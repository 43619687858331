import React from "react";
import { Badge } from "react-bootstrap";

const Chip = ({ showRemove = false, callback, label, uniqKey = "" }) => {
  return (
    <Badge pill bg="primary" className="fs-6 mb-2 me-2">
      <span className="me-2">{label}</span>
      {showRemove && (
        <span
          className="bi bi-x-circle-fill cursor-pointer"
          onClick={() => callback({ action: "remove", uniqKey })}
        ></span>
      )}
    </Badge>
  );
};

export default Chip;
