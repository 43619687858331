export const projectManagementMenus = {
  groups: ["ViewProject", "EditProject", "AddProject"],
  icon: "kanban",
  name: "Project Management",
  priority: 13,
  redirect: {
    path: "/utility/project-management/projects",
  },
  subMenus: [
    {
      groups: ["MyTasks"],
      redirect: {
        path: "/utility/project-management/tasks",
        params: {
          taskType: "my",
        },
      },
      name: "My Tasks",
    },
    {
      groups: ["TaskBoard"],
      redirect: {
        path: "/utility/project-management/tasks",
        params: {
          id: "all",
        },
      },
      name: "Task Board",
    },
    {
      groups: ["ViewProject"],
      redirect: {
        path: "/utility/project-management/projects",
      },
      name: "Projects",
    },
    {
      groups: ["ViewProject"],
      redirect: {
        path: "/utility/project-management/tasks",
        params: {
          id: "SKPRJ8",
        },
      },
      name: "Production Issues",
    },
  ],
};
