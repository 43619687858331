import { useRef, useState } from "react";

import { AjaxService } from "@sk/services";
import { BusyLoader } from "../sk-uis";

const FileUpload2 = ({ url, callback, children, accept = "image/*" }) => {
  const [busyLoading, setBusyLoading] = useState({ show: false });

  const inpRef = useRef(null);

  const openFile = () => {
    inpRef.current?.click();
  };

  const onFileSelect = async (e) => {
    const files = e.target.files || [];
    if (!files.length) {
      return;
    }
    try {
      setBusyLoading({ show: true });
      const r = await AjaxService.request(
        url,
        "POST",
        {
          file: files[0],
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setBusyLoading({ show: false });
      if (r.statusCode == 200) {
        callback({
          id: r.resp._id,
          resp: r.resp,
          status: "success",
        });
      } else {
        callback({
          id: "",
          resp: r.resp,
          status: "error",
        });
      }
    } catch (error) {
      console.warn(error);
      setBusyLoading({ show: false });
      callback({
        id: "",
        resp: {},
        status: "error",
      });
    }
  };

  return (
    <>
      <input
        type="file"
        className="d-none"
        ref={inpRef}
        onChange={onFileSelect}
        accept={accept}
        // capture={view == "camera" ? true : false}
      />
      <div className="d-inline-block" onClick={openFile}>
        {children}
      </div>
      <BusyLoader show={busyLoading.show} />
    </>
  );
};

export default FileUpload2;
