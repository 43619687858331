import { memo, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Toaster } from "../sk-uis";

const OtpModal = ({ show, sentTo, otpLen, callback, loading }) => {
  const DEFAULT_INPUTS = 6;

  const modalRef = useRef(null);

  const onInpChange = (e, index) => {
    const val = e.target.value;
    if (!/[0-9]/.test(val)) {
      return;
    }
    e.target.value = val % 10;
    const allInputsArr = modalRef.current.querySelectorAll("input");
    const len = allInputsArr.length;
    if (index < len) {
      allInputsArr[index].focus();
    }
  };

  const verify = () => {
    let otp = getEnteredOtpVal();
    if (!otp) {
      Toaster.error("Please provide OTP");
      return;
    }

    if (Number(otp).toString().length != (otpLen || DEFAULT_INPUTS)) {
      Toaster.error("Please provide valid OTP");
      return;
    }

    invokeCallback("verify", { otp });
  };

  const getEnteredOtpVal = () => {
    const inputs = modalRef.current.querySelectorAll("input");
    let nums = "";
    for (let i = 0; i < inputs.length; i++) {
      nums += "" + inputs[i].value;
    }
    return Number(nums);
  };

  const invokeCallback = (action, data) => {
    if (callback) {
      callback({
        action: action,
        data: data || {},
      });
    }
  };

  const onHideCb = () => {
    invokeCallback("close");
  };

  return (
    <>
      <Modal show={show} backdrop={"static"} onHide={onHideCb}>
        <Modal.Header closeButton>
          <div className="modal-title m-0 text-white">Please Enter OTP</div>
        </Modal.Header>
        <Modal.Body className="rounded-bottom">
          <div className="p-3" ref={modalRef}>
            <p>We have sent OTP to {sentTo} </p>
            <div className="row mb-5">
              {Array.from(
                { length: otpLen || DEFAULT_INPUTS },
                (e, i) => i + 1
              ).map((e) => (
                <div className="col" key={e}>
                  <input
                    type="number"
                    onChange={(ev) => onInpChange(ev, e)}
                    min={0}
                    className="form-control text-center"
                  />
                </div>
              ))}
            </div>
            <div className="row g-0 align-items-center">
              <div className="col fs-7 text-muted">
                <span>Dont Receive OTP ? &nbsp; </span>
                <a className="text-decoration-none">Resend</a>
              </div>
              <div className="col text-end">
                <button
                  type="button"
                  onClick={verify}
                  className="mb-2 btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  Verify
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(OtpModal);
