import Ajax from "./ajax";
import { appConfigs } from "./config";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isAfter,
} from "date-fns";

class product {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static create(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion,
      "POST",
      params
    );
  }

  static update(id, params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/" + id,
      "PUT",
      params
    );
  }

  static getList(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion,
      "GET",
      params,
      config
    );
  }
  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getProducts(params) {
    return this.getList(params);
  }

  static getProduct(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/" + id,
      "GET",
      params
    );
  }

  static getClassGroupList(parmas = {}) {
    return Ajax.request(
      this.endpoint + "/utilities/" + this.apiVersion + "/classgroup",
      "GET",
      parmas
    );
  }

  static getProductByVendor(vendorId, params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/getProduct/" + vendorId,
      "GET",
      params
    );
  }

  static getBarcode() {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/barcode/generate"
    );
  }

  static getReservationDisplayStatus(status) {
    let displayStatus = "";
    if (status == "Started") {
      displayStatus = "Live";
    } else if (status == "Ended") {
      displayStatus = "Expired";
    } else if (status == "Reserve got over") {
      displayStatus = "Reserve got over";
    } else {
      displayStatus = status;
    }
    return displayStatus;
  }

  static async getReservationList(params, signal) {
    const getBrandMargin = (dealDetail) => {
      if (!dealDetail) return 0;

      const mrp = dealDetail.dealMrp || 0;
      const b2bPrice = dealDetail.dealB2bPrice || 0;

      if (mrp === 0) return 0;

      return (((mrp - b2bPrice) / mrp) * 100).toFixed(2);
    };

    const getExpiresIn = (endDate) => {
      if (!endDate) return "N/A";

      const now = new Date();
      const end = new Date(endDate);

      // If end date is in the past, return N/A
      if (!isAfter(end, now)) {
        return "N/A";
      }

      const days = differenceInDays(end, now);
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""}`;
      }

      const hours = differenceInHours(end, now);
      if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
      }

      const minutes = differenceInMinutes(end, now);
      return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    };

    const r = await Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/preOrderWhList/list",
      "GET",
      params,
      { signal }
    );
    if (Array.isArray(r?.resp)) {
      r.resp.forEach((x) => {
        if (Array.isArray(x.subOrderDetails) && x.subOrderDetails.length == 0) {
          x.subOrderDetails = {};
        }

        x._canEdit =
          ["Started", "Ended", "Pending"].indexOf(x.status) != -1 &&
          x.isValidExpired == true;

        x._canExtend = x.actualStatus == "Expired" && x.isValidExpired == true;

        x._canStop = x.status == "Started";

        x._canStart = x.status == "Pending";

        x._displayStatus = this.getReservationDisplayStatus(x.actualStatus);

        x.margin = getBrandMargin(x.dealDetail);

        x._expiresIn = getExpiresIn(x.endDate);

        const statusTypeMap = {
          Live: "success",
          Expired: "danger",
          Ended: "danger",
          Pending: "warning",
          Stopped: "secondary",
          "Reserve got over": "danger",
        };
        x._statusType = statusTypeMap[x.actualStatus] || "secondary";
      });
    }
    return r;
  }

  static getReservationDetails(id) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/preOrderWhList/" + id,
      "GET"
    );
  }
  static getReservationAuditLog(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/messageList",
      "GET",
      params
    );
  }

  static getReservationAuditLogCount(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/messageList/count",
      "GET",
      params
    );
  }

  static createReserve(id, params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/groupId/" +
        id,
      "PUT",
      params
    );
  }

  static updateReserve(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/preOrderWhList/" + id,
      "PUT",
      params
    );
  }

  static getHsnMasters(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/taxation/hsnmaster",
      "GET",
      params
    );
  }

  static getHsnById(id) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/taxation/getHsnById/" +
        id,
      "GET"
    );
  }

  static updateHsnById(id, params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/taxation/updateHsnById/" +
        id,
      "PUT",
      params
    );
  }

  static getHsnMastersCount(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/taxation/hsnmaster/count",
      "GET",
      params
    );
  }

  static createHsnMasters(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/taxation/createHsn",
      "POST",
      params
    );
  }

  static reserveBulkEditConfig(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/bulkOrderUpdate",
      "PUT",
      params
    );
  }

  static extendConfig(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/extendConfig",
      "PUT",
      params
    );
  }

  static bulkStop(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/bulkStopConfig",
      "PUT",
      params
    );
  }

  static getBulkProduct(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/bulk",
      "GET",
      params
    );
  }

  static async getReservationGroupConfig(id, params) {
    const r = await Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/groupId/" +
        id,
      "GET",
      params
    );
    if (Array.isArray(r.resp)) {
      (r.resp || []).forEach((x) => {
        x._displayStatus = this.getReservationDisplayStatus(x.status);
        const statusTypeMap = {
          Started: "success",
          Ended: "danger",
          Pending: "warning",
          Stopped: "secondary",
        };
        x._statusType = statusTypeMap[x.status] || "secondary";
      });
    }

    return r;
  }

  static getProductConfigCount(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/getproductconfiglogscount",
      "GET",
      params
    );
  }

  static getProductConfig(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/getproductconfiglogs",
      "GET",
      params
    );
  }

  static getMasterBarcodeList(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/getMasterBarcodes",
      "GET",
      params
    );
  }

  static createMasterBarcode(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/createMasterBarcodes",
      "POST",
      params
    );
  }

  static updateMasterBarcode(id, params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/updateMasterBarcodes/" +
        id,
      "PUT",
      params
    );
  }

  static getBoxBarcodeDetails(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/box/scan",
      "GET",
      params,
      config
    );
  }

  static getCaseBarcode(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/caseBarcode",
      "GET",
      params,
      config
    );
  }

  static getInvoiceBarcode(id, dimension = {}) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/getBarCodeRedirection/" +
        id +
        `/${dimension.length}/${dimension.breath}/${dimension.height}`
    );
  }

  static updateProductReserveQty(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/individual/OrderUpdate",
      "PUT",
      params
    );
  }

  static discountinueProduct(id, discontinued, reason) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/discontinued/" +
        id +
        `?discontinued=${discontinued}&reason=${reason}`,
      "GET"
    );
  }

  static syncReservation(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/preOrderWhList/syncOrderCount",
      "PUT",
      params
    );
  }

  static getProductSalesSummary(id) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/getProductSalesSummary/" +
        id,
      "GET",
      {}
    );
  }

  static getProductBrands(params) {
    return Ajax.request(
      this.endpoint +
        "/product/" +
        this.apiVersion +
        "/product/getProductBrands",
      "GET",
      params
    );
  }

  static getProductCategories(params) {
    return Ajax.request(
      this.endpoint + "/product/" + this.apiVersion + "/getProductCategories",
      "GET",
      params
    );
  }
}

export default product;
