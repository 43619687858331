import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderForm {
  static endpoint = appConfigs.BUILDER_API;

  static create(data) {
    return Ajax.request(this.endpoint + "/builder/forms", "POST", data);
  }

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/builder/forms", "GET", params);
  }

  static getDetail(id) {
    return Ajax.request(this.endpoint + "/builder/forms/" + id, "GET");
  }

  static update(id, data) {
    return Ajax.request(this.endpoint + "/builder/forms/" + id, "PUT", data);
  }
}

export default BuilderForm;
