import { AuthService } from "@sk/services";
import { memo, useEffect, useState } from "react";
import { SelectInput } from "../sk-uis";

const AssignedWarehouseSelect = ({ callback, state, register, error }) => {
  const [warehouseList, seWarehouseList] = useState([]);

  useEffect(() => {
    const wh = AuthService.getLoggedInEmp()
      .warehouses.filter((currWh) => state === currWh.state)
      .map((currWh) => ({ label: currWh.name, value: currWh._id }));
    seWarehouseList(wh);
  }, [state]);

  return (
    <SelectInput
      label="Warehouse"
      register={register}
      name="wh"
      error={error}
      isMandatory={true}
      options={warehouseList}
      callback={callback}
    />
  );
};

export default memo(AssignedWarehouseSelect);
