import produce from "immer";
import { memo, useCallback, useEffect, useId, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const style = {
  container: {
    width: "300px",
  },
};

const TableColumnBtn = ({ columns = [], callback }) => {
  const uid = useId();

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setData([...columns]);
  }, [columns]);

  const onCheckboxChange = (index, ev) => {
    setData(
      produce((draft) => {
        draft[index].checked = ev.target.checked;
      })
    );
  };

  const onSelectAll = () => {
    setData(
      produce((draft) => {
        draft.forEach((x) => {
          x.checked = true;
        });
      })
    );
  };

  const onClearAll = () => {
    setData(
      produce((draft) => {
        draft.forEach((x) => {
          x.checked = false;
        });
      })
    );
  };

  const onApply = () => {
    if (callback) {
      callback({ columns: data, selected: data.filter((x) => x.checked) });
      setShow(false);
    }
  };

  const onToggle = useCallback(
    (val) => {
      if (!val) {
        callback({ columns: data, selected: data.filter((x) => x.checked) });
      }
      setShow(val);
    },
    [callback, data]
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      onToggle={onToggle}
      show={show}
      overlay={
        <Popover style={style.container} className="shadow">
          <Popover.Body className="p-0">
            <div className="border-bottom px-3 py-1">
              <button className="btn btn-link btn-sm" onClick={onSelectAll}>
                Select All
              </button>
              <span className="px-1"> | </span>
              <button className="btn btn-link btn-sm" onClick={onClearAll}>
                Clear all
              </button>
            </div>
            <div className="p-3">
              <div className="mb-1 fs-val-sm fw-semibold">Columns</div>
              {data.map((x, i) => (
                <div className="row mb-2 align-items-center" key={x.key}>
                  <div className="col-auto">
                    <input
                      type="checkbox"
                      className="align-middle"
                      name="tbl-cols"
                      checked={x.checked == true ? true : false}
                      onChange={(e) => onCheckboxChange(i, e)}
                    />
                  </div>
                  <div className="col fs-val-md">{x.label}</div>
                </div>
              ))}

              <div className="text-end">
                <button onClick={onApply} className="btn btn-primary btn-sm">
                  Apply
                </button>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <button
        className="btn bg-white btn-lg py-1 border"
        key={uid}
        type="button"
      >
        <i className="bi bi-sliders"></i>
      </button>
    </OverlayTrigger>
  );
};

export default memo(TableColumnBtn);
