import { merge } from "lodash";
import { CommonService } from "../sk-services";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Franchise {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getFranchises(params) {
    return Ajax.request(this.endpoint + "/franchise/v1", "GET", params);
  }

  static async getFranchise(id, params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/franchise/v1/" + id,
      "GET",
      params
    );

    if (r.statusCode == 200 && r.resp) {
      r.resp.sellerId = r.resp.sk_franchise_details?.linkedSellerId;
    }

    return r;
  }

  static getFranchiseStatusType(status) {
    const statusMap = {
      Approved: "success",
      "Partial Approval": "warning",
      Rejected: "danger",
      Submitted: "info",
      Suspended: "secondary",
    };

    return statusMap[status] || "secondary";
  }

  static async getList(params, config = {}) {
    const r = await Ajax.request(
      this.endpoint + "/franchise/v1/",
      "GET",
      params,
      config
    );

    if (r.statusCode == 200 && Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        x.sellerId = x.sk_franchise_details?.linkedSellerId;
        x.statusType = this.getFranchiseStatusType(x.status);
        return x;
      });
    }

    return r;
  }

  static getCount(params) {
    return Ajax.request(this.endpoint + "/franchise/v1/count", "GET", params);
  }

  static async getFranchiseInventorySummary(params) {
    let p = { ...params };
    if (p.filter && p.filter._id) {
      p.franchiseId = p.filter._id;
    }

    const r = await Ajax.request(
      this.endpoint + "/franchise/v1/fetch/stockInventorySummary",
      "GET",
      p
    );

    if (r.statusCode == 200) {
      if (!params?.showCount && params.outputType != "csv") {
        (r.resp || []).forEach((x) => {
          x.hasLastPurDt = false;
          x.hasSubscribeDt = false;

          if (!x.isOwnedByThirdparty) {
            if (x.purchaseData && x.purchaseData.on) {
              x.hasLastPurDt = true;
            }
            if (!x.hasLastPurDt && x.subscribedDate) {
              x.hasSubscribeDt = true;
            }
          }

          x._finalSellingPrice = CommonService.roundedByDecimalPlace(
            x.retailerSellingPrice || x.b2cPrice || x.mrp || 0,
            2
          );

          // x._discountPerc = CommonService.discountCal(
          //   x.mrp,
          //   x._finalSellingPrice
          // );

          x._purchasePrice = x?.purchaseData?.price || x.b2bprice || x.mrp;

          x._recentPurcahsePrice = x.pp || 0;

          // if (x?.purchaseData?.purchaseHistory?.length > 0) {
          //   x._recentPurcahsePrice = orderBy(
          //     x?.purchaseData?.purchaseHistory || [],
          //     ["orderedOn", "mrp"],
          //     ["desc", "desc"]
          //   )[0]?.price;
          // } else {
          //   x._recentPurcahsePrice = x._purchasePrice;
          // }

          x._rsp = x._finalSellingPrice;
          x._rspPrev = x._finalSellingPrice;
          x._rspGiven = x._rsp != x.mrp;

          x._qty = x.qty;

          x._displayUnitKey = "unit";

          x._recommended = x.isRecommended;
          x._topDiscount = x.isTopDiscounted;

          // for reserve
          if (x?.reserveConfig?.available) {
            x.reserveConfig._available = x.sellInLooseQty
              ? CommonService.roundedByDecimalPlace(
                  x?.reserveConfig?.available / 1000,
                  4
                )
              : x?.reserveConfig?.available;
          }

          if (x.sellInLooseQty) {
            x._displayUnitKey = "kg";

            x._qty = CommonService.roundedByDecimalPlace(x.qty / 1000, 4);

            ["sevenday", "fifteenday", "thirtyday", "ninetyday"].forEach(
              (e) => {
                if (x.salesData && x.salesData[e] && x.salesData[e].qty) {
                  x.salesData[e]._qty = CommonService.roundedByDecimalPlace(
                    x.salesData[e].qty / 1000,
                    4
                  );
                }
              }
            );
            ["fifteendayQty", "thirtydaysQty", "sixtydaysQty"].forEach((e) => {
              if (x.purchaseData && x.purchaseData[e]) {
                x.purchaseData[e] = {
                  qty: x.purchaseData[e],
                  _qty: CommonService.roundedByDecimalPlace(
                    x.purchaseData[e] / 1000,
                    4
                  ),
                };
              }
            });
          } else {
            x._qty = x.qty;
            ["sevenday", "fifteenday", "thirtyday", "ninetyday"].forEach(
              (e) => {
                if (x.salesData && x.salesData[e] && x.salesData[e].qty) {
                  x.salesData[e]._qty = x.salesData[e].qty;
                }
              }
            );
            ["fifteendayQty", "thirtydaysQty", "sixtydaysQty"].forEach((e) => {
              if (x.purchaseData && x.purchaseData[e]) {
                x.purchaseData[e] = {
                  qty: x.purchaseData[e],
                  _qty: x.purchaseData[e],
                };
              }
            });
          }
        });
      }
    }

    return r;
  }

  static getGroupCodeList(params) {
    return Ajax.request(
      this.endpoint + "/franchise/v1/reservation/groupConfig",
      "GET",
      params
    );
  }

  static getCallLogs(params) {
    return Ajax.request(
      this.endpoint + "/franchise/v1/getCallLogs",
      "GET",
      params
    );
  }

  static getCallLogsCount(params) {
    return Ajax.request(
      this.endpoint + "/franchise/v1/getCallLogCount",
      "GET",
      params
    );
  }

  static getPosSummary(params) {
    return Ajax.request(
      this.endpoint + "/franchise/" + this.apiVersion + "/fetch/POSSummary",
      "GET",
      params
    );
  }

  static getDarkstoreFilter() {
    return {
      filter: {
        groupType: "COCO",
        "sk_franchise_details.franchise_sub_type": {
          $in: appConfigs.DARKSTORE_SUB_TYPES,
        },
      },
    };
  }

  static calculateStoreComboPerc(deals, value, type) {
    const tot = deals.reduce((s, i) => (s += i._rsp * i._qty), 0);
    let p = 0;
    if ((type || "").toLowerCase() == "price") {
      p = 100 - (value / tot) * 100;
    } else {
      p = value;
    }

    return deals.map((e) => {
      const price = e._rsp - (e._rsp * p) / 100;
      return {
        ...e,
        _discount: p,
        _finalRsp: price,
        _totalRsp: 1 * e._qty * price,
      };
    });
  }

  // static calculateStoreComboPerc(deals, value, type) {
  //   const totRsp = deals.reduce((s, i) => (s += i._rsp * i._qty), 0);

  //   if ((type || "").toLowerCase() != "price") {
  //     value = totRsp - (totRsp * value) / 100;
  //   }

  //   return deals.map((e) => {
  //     let contribution = 0;

  //     const totVal = e._rsp * e._qty;
  //     contribution = (totVal / totRsp) * 100;
  //     const subTot = (value * contribution) / 100;
  //     const price = subTot / e._qty;
  //     const p = CommonService.roundedByDecimalPlace(
  //       100 - (subTot / totVal) * 100,
  //       2
  //     );

  //     return {
  //       ...e,
  //       contribution: CommonService.roundedByDecimalPlace(contribution, 3),
  //       _finalRsp: price,
  //       _totalRsp: 1 * e._qty * price,
  //     };
  //   });
  // }

  static getFranchiseManpower(params) {
    return Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/manpower`,
      "GET",
      params
    );
  }

  static getFcList(fid) {
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/fc/${fid}`,
      "GET"
    );
  }

  static getStockIntake(params) {
    let p = merge({}, params, {
      isDownload: true,
    });
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/store/purchase/intake/summary`,
      "GET",
      p
    );
  }

  static getSfSubTypes() {
    return Ajax.request(`${this.endpoint}/config/sfSubTypes`, "GET");
  }
}

export default Franchise;
