import React from "react";
import { SkeletonLoader } from "../sk-uis";
import range from "lodash/range";

const TableSkeletonLoader = ({ rows, cols, height = 20 }) => {
  return range(0, rows).map((x) => (
    <tr key={x}>
      {Array(cols)
        .fill(-1)
        .map((x, i) => (
          <td key={"col-" + i + 1}>
            <SkeletonLoader height={height} />
          </td>
        ))}
    </tr>
  ));
};

export default TableSkeletonLoader;
