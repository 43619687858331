import Ajax from "./ajax";
import { appConfigs } from "./config";

class Warehouse {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getStockInfo(whId, pid, params = {}, config = {}) {
    const url =
      this.endpoint +
      "/wh/" +
      this.apiVersion +
      "/inventoryStockSummary/" +
      whId +
      "/" +
      pid;
    return Ajax.request(url, "GET", params, config);
  }

  static stockTransactionSummaryList(params = {}) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/stockTransactionSummary",
      "GET",
      params
    );
  }

  static stockTransactionSummaryCount(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/stockTransactionSummary/count",
      "GET",
      params
    );
  }

  static getPrdSnapStatus() {
    let statuses = [
      { label: "Pending", value: "Pending", color: "info" },
      { label: "Committed", value: "Committed", color: "success" },
      { label: "Cancelled", value: "Cancelled", color: "danger" },
      { label: "Failed", value: "Failed", color: "warning" },
      { label: "Reverted", value: "Reverted", color: "secondary" },
    ];
    return statuses;
  }

  static async stockLedgerList(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/stockLedgerList",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      const statuses = this.getPrdSnapStatus();
      r.resp.forEach((item) => {
        item.statusColor =
          statuses.find((x) => x.value === item.status)?.color || "secondary";

        item.docStatusColor =
          item.docStatus == "Success" ? "success" : "danger";
      });
    }

    return r;
  }

  static getBarcodeMasterCount(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/barcodeMaster/count",
      "GET",
      params
    );
  }

  static getBarcodeMasterList(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/barcodeMaster/list",
      "GET",
      params
    );
  }

  static getDocumentWiseList(id, params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/inventory/product/" + id,
      "GET",
      params
    );
  }
  static getRfReservationStockSummary(id) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/RFReservationStocksSummary/" +
        id,
      "GET"
    );
  }
  static getDocumentWiseListCount(id, params) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/inventory/product/" +
        id +
        "/count",
      "GET",
      params
    );
  }

  static getLocationWiseList(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/inventory/locationwise",
      "GET",
      params
    );
  }

  static getLocationWiseListCount(params) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/inventory/locationwise/count",
      "GET",
      params
    );
  }

  static getImeiWiseList(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/imei/list",
      "GET",
      params
    );
  }
  static getImeiWiseListCount(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/imei/count",
      "GET",
      params
    );
  }

  static getImeiListSnapshot(params) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/snapshot",
      "GET",
      params
    );
  }

  static getAreaDetailsById(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/" + id + "/area",
      "GET",
      params
    );
  }

  static getRackDetailsById(whId, areaId, params = {}) {
    return Ajax.request(
      this.endpoint + "/wh/" + this.apiVersion + "/" + whId + `/${areaId}/rack`,
      "GET",
      params
    );
  }

  static getProductByBarcode(warehouse, barcode, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/" +
        warehouse +
        "/barcode/" +
        barcode,
      "GET",
      params
    );
  }

  static getStockByBarcode(id, params) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/" +
        id +
        "/stockdetBySerialOrBarcode",
      "GET",
      params
    );
  }

  static updateStockMrpShelfLife(wid, params) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/updateStockMrpShelfLife/" +
        wid,
      "PUT",
      params
    );
  }

  static getInventoryCount(_whId, _pId, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/wh/" +
        this.apiVersion +
        "/" +
        _whId +
        "/inventory/" +
        _pId +
        "/count",
      "GET",
      params
    );
  }
}
export default Warehouse;
