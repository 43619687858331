import { merge } from "lodash";
import { CommonService } from "../sk-services";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import {
  formatDistanceToNow,
  isPast,
  isWithinInterval,
  addDays,
} from "date-fns";

class Deal {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static create(params) {
    return Ajax.request(this.endpoint + "/deal/v1", "POST", params);
  }

  static getDetailsById(id) {
    return Ajax.request(this.endpoint + "/deal/v1/getdeals/" + id, "GET");
  }

  static getDeals(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/deal/v1/getdeals",
      "GET",
      params,
      config
    );
  }

  static getDealsOnPid(ids, params = {}) {
    const p = {
      page: 1,
      count: ids.length * 2,
      filter: {
        dealType: { $ne: "Combo" },
        product: { $elemMatch: { id: { $in: ids }, quantity: { $eq: 1 } } },
        is_active: true,
      },
    };
    return this.getDeals(merge({}, p, params));
  }

  static getDealsCount(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/deal/v1/count",
      "GET",
      params,
      config
    );
  }

  static update(id, params) {
    return Ajax.request(this.endpoint + "/deal/v1/" + id, "PUT", params);
  }

  static bulkUpdate(ids, params) {
    return Ajax.request(
      this.endpoint + "/deal/v1/bulk?id=" + ids.toString(),
      "PUT",
      params
    );
  }

  static getWarehouseConfig(params = {}) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceconfig/list",
      "GET",
      params
    );
  }
  static getWarehouseConfigCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceconfig/count",
      "GET",
      params
    );
  }

  static createWarehouseConfig(params = {}) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceconfig/create",
      "POST",
      params
    );
  }

  static generateBarcode(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/generatebarcode",
      "POST",
      params
    );
  }
  static getBarcodeDetails = (id) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/barcode/" + id,
      "GET"
    );
  };

  static getBarcodeList = (params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/generatebarcode",
      "GET",
      params
    );
  };

  static getBarcodeCount = (params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/barcode/count",
      "GET",
      params
    );
  };

  static createCustomerCatalogPriceConfig = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/create",
      "POST",
      params
    );
  };

  static bulkCreateCustomerCatalogPriceConfig = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/bulkCreate",
      "POST",
      params
    );
  };

  static bulkUpdateCustomerCatalogPriceConfig = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/bulkUpdate",
      "POST",
      params
    );
  };

  static getCustomerCatalogPriceConfig = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/list",
      "GET",
      params
    );
  };

  static updateCustomerCatalogPriceConfig = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/update",
      "PUT",
      params
    );
  };

  static customerCatalogPriceConfigCount = (params) => {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/list",
      "GET",
      params
    );
  };

  static getBulkDealStock = (id, params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/bulkGetDealStock/" + id,
      "GET",
      params
    );
  };

  static getPosKingSlabList = (params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceslabs/list",
      "GET",
      params
    );
  };

  static getPosKingSlabCount = (params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceslabs/count",
      "GET",
      params
    );
  };
  static createPriceSlab = (params) => {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceslabs/create",
      "POST",
      params
    );
  };

  static updatePriceSlab(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/dealpriceslabs/update/" +
        id,
      "PUT",
      params
    );
  }
  static getPriceSlabById(id) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpriceslabs/view/" + id,
      "GET"
    );
  }

  static getDealConfigLogs(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/getdealconfiglogs",
      "GET",
      params
    );
  }

  static getDealConfigCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/getdealconfiglogscount",
      "GET",
      params
    );
  }

  /******************************** Store Discounts  ***************************************/
  static async getStoreDiscountList(params) {
    const r = await Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/config",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((x) => {
        const today = new Date();
        const validTo = new Date(x.offerValidity?.validTo);

        if (isPast(validTo)) {
          x.validityStatus = {
            text: "Expired",
            color: "danger",
            icon: "bi-x-circle-fill",
            remainingTime: "Expired",
          };
        } else {
          // Check if within 10 days
          const isNearExpiry = isWithinInterval(validTo, {
            start: today,
            end: addDays(today, 10),
          });

          if (isNearExpiry) {
            x.validityStatus = {
              text: "Expiring Soon",
              color: "warning",
              icon: "bi-clock-fill",
              remainingTime: `Expires in ${formatDistanceToNow(validTo)}`,
            };
          } else {
            x.validityStatus = {
              text: "Active",
              color: "success",
              icon: "bi-check-circle-fill",
              remainingTime: `Expires in ${formatDistanceToNow(validTo)}`,
            };
          }
        }
      });
    }

    return r;
  }
  static getStoreDiscountListCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/config/count",
      "GET",
      params
    );
  }
  static getStoreDiscountById(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/config/" + id,
      "GET",
      params
    );
  }

  static updateStoreDiscount(id, params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/config/" + id,
      "PUT",
      params
    );
  }
  static createStoreDiscount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/config",
      "POST",
      params
    );
  }

  /******************************** Lucky Draw  ***************************************/
  static getLuckyDrawList(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/luckyDraw/config",
      "GET",
      params
    );
  }

  static getStoreOfferOrderCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/storeoffers/getOrderCount",
      "GET",
      params
    );
  }

  static getLuckyDrawListCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/luckyDraw/config/count",
      "GET",
      params
    );
  }
  static getLuckyDrawById(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/luckyDraw/config/" + id,
      "GET",
      params
    );
  }

  static updateLuckyDraw(id, params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/luckyDraw/config/" + id,
      "PUT",
      params
    );
  }

  static createLuckyDraw(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/luckyDraw/config",
      "POST",
      params
    );
  }

  static getRestrictedConfigList(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/ristrictedBrandCatConfig",
      "GET",
      params
    );
  }

  static clubDealPurchaseLimit(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpurchaselimit/config",
      "POST",
      params
    );
  }

  static updateRestrictedConfig(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/ristrictedBrandCatConfig?id=" +
        id,
      "PUT",
      params
    );
  }
  static updateClubDealPurchaseLimit(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/dealpurchaselimit/config/" +
        id,
      "PUT",
      params
    );
  }

  static createRestrictedConfig(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/ristrictedBrandCatConfig",
      "POST",
      params
    );
  }

  static async getClubDealPurchaseLimit(params) {
    const r = await Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/dealpurchaselimit/config",
      "GET",
      params
    );

    if (Array.isArray(r.resp) && r.resp.length > 0) {
      r.resp = r.resp.map((x) => {
        x._unitType = x.unitType;
        x._perOrderLimit = x.perOrderLimit;
        x._perMonthLimit = x.perMonthLimit;
        x._perDayLimit = x.perDayLimit;
        if (x.unitType == "gm") {
          x._unitType = "kg";
          x._perOrderLimit = CommonService.roundedByDecimalPlace(
            x.perOrderLimit / 1000,
            2
          );
          x._perMonthLimit = CommonService.roundedByDecimalPlace(
            x.perMonthLimit / 1000,
            2
          );
          x._perDayLimit = CommonService.roundedByDecimalPlace(
            x.perDayLimit / 1000,
            2
          );
        }
        return { ...x };
      });
    }

    return r;
  }

  static getClubDealPurchaseLimitCount(params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/dealpurchaselimit/config/count",
      "GET",
      params
    );
  }

  static getSellerDealStockDetails(sellerId, dealId, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/" +
        sellerId +
        "/stock/" +
        dealId +
        "/master",
      "GET",
      params
    );
  }
  static getRspOrdersList(params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/customerCatalogPriceConfig/orders/list",
      "GET",
      params
    );
  }
  static getOutofStockRequestList(params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/clubCustomer/productRequest",
      "GET",
      params
    );
  }
  static getOutofStockRequestListCount(params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/clubCustomer/productRequest/count",
      "GET",
      params
    );
  }

  static createStoreComboDeals(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/comboDeal/config",
      "POST",
      params
    );
  }

  static updateStoreComboDeals(id, params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/comboDeal/config/" + id,
      "PUT",
      params
    );
  }

  static async getStoreComboDeals(params) {
    const r = await Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/comboDeal/config",
      "GET",
      params
    );

    if (r.resp && Array.isArray(r.resp)) {
      r.resp.forEach((e) => {
        e._linkedDeals = e.deals.length;
      });
    }

    return r;
  }

  static getStoreComboDealsCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/comboDeal/config/count",
      "GET",
      params
    );
  }

  static getLedgerRefLblVal(refValue, salesType) {
    const a = (refValue || "").split("#");

    const invPatt = /^IN/g;
    const pordPatt = /^ORD/g;
    const sordPatt = /^XOR/g;
    const posOrdPatt = /^POS/g;
    const posReturnPatt = /^POSR/g;
    const po = /^SPO-/g;

    const pickOrdId = (val) => {
      return val.substring(0, val.lastIndexOf("_"));
    };

    let lbls = [];

    a.forEach((x) => {
      if (invPatt.test(x)) {
        lbls.push({
          type: "invoice",
          lbl: "Invoice",
          val: x,
          redirect: {
            path: "",
            params: {
              id: x,
            },
          },
        });
      } else if (pordPatt.test(x)) {
        lbls.push({
          type: "primaryOrder",
          lbl: "Order",
          val: x,
          redirect: {
            path: "/oms/orders/details",
            params: {
              id: pickOrdId(x),
            },
          },
        });
      } else if (sordPatt.test(x)) {
        lbls.push({
          type: "secondaryOrder",
          lbl: "Order",
          val: x,
          // redirect: {
          //   path:
          //     salesType == "PURCHASE"
          //       ? "dashboard/orders/details"
          //       : "seller/orders/detail",
          //   params: {
          //     id: pickOrdId(x),
          //   },
          // },
        });
      } else if (posReturnPatt.test(x)) {
        lbls.push({
          type: "posReturn",
          lbl: "Return",
          val: x,
        });
      } else if (posOrdPatt.test(x)) {
        lbls.push({
          type: "posOrder",
          lbl: "Order",
          val: x,
          redirect: {
            path: "/pos/order/view",
            params: {
              id: x,
            },
          },
        });
      } else if (po.test(x)) {
        lbls.push({
          type: "po",
          lbl: "Purchase Order (PO)",
          val: x,
          redirect: {
            path: "purchase-order/pos/view",
            params: {
              id: x,
            },
          },
        });
      } else {
      }
    });

    return lbls;
  }

  static async getPosStockLedger(params, isSellInLoose = false) {
    let options = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Purchase from Vendor",
        value: "PURCHASE - Third Party",
      },
      {
        label: "Purchase from SK",
        value: "PURCHASE - SK",
      },
      {
        label: "Sales from POS (B2C Sales)",
        value: "SALES - POS",
      },
      {
        label: "Network Sales (from Retailer)",
        value: "SALES - Network",
      },
      {
        label: "Stock Adjustment",
        value: "Adjustment",
      },
      {
        label: "Subscription",
        value: "Subscription",
      },
      {
        label: "Return from POS",
        value: "Sales Return",
      },
      {
        label: "Returns",
        value: "Purchase Return",
      },
    ];

    const r = await Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/seller/stock/ledger",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((x) => {
        if (
          x.referenceType == "PURCHASE - Third Party" &&
          x.purchaseInfo?.name
        ) {
          x._msg = /^SPO/.test(x.refId)
            ? "Purchase Order"
            : "Purchased from " + x.purchaseInfo?.name;
        } else if (x.referenceType == "SALES - POS") {
          x._msg = "POS Order placed";
          x._orderId = x.refId;
        } else {
          const i = options.find((e) => e.value == x.referenceType) || {};
          x._msg = i.label || x.referenceType;
        }

        x._effectiveOldQty = x.effectiveOldQty;
        x._quantity = x.changeQtyBy;
        x._effectiveNewQty = x.effectiveNewQty;

        x.lbls = this.getLedgerRefLblVal(x.refId, x.subType);

        if (isSellInLoose) {
          let effectiveOldQty = CommonService.convertQtyOnUom(
            x.effectiveOldQty,
            "kg"
          );
          let quantity = CommonService.convertQtyOnUom(x.quantity, "kg");
          let effectiveNewQty = CommonService.convertQtyOnUom(
            x.effectiveNewQty,
            "kg"
          );

          x._effectiveOldQty = effectiveOldQty.qty;
          x._quantity = quantity.qty;
          x._effectiveNewQty = effectiveNewQty.qty;

          x._effectiveOldQtyUom = effectiveOldQty.uom;
          x._quantityUom = quantity.uom;
          x._effectiveNewQtyUom = effectiveNewQty.uom;
        }
      });
    }

    return r;
  }

  static getPosStockLedgerCount(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/seller/stock/ledger/count",
      "GET",
      params
    );
  }

  static getPosStockMaster(dealId, sellerId, params, isSellInLoose = false) {
    const calculateRemainingDays = (expiryDate) => {
      const expiry = new Date(expiryDate);
      const today = new Date();
      const timeDifference = +expiry - +today;
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return remainingDays;
    };

    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/" +
        sellerId +
        "/stock/" +
        dealId +
        "/master",
      "GET",
      params
    ).then((r) => {
      (r.resp || []).forEach((x) => {
        x._quantity = x.quantity;

        if (x.expiry) {
          const d = calculateRemainingDays(x.expiry);
          if (d >= 0) {
            x._shelfLifeRemaining = d + " days";
          } else {
            x._expired = true;
          }
        }

        if (isSellInLoose) {
          let quantity = CommonService.convertQtyOnUom(x.quantity, "kg");
          x._quantity = quantity.qty;
          x._quantityUom = quantity.uom;
        }
      });

      return r;
    });
  }

  static downloadDealStockLedger(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/fetch/ledgerDownload",
      "GET",
      params
    );
  }

  static createFixedCombo(params) {
    return Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/fixedcartcombo/config",
      "POST",
      params
    );
  }

  static updateFixedCombo(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/fixedcartcombo/config/" +
        id,
      "PUT",
      params
    );
  }

  static async getFixedCombo(params) {
    const r = await Ajax.request(
      this.endpoint + "/deal/" + this.apiVersion + "/fixedcartcombo/config",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((x) => {
        x._totalCategories = x.cartDetails?.length || 0;
        x._totalProducts = (x.cartDetails || []).reduce(
          (total, category) => total + (category.dealsList?.length || 0),
          0
        );
      });
    }

    return r;
  }

  static async removeFixedComboProduct(configId, categoryId, productId) {
    const config = await this.getFixedCombo({
      filter: {
        _id: configId,
      },
    });

    const categoryIndex = config.resp?.[0]?.cartDetails?.findIndex(
      (e) => e.category?.id === categoryId
    );

    if (categoryIndex === -1) {
      throw new Error("Category not found");
    }

    const productIndex = config.resp?.[0]?.cartDetails[
      categoryIndex
    ].dealsList.findIndex((e) => e.dealId === productId);

    if (productIndex === -1) {
      throw new Error("Product not found");
    }

    config.resp?.[0]?.cartDetails[categoryIndex].dealsList.splice(
      productIndex,
      1
    );

    const payload = {
      ...config.resp?.[0],
      cartDetails: config.resp?.[0]?.cartDetails,
    };

    delete payload.auditLog;

    return this.updateFixedCombo(configId, payload);
  }

  static async updatedFixedComboProductQty(
    configId,
    categoryId,
    productId,
    newQuantity
  ) {
    const config = await this.getFixedCombo({
      filter: {
        _id: configId,
      },
    });

    const categoryIndex = config.resp?.[0]?.cartDetails?.findIndex(
      (e) => e.category?.id === categoryId
    );

    if (categoryIndex === -1) {
      throw new Error("Category not found");
    }

    const productIndex = config.resp?.[0]?.cartDetails[
      categoryIndex
    ].dealsList.findIndex((e) => e.dealId === productId);

    if (productIndex === -1) {
      throw new Error("Product not found");
    }

    // Update the quantity of the product
    config.resp[0].cartDetails[categoryIndex].dealsList[productIndex] = {
      ...config.resp[0].cartDetails[categoryIndex].dealsList[productIndex],
      quantity: newQuantity,
    };

    const payload = {
      ...config.resp?.[0],
      cartDetails: config.resp?.[0]?.cartDetails,
    };

    delete payload.auditLog;

    return this.updateFixedCombo(configId, payload);
  }

  static getFixedComboCount(params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/fixedcartcombo/config/count",
      "GET",
      params
    );
  }
}

export default Deal;
