import { yupResolver } from "@hookform/resolvers/yup";
import { DatePickerInput, TextInput } from "@sk/uis";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import debounce from "lodash/debounce";

const AuditLogValidationSchema = yup.object().shape({
  field: yup.string().trim(),
});

const dateConfig = { mode: "range" };

const FilterForm = ({ callback, filterData }) => {
  const { register, control, setValue, getValues } = useForm({
    defaultValue: {
      createdAt: [],
      field: "",
    },
    resolver: yupResolver(AuditLogValidationSchema),
  });

  const debouncedCallback = useCallback(
    debounce((data) => {
      callback({ status: "submit", formData: data });
    }, 300),
    []
  );

  const onDateChange = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        debouncedCallback(getValues());
      };
    },
    [debouncedCallback, getValues]
  );

  useEffect(() => {
    Object.keys(filterData).forEach((x) => {
      setValue(x, filterData[x]);
    });
  }, [filterData, setValue]);

  const onSearch = () => {
    debouncedCallback(getValues());
  };

  return (
    <div className="row mb-2">
      <div className="col-4">
        <TextInput
          name="field"
          register={register}
          label={"Field"}
          placeholder="Enter search field here ..."
          callback={onSearch}
        />
      </div>
      {/* Created On */}
      <div className="col-4">
        <label className=" mb-1 fs-val-md"> Created on </label>
        <Controller
          name="createdAt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePickerInput
              placeholder="Choose Date Range"
              value={value}
              inpChange={onDateChange(onChange)} // send value to hook form
              config={dateConfig}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FilterForm;
