import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderQueries {
  static endpoint = appConfigs.BUILDER_API;

  static create(data) {
    return Ajax.request(this.endpoint + "/builder/query", "POST", data);
  }

  static getList(params) {
    return Ajax.request(this.endpoint + "/builder/query", "GET", params);
  }

  static getDetail(id) {
    return Ajax.request(this.endpoint + "/builder/query/" + id, "GET");
  }

  static update = (id, data) => {
    return Ajax.request(this.endpoint + "/builder/query/" + id, "PUT", data);
  };
}
export default BuilderQueries;
