import { NavService } from "@sk/services";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import { menuList } from "../menus";

class SideMenuService {
  static toggleMenu() {
    const bdy = document.body;
    bdy.classList.toggle("enlarge-menu");
  }

  static doExpand() {
    const bdy = document.body;
    bdy.classList.remove("enlarge-menu");
  }

  static doCollapse() {
    const bdy = document.body;
    bdy.classList.add("enlarge-menu");
  }

  static nav(path, type, router, params = {}) {
    if (path) {
      if (/^(http|https)/gi.test(path)) {
        if (type == "newWindow") {
          window.open(path, "_blank");
        } else {
          window.open(path);
        }
      } else {
        if (type == "newWindow") {
          window.open("#" + path, "_blank");
        } else {
          NavService.to(router, path, params);
        }
      }
      this.doCollapse();
    }
  }

  static getMenus() {
    return menuList || [];
  }

  static getAllowedMenus(groups = []) {
    // Recursive function to filter menus based on user groups
    const filterMenusOnGroup = (menuData) => {
      const checker = (menu) => {
        let found = false;
        each(menu.groups, (group) => {
          found = groups.includes(group);
          return found ? false : true; // Break loop if found
        });

        if (menu.isLbl) {
          found = true; // Always allow labels
        }
        return found;
      };

      const filterRecursive = (menus) => {
        return menus.filter((menu) => {
          if (menu.subMenus && menu.subMenus.length > 0) {
            // Recursively filter submenus
            menu.subMenus = filterRecursive(menu.subMenus);
            return menu.subMenus.length > 0; // Keep parent if submenus exist
          }
          return checker(menu); // Check if menu is allowed
        });
      };

      return filterRecursive(cloneDeep(menuData));
    };

    if (groups.length > 0) {
      const filteredMenus = filterMenusOnGroup(this.getMenus());

      // Mark active menu based on current path
      const curPath = location.pathname;
      const markActiveMenu = (menus) => {
        let found = false;
        each(menus, (menu) => {
          if (menu.subMenus && menu.subMenus.length > 0) {
            found = markActiveMenu(menu.subMenus); // Recursively check submenus
            if (found) {
              menu.active = true; // Mark parent as active if submenu is active
            }
          } else if (menu.redirect?.path === curPath) {
            menu.active = true;
            found = true;
          }
          return !found; // Break loop if active menu is found
        });
        return found;
      };

      markActiveMenu(filteredMenus);

      return orderBy(filteredMenus, ["priority"], ["asc"]);
    }

    return [];
  }
}

export default SideMenuService;
