import classNames from "classnames";
import React, { useRef } from "react";
import FormInputLayout from "./FormInputLayout";

export const SwitchInput = ({
  label,
  error,
  isMandatory,
  value,
  callback,
  yesText = "Yes",
  noText = "No",
  note = "",
  info = {},
  size = "wd-sm",
  gap,
}) => {
  const checkBoxRef = useRef();

  const onChange = () => {
    callback(checkBoxRef.current.checked);
  };

  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      note={note}
      info={info}
      gap={gap}
    >
      <label
        className={classNames(
          "app-switch-input",
          value == true ? "active" : "inactive",
          size
        )}
      >
        <input ref={checkBoxRef} type="checkbox" onChange={onChange} />
        <span
          className={classNames("tgl-btn", value == true ? "right" : "left")}
        ></span>
        {value ? (
          <span className={classNames("yes", size)}>{yesText}</span>
        ) : (
          <span className={classNames("no", size)}>{noText}</span>
        )}
      </label>
    </FormInputLayout>
  );
};

export default SwitchInput;
