import queryString from "query-string";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import { uniq } from "lodash";
class Grn {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static create(params) {
    return Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion,
      "POST",
      params
    );
  }

  static update(id, params) {
    return Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion + "/" + id,
      "PUT",
      params
    );
  }

  static formatGrnData(data) {
    let item = {
      ...data,
    };

    item._poIds = uniq(item.poIds);

    item.productDetails.forEach((product) => {
      product._poIds = uniq(product.poIds);
    });

    const statusUpdateLog = item.statusUpdateLog || [];
    const pendingPutawayLog = statusUpdateLog.find(
      (log) => log.newStatus === "Pending Putaway"
    );
    item.inwardedOn = pendingPutawayLog ? pendingPutawayLog.updatedOn : null;

    item.statusColor =
      this.getGrnStatuses().find((status) => status.value === item.status)
        ?.color || "secondary"; // Status color mapping

    // Calculate total invoiceQty
    item.totalInvoiceQty = item.productDetails.reduce(
      (sum, product) => sum + product.invoiceQuantity,
      0
    );

    // Calculate total receivedQuantity
    item.totalReceivedQty = item.productDetails.reduce(
      (sum, product) => sum + product.receivedQuantity,
      0
    );

    // Calculate grn total value
    item.totalInvoiceValue = item.productDetails.reduce(
      (sum, product) =>
        sum + product.price?.purchasePrice * product.receivedQuantity,
      0
    );

    item.uniquePos = this.getUniquePos(item.productDetails);

    console.log("item", item);

    return item;
  }

  static getUniquePos(products) {
    let po = {};
    products.forEach((product) => {
      product.poIds.forEach((poId) => {
        if (!po[poId]) {
          po[poId] = {
            poId: poId,
            invoiceQuantity: 0,
            receivedQuantity: 0,
            poQty: 0,
            grnValue: 0,
          };
        }

        po[poId].invoiceQuantity += product.invoiceQuantity;
        po[poId].receivedQuantity += product.receivedQuantity;
        po[poId].grnValue +=
          product.price?.purchasePrice * product.receivedQuantity;
      });
    });
    return Object.values(po);
  }

  static async getList(params) {
    const response = await Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion,
      "GET",
      params
    );
    if (Array.isArray(response.resp)) {
      response.resp = response.resp.map((item) => this.formatGrnData(item));
    }
    return response;
  }

  static async getGrnById(id, params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion + "/" + id,
      "GET",
      params
    );

    if (r.resp?._id) {
      r.resp = this.formatGrnData(r.resp);
    }

    return r;
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getVendorPurchaseSummary(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendorPurchaseSummary/" +
        id,
      "GET",
      params
    );
  }

  static getAutoDebitAdjustList(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendor/autoDebitAdjustment/" +
        id,
      "GET",
      params
    );
  }

  static getGrnDetailsForPrint(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/getGrnDetailsForPrint/" +
        id,
      "GET",
      params
    );
  }

  static getGrnAutoDebitAdjustment(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendor/autoDebitAdjustment/" +
        id,
      "GET",
      params
    );
  }
  static getGrnAutoDebitAdjustmentAuditLogs(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendor/autoDebitAdjustment/audit/logs",
      "GET",
      params
    );
  }
  static grnInwardWarning(params) {
    return Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion + "/grnInwardWarning",
      "POST",
      params
    );
  }

  static createGrnDebitNote(params) {
    return Ajax.request(
      this.endpoint + "/grn/" + this.apiVersion + "/vendor/debitnote/manual",
      "POST",
      params
    );
  }
  static updateGrnDebitNote(id, params) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendor/debitnote/manual/" +
        id,
      "PUT",
      params
    );
  }

  static updateVendorPayment(grnId, params = []) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendorpayment/update/" +
        grnId,
      "PUT",
      params
    );
  }

  static updateProductStatus(grnId, params) {
    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/productStatus/update/" +
        grnId,
      "PUT",
      params
    );
  }

  static updateVendorAutoDebitStatus(id, params) {
    let qs = queryString.stringify(params);

    return Ajax.request(
      this.endpoint +
        "/grn/" +
        this.apiVersion +
        "/vendor/autoDebitAdjustment/status/" +
        id +
        "?" +
        qs,
      "PUT",
      {}
    );
  }

  static getGrnStatuses() {
    return [
      {
        label: "Pending Stock Inward",
        value: "Pending Stock Inward",
        color: "warning",
      },
      {
        label: "Pending Inward Approval",
        value: "Pending Inward Approval",
        color: "info",
      },
      { label: "Pending Putaway", value: "Pending Putaway", color: "primary" },
      {
        label: "Pending Bill Matching",
        value: "Pending Bill Matching",
        color: "secondary",
      },
      { label: "Payment Pending", value: "Payment Pending", color: "danger" },
      { label: "Rejected", value: "Rejected", color: "dark" },
      { label: "Closed", value: "Closed", color: "success" },
    ];
  }

  static async getLastGrnDetailsByProductId(pId) {
    const params = {
      filter: {
        productDetails: { $elemMatch: { productId: pId } },
        status: { $nin: ["Rejected"] },
      },
      sort: {
        createdAt: -1,
      },
    };
    const response = await this.getList(params);
    if (Array.isArray(response.resp) && response.resp.length > 0) {
      const firstRecord = response.resp[0];
      const productDetails = firstRecord?.productDetails?.find(
        (e) => e.productId == pId
      );
      return {
        id: firstRecord._id,
        date: firstRecord.createdAt,
        qty: productDetails?.receivedQuantity,
        invoiceQty: productDetails?.invoiceQuantity,
        value:
          productDetails?.receivedQuantity *
          productDetails?.price?.purchasePrice,
      };
    }
    return {
      id: null,
      date: null,
      qty: 0,
      invoiceQty: 0,
      value: 0,
    };
  }
}
export default Grn;
