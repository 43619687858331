import { CommonService, Toaster, UtilityService } from "@sk/services";
import { AppCard, Spinner } from "@sk/uis";
import { set } from "date-fns";
import { useEffect, useRef, useState } from "react";
import NoDataFound from "../NoDataFound";
import FilterForm from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const defaultFormData = {
  createdAt: [],
  field: "",
};

const defaultSortOpt = { key: "createdAt", value: "desc" };

const AuditLogTable = ({ withCard = false, entityType, entityId }) => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [initialLoader, setInitialLoader] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const formDataRef = useRef({ ...defaultFormData });

  const paginationRef = useRef({ ...defaultPageOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const loadList = async (params = {}) => {
    // List
    setLoadingData(true);
    const r = await UtilityService.getMasterAuditLogs(params);
    if (r.statusCode !== 200) {
      Toaster.error("Failed to fetch list,Please Reload");
      setData([]);
      return;
    }
    const d = r.resp || [];
    const u = await CommonService.attachUserInfo(d, ["createdBy"]);
    setData(u);
    setLoadingData(false);
    // Count
    setLoadingTotalRecords(true);
    const c = await getCount(params);
    setLoadingTotalRecords(false);
    const t = c.statusCode == 200 && c.resp ? c.resp : 0;
    paginationRef.current.totalRecords = t;
  };

  const filterCb = async (data) => {
    paginationRef.current = { ...defaultPageOpt };
    if (data.status === "submit") {
      formDataRef.current = { ...data.formData };
    }
    if (data.status === "reset") {
      formDataRef.current = { ...defaultFormData };
    }
    applyFilter();
  };

  const applyFilter = () => {
    const p = getFilterParams();
    loadList(p);
  };

  const getFilterParams = () => {
    const p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {
        entityType: entityType,
        entityId: entityId,
      },
    };

    if (formDataRef.current.createdAt?.length) {
      p.filter.createdAt = {
        $gte: set(formDataRef.current.createdAt[0], {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(formDataRef.current.createdAt[1], {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      };
    }
    if (sortRef.current?.key) {
      p.sort =
        sortRef.current.value == "asc"
          ? sortRef.current.key
          : "-" + sortRef.current.key;
    }

    if (formDataRef.current.field && formDataRef.current.field.trim()) {
      p.filter.field = {
        $regex: formDataRef.current.field.trim(),
        $options: "gi",
      };
    }
    return p;
  };

  const paginationCb = async (data) => {
    paginationRef.current = { ...data };
    applyFilter();
  };

  const getCount = async (params = {}) => {
    return await UtilityService.getAuditLogsCount(params);
  };

  const init = async () => {
    formDataRef.current = { ...defaultFormData };
    setInitialLoader(true);
    const p = getFilterParams();
    await loadList(p);
    setInitialLoader(false);
  };

  useEffect(() => {
    if (entityId && entityType) {
      init();
    } else {
      setInitialLoader(false);
    }
  }, []);

  const sortCb = async (data) => {
    sortRef.current = { ...data };
    paginationRef.current = { ...defaultPageOpt };
    applyFilter();
  };

  const RenderTable = () => {
    return (
      <>
        <FilterForm callback={filterCb} filterData={formDataRef.current} />
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={{ ...paginationRef.current }}
          loadingTotalRecords={loadingTotalRecords}
          paginationCb={paginationCb}
        />
      </>
    );
  };

  return (
    <div>
      {!entityType && <NoDataFound>Entity Type Is Required</NoDataFound>}
      {entityType && entityId && (
        <>
          {initialLoader ? (
            <div className="text-center">
              <div className="d-inline-block">
                <Spinner />
              </div>
            </div>
          ) : withCard ? (
            <AppCard title="Audit Log">
              <RenderTable />
            </AppCard>
          ) : (
            <RenderTable />
          )}
        </>
      )}
    </div>
  );
};

export default AuditLogTable;
