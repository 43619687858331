import classNames from "classnames";
import { memo, useMemo } from "react";
import Amount from "../Amount";
import InfoPopover from "../InfoPopover";
import Spinner from "../Spinner";
import AppCard from "../AppCard";

const styles = {
  cardBody: {
    padding: ".5vw 1vw",
  },
};

const SummaryCardTwo = ({
  img,
  title,
  titleColor = "",
  valueColor = "",
  isValueAmt,
  amtDecimalPlace,
  loading,
  onSummaryCardClick,
  isClickable,
  value,
  data,
  info,
  cardHeight,
}) => {
  const cardStyle = useMemo(() => {
    if (cardHeight) {
      return { ...styles.cardBody, height: cardHeight };
    } else {
      return { ...styles.cardBody };
    }
  }, [cardHeight]);

  return (
    <AppCard
      noPad={true}
      className={classNames(isClickable ? "cursor-pointer" : "")}
      // style={styles.cardBody}
      onClick={() => {
        if (onSummaryCardClick && isClickable) {
          onSummaryCardClick(data);
        }
      }}
    >
      <div className="row justify-content-center align-items-center py-1 px-3">
        {/* Icon */}
        <div className="col-2 p-1">
          {img ? <img src={img} alt="icon-img" className="w-100" /> : null}
        </div>
        {/*end col*/}
        <div
          className={classNames(
            "col fs-val-normal my-2",
            titleColor ? "text-" + titleColor : ""
          )}
        >
          {title}
          {info.header || info.content ? (
            <InfoPopover
              header={info.header}
              content={info.content}
              placement={info.placement}
            />
          ) : null}
        </div>
        <div className="col-auto text-end">
          <div
            className={classNames(
              "fs-val-lg fw-bold",
              valueColor ? "text-" + valueColor : "text-muted"
            )}
          >
            {loading ? (
              <Spinner type="dots" />
            ) : (
              <>
                {isValueAmt ? (
                  <Amount value={value} decimalPlace={amtDecimalPlace} />
                ) : (
                  Number(value).toLocaleString("en-IN")
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(SummaryCardTwo);
