import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class LoyaltyPoint {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static async getStatement(params = {}) {
    const r = await Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/statement",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((e) => {
        if (e.payoutType === "Credit" || e.processedType === "Reward") {
          e._type = "Earned";
        }

        if (e.payoutType === "Debit") {
          if (e.processedType === "Redeem") {
            e._type = "Redeemed";
          } else if (e.processedType === "Rollback") {
            e._type = "Cancelled";
          } else if (e.processedType === "Expired") {
            e._type = "Expired";
          }
        }

        if (e.transactionType == "POSOrders") {
          e._transactionTypeLabel =
            e.payoutType == "Debit" ? "Order Redeem" : "Order Reward";
        } else if (e.transactionSubType == "ParentCoinReward") {
          e._transactionTypeLabel = "Friend Order Reward";
        } else if (e.transactionSubType == "ReferralCoupon") {
          e._transactionTypeLabel = "Club Coupon";
        } else if (e.transactionSubType == "NoInviteFirstorder") {
          e._transactionTypeLabel = "Joining Bonus";
        } else if (/for referral/gi.test(e.remarks)) {
          e._transactionTypeLabel = "Referral Reward";
          e._isReferral = true;
        } else if (/against coupon/gi.test(e.remarks)) {
          e._transactionTypeLabel =
            e.payoutType == "Credit" ? "Coupon Reward" : "Coupon Debit";
        } else if (e.transactionType === "WYPParticipants") {
          e._transactionTypeLabel = "Contest id";
        } else if (
          e.transactionType === "Physical" &&
          e.payoutType == "Debit"
        ) {
          e._transactionTypeLabel = "Order Redeemed";
        } else if (e.transactionType === "mpServices") {
          e._transactionTypeLabel = "Listing id";
        } else if (e.transactionType === "ManualLPCredit") {
          e._transactionTypeLabel = "Manual";
        } else if (e.transactionType === "SchemesValuesToCoins") {
          e._transactionTypeLabel = "Scheme id";
        } else {
          e._transactionTypeLabel = e.transactionType;
        }

        // Replace KING COINS or KingCoins with SmartCoins in remarks
        e._remarks = e.remarks;
        //.replace(/KING COINS|KingCoins/gi, "SmartCoins");
      });
    }

    return r;
  }

  static async getExpiredPoints(ownerId, ownerType, params = {}) {
    const p = merge({}, params, {
      filter: {
        processedType: "Expired",
        payoutType: "Debit",
        ownerId,
        ownerType,
      },
    });
    const r = await this.getStatement(p);

    let points = 0;

    if (r.resp.length > 0) {
      points = (r.resp.find((x) => x.ownerType == ownerType) || { total: 0 })
        .total;
    }

    return { points };
  }

  static async getRewardSummary(params = {}) {
    const r = await Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/dashboard/getrewardsummary",
      "GET",
      params
    );
    return r;
  }
}

export default LoyaltyPoint;
