import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import { AuthService } from "../sk-services";

class Roles {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/roles", "GET", params);
  }

  static getCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/user/v1/rbac/roles/count",
      "GET",
      params
    );
  }

  static rbacGroup(params = {}) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/groups", "GET", params);
  }

  static getEmpRbacGroup(empId, params = {}) {
    const p = {
      filter: {
        empId,
      },
    };
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/rbac/employee/master",
      "GET",
      merge(p, params)
    );
  }

  static getGroupsOnUser(options) {
    return Ajax.request(
      this.endpoint + "/user/v1/permissionsPost",
      "POST",
      { mastername: "roles" },
      options || {}
    );
  }

  static async getEmpAssignedGroups(empId) {
    const r = await this.getGroupsOnUser();

    if (empId) {
      const r2 = await this.getEmpRbacGroup(empId, {
        page: 1,
        count: 1000,
      });

      if (r2.statusCode === 200 && Array.isArray(r2.resp)) {
        let empGroups = [];
        let storeIds = [];

        r2.resp.forEach((g) => {
          empGroups.push(
            ...g.groups.filter((g2) => g2.enabled).map((g2) => g2.id)
          );

          if (g.stores && Array.isArray(g.stores)) {
            storeIds.push(...g.stores.map((store) => store.id));
          }
        });

        if (!r.resp.user) {
          r.resp.user = {};
        }

        r.resp.stores = storeIds;

        if (!r.resp.user.groups) {
          r.resp.user.groups = [];
        }
        r.resp.user.groups.push(...empGroups);
        r.resp.user.groups.push(...storeIds);
      }
    }

    return r;
  }

  static createRole(params = {}) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/roles", "POST", params);
  }

  static updateRole(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/user/v1/rbac/roles/" + id,
      "PUT",
      params
    );
  }

  static deleteAccessRole(id) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/roles/" + id, "DELETE");
  }

  static async getDashboardEnabledStores(id) {
    let stores = [];
    const r = await this.getEmpRbacGroup(AuthService.getLoggedInEmpId(), {
      filter: { "groups.id": Array.isArray(id) ? { $in: id } : id },
    });

    if (r.statusCode === 200 && Array.isArray(r.resp) && r.resp.length > 0) {
      let keys = Array.isArray(id) ? id : id.split(",");

      stores = r.resp[0].groups
        ?.find((g) => keys.includes(g.id))
        ?.stores?.map((s) => s.id);
    }

    return { stores };
  }
}

export default Roles;
