import { memo, useCallback, useRef } from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Typeahead = ({
  val,
  searching,
  onSearch,
  result,
  placeholder,
  onItemChange,
  uid,
  isMultiple = false,
  disabled = false,
  template = (e) => <span>{e.label}</span>,
  emptyLabel = "No data found",
  size = null,
}) => {
  const inpRef = useRef(null);

  const onBlur = useCallback(() => {
    if (Array.isArray(val) && !val.length) {
      inpRef.current.clear();
    }
  }, [val]);
  return (
    <>
      <AsyncTypeahead
        multiple={isMultiple}
        id={uid}
        isLoading={searching}
        labelKey="label"
        minLength={2}
        onSearch={onSearch}
        options={result}
        filterBy={() => true}
        placeholder={placeholder || "Search..."}
        onChange={onItemChange}
        selected={val || []}
        renderMenuItemChildren={template}
        disabled={disabled}
        ref={inpRef}
        emptyLabel={emptyLabel}
        onBlur={onBlur}
        size={size}
        clearButton={true}
        useCache={false}
      />
    </>
  );
};

export default memo(Typeahead);
