import Ajax from "./ajax";
import { appConfigs } from "./config";

class Expense {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getExpensesList(params) {
    return Ajax.request(
      this.endpoint +
        "/franchise/" +
        this.apiVersion +
        "/expense/transaction/list",
      "GET",
      params
    );
  }
}

export default Expense;
