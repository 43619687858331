import { orderBy } from "lodash";
import { BrandService, CategoryService, ProductService } from "../sk-services";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import isAfter from "date-fns/isAfter";

class Vendor {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static async getVendorsAnalyticsOnWh(params = {}, appendVendorInfo = true) {
    let vendors = [];
    const r = await Ajax.request(
      this.endpoint +
        "/po/" +
        this.apiVersion +
        "/dashboard/getPoVendorDetailsByWhid",
      "GET",
      params
    );

    if (appendVendorInfo && Array.isArray(r?.resp)) {
      const vids = (r?.resp || []).map((x) => x.vId);
      if (vids.length > 0) {
        const v = await this.getVendors({
          select:
            "name,average_tat,address,credit_days,margins,contact,gst_no,communication",

          filter: { _id: { $in: vids } },
        });
        vendors = (v?.resp || []).map((x) => {
          const d = r.resp.find((e) => e.vId == x._id) || {};
          return { ...x, _analytics: d };
        });
      }
    }

    if (!appendVendorInfo) {
      vendors = r?.resp || [];
    }

    return vendors;
  }

  static async getVendors(params) {
    const url = this.endpoint + "/vendor/" + this.apiVersion;
    const r = await Ajax.request(url, "GET", params);
    if (Array.isArray(r?.resp)) {
      r.resp.forEach((x) => {
        x._contactDetails = x.contact?.find((y) => y.isOwner);
      });
    }
    return r;
  }

  static async getVendor(id, params) {
    const url = this.endpoint + "/vendor/" + this.apiVersion + "/" + id;
    const r = await Ajax.request(url, "GET", params);
    if (r?.resp) {
      r.resp._contactDetails = r.resp.contact?.find((y) => y.isOwner);
    }
    return r;
  }

  static updateVendor(id, params) {
    const url = this.endpoint + "/vendor/" + this.apiVersion + "/" + id;
    return Ajax.request(url, "PUT", params);
  }

  static createVendor(params) {
    const url = this.endpoint + "/vendor/" + this.apiVersion;
    return Ajax.request(url, "POST", params);
  }

  static getCount(params) {
    const url = this.endpoint + "/vendor/" + this.apiVersion + "/count";
    return Ajax.request(url, "GET", params);
  }

  static attachStatusToMargins(margins) {
    return (margins || []).map((x) => {
      x.status = isAfter(new Date(x.end_date), new Date())
        ? "Active"
        : "Expired";
      return x;
    });
  }
  static getVendorWiseSKUList(params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/getVendorWiseSKU",
      "GET",
      params
    );
  }

  static getVendorWiseSKUCount(params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/getVendorWiseSKUCount",
      "GET",
      params
    );
  }

  static getStatementList(params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/vendorStatement/list",
      "GET",
      params
    );
  }
  static getStatementCount(params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/vendorStatement/count",
      "GET",
      params
    );
  }

  static getBalanceByvendorId(params) {
    return Ajax.request(
      this.endpoint +
        "/vendor/" +
        this.apiVersion +
        "/vendorStatement/getBalanceByvendorId",
      "GET",
      params
    );
  }

  static getVendorProductsList(id, params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/getProduct/" + id,
      "GET",
      params
    );
  }

  static getVendorProductsCount(id, params) {
    return Ajax.request(
      this.endpoint + "/vendor/" + this.apiVersion + "/getProductcount/" + id,
      "GET",
      params
    );
  }

  static vendorMarginFilterHandler(formData, margins) {
    const { search, status, wareshouse } = formData;
    const s = search.toLowerCase();

    const filteredData = margins
      .filter((margin) => (status ? margin.status === status : true))
      .filter((margin) => (wareshouse ? margin.whId === wareshouse : true))
      .filter((margin) => {
        const brandName = margin._brand?.name?.toLowerCase() || "";
        const categoryName = margin._category?.name?.toLowerCase() || "";
        const productName = margin._product?.name?.toLowerCase() || "";

        const brandId = margin.brand || "";
        const categoryId = margin.category || "";
        const productId = margin.product || "";

        const bmatch = brandName.includes(s) || brandId.includes(s);
        const cmatch = categoryName.includes(s) || categoryId.includes(s);
        const pmatch = productName.includes(s) || productId.includes(s);

        return bmatch || cmatch || pmatch;
      });

    return filteredData;
  }

  static async getVendorMarginList(margins) {
    const getBrands = async (brands) => {
      if (brands.length > 0) {
        const r = await BrandService.getList({
          page: 1,
          count: brands.length,
          filter: { _id: { $in: brands } },
          select: "name",
        });

        return r.resp || [];
      } else {
        return [];
      }
    };

    const getCategories = async (categories) => {
      if (categories.length > 0) {
        const r = await CategoryService.getList({
          page: 1,
          count: categories.length,
          filter: { _id: { $in: categories } },
          select: "name",
        });

        return r.resp || [];
      } else {
        return [];
      }
    };

    const getProducts = async (products) => {
      if (products.length > 0) {
        const r = await ProductService.getList({
          page: 1,
          count: products.length,
          filter: { _id: { $in: products } },
          select: "name",
        });

        return r.resp || [];
      } else {
        return [];
      }
    };
    const brands = margins.map((margin) => margin.brand);
    const categories = margins.map((margin) => margin.category);
    const products = margins.map((margin) => margin.product);

    const promises = [
      getBrands(brands),
      getCategories(categories),
      getProducts(products),
    ];

    const results = await Promise.all(promises);
    const [brandsList, categoriesList, productsList] = results;

    const d = margins.map((margin) => {
      const brand = brandsList.find((b) => b._id === margin.brand);
      const category = categoriesList.find((c) => c._id === margin.category);
      const product = productsList.find((p) => p._id === margin.product);
      return {
        ...margin,
        _brand: brand || {},
        _category: category || {},
        _product: product || {},
      };
    });

    return orderBy(this.attachStatusToMargins(d), "start_date", "desc");
  }
}

export default Vendor;
