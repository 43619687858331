import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";
class Dashboard {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static prepareStockNotAllocatedSummaryParams(params, type) {
    let p = {
      filter: {
        // productId: { $ne: "PR20705" },
        ...(params.store?.length
          ? {
              franchiseId: {
                $in: params.store.map((x) => x.value._id),
              },
            }
          : {
              whId: {
                $in: [params?.warehouse || "WMF28"],
              },
            }),
      },
      batchId: params?.batchId || "",
    };

    if (params.store?.[0]?.value?._id) {
      p.filter.franchiseId = params.store[0].value._id;
      p.filter.franchiseName = params.store[0].value?.name;
    }

    if (params.menu?.length) {
      p.filter["productInfo.menuId"] = {
        $in: params.menu.map((x) => x.value._id),
      };
    }

    if (params.category?.length) {
      p.filter["productInfo.categoryId"] = {
        $in: params.category.map((x) => x.value._id),
      };
    }

    if (params.brand?.length) {
      p.filter["productInfo.brandId"] = {
        $in: params.brand.map((x) => x.value._id),
      };
    }

    if (params.companyName?.length) {
      p.filter["productInfo.companyName"] = {
        $in: params.companyName.map((x) => x.label),
      };
    }

    if (params.product?.length) {
      p.filter["productId"] = {
        $in: params.product.map((x) => x.value._id),
      };
    }

    p.queryType = type;
    return p;
  }

  // which takes filter form data and prepares the api filter params
  static prepareFilterParams(filterData, groupBy, pagination, sort) {
    let p = {
      page: pagination.activePage,
      count: pagination.rowsPerPage,
      outputType: "list",
      groupBy: groupBy,
      filter: {},
      reportType: "NEWREPORT",
    };

    if (sort?.key) {
      p.sortByType = sort.value || "asc";
      p.sortByCol = sort.key;
    }

    if (filterData.menu?.length) {
      p.filter.menuId = filterData.menu[0].value._id;
    }

    if (filterData.category?.length) {
      p.filter.categoryId = filterData.category[0].value._id;
    }

    if (filterData.product?.length) {
      p.filter.productId = filterData.product[0].value._id;
    }

    if (filterData.brand?.length) {
      p.filter.brandId = filterData.brand[0].value._id;
    }

    if (filterData.companyName?.length) {
      p.filter.companyName = filterData.companyName[0].value.name;
    }

    if (filterData.warehouse) {
      p.filter.whId = filterData.warehouse;
    }

    return p;
  }

  static async getWarehouseCitiesForStockSummaryDash() {
    let p = {
      groupBy: { whDistrict: "$whDistrict" },
      outputType: "list",
      sortByCol: "whDistrict",
      sortByType: "asc",
      reportType: "NEWREPORT",
    };
    const r = await this.getStockNotFoundDetails(p);
    return { data: r?.resp || [] };
  }

  static getStockNotAllowed(params, config = {}) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/dashboard/stocknotfound/PRODUCT_WISE_NOSTOCK",
      "GET",
      params,
      config
    );
  }

  static getStockNotFoundDetails(params, config) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/dashboard/stocknotfound",
      "GET",
      params,
      config
    );
  }

  static getBatchId() {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/getLatestBatchId",
      "GET",
      {}
    );
  }

  static async fetchStockAnalytics(params, config) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/fetch/stockanalytics",
      "GET",
      params,
      config
    );
  }

  static getHasStockNotAllocated(params, config) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/fetch/order/notAllocated",
      "GET",
      params,
      config
    );
  }
  static getAgeingTableData(params, config) {
    let p = merge({}, params, {
      queryType: "getAgeingStockSummary",
    });
    return this.fetchStockAnalytics(p, config);
  }

  static getStockNotFoundDetailsV2(params, config) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/fetch/stockanalytics",
      "GET",
      params,
      config
    );
  }

  static getUnallocatedStockProductWise(params) {
    const p = merge({}, params, {
      queryType: "getUnallocatedStockByOpenPO",
    });
    return this.fetchStockAnalytics(p);
  }

  static downloadTempFile(path) {
    let url =
      this.endpoint +
      "/analytics/" +
      this.apiVersion +
      "/fetch/stock/analytics/download/link?filePath=" +
      path;
    return url;
  }

  static getDealStockMasterDownloadLink(path) {
    return this.downloadTempFile(path);
  }

  static prepareInventoryAnalyticsParams(params, type) {
    let p = {
      filter: {
        whId: {
          $in: [params?.warehouse],
        },
      },
      batchId: params?.batchId || "",
    };

    if (params.store?.length) {
      delete p.filter.whId;
      p.filter.franchiseId = {
        $in: params.store.map((x) => x.value._id),
      };
    }

    if (params.menu?.length) {
      if (!p.filter.$or) {
        p.filter.$or = [];
      }
      p.filter.$or.push({
        "productInfo.menuId": {
          $in: params.menu.map((x) => x.value._id),
        },
      });
      p.filter.$or.push({
        "dealInfo.menuId": {
          $in: params.menu.map((x) => x.value._id),
        },
      });
    }

    if (params.category?.length) {
      if (!p.filter.$or) {
        p.filter.$or = [];
      }
      p.filter.$or.push({
        "productInfo.categoryId": {
          $in: params.category.map((x) => x.value._id),
        },
      });
      p.filter.$or.push({
        "productInfo.parentCategoryId": {
          $in: params.category.map((x) => x.value._id),
        },
      });
      p.filter.$or.push({
        "dealInfo.categoryId": {
          $in: params.category.map((x) => x.value._id),
        },
      });
      p.filter.$or.push({
        "dealInfo.parentCategoryId": {
          $in: params.category.map((x) => x.value._id),
        },
      });
    }

    if (params.brand?.length) {
      if (!p.filter.$or) {
        p.filter.$or = [];
      }
      p.filter.$or.push({
        "productInfo.brandId": {
          $in: params.brand.map((x) => x.value._id),
        },
      });
      p.filter.$or.push({
        "dealInfo.brandId": {
          $in: params.brand.map((x) => x.value._id),
        },
      });
    }

    if (params.companyName?.length) {
      if (!p.filter.$or) {
        p.filter.$or = [];
      }
      p.filter.$or.push({
        "productInfo.companyName": params.companyName,
      });
      p.filter.$or.push({
        "dealInfo.companyName": params.companyName,
      });
    }

    if (params.product?.length) {
      p.filter["productId"] = {
        $in: params.product.map((x) => x.value._id),
      };
    }

    if (params.inventoryStatus) {
      p.filter["inventoryInfo.inventoryStatus"] = params.inventoryStatus;
    }

    if (params.stockMovement) {
      p.filter["inventoryInfo.stockMovement"] = params.stockMovement;

      if (params.stockMovement === "NearExpiry") {
        p.filter["inventoryInfo.nearExpiry"] = true;
        delete p.filter["inventoryInfo.stockMovement"];
      }
    }

    if (params.health) {
      p.filter["inventoryInfo.inventoryHealth"] = params.health;
    }

    if (params.ageingSlab) {
      p.filter["inventoryInfo.ageingSlab"] = params.ageingSlab;
    }

    if (params.nonSellableType == "expired") {
      p.filter["sourceInfo.locationInfo.location"] = "Expiry";
    }

    if (params.nonSellableType == "damaged") {
      p.filter["sourceInfo.locationInfo.location"] = "Damage";
    }

    p.queryType = type;
    return p;
  }

  static async getInventoryAgeingBatchId(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/getLatestBatchIdForInventoryAgeing",
      "GET",
      params
    );
  }

  static async fetchInventoryAnalytics(params, config) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/fetch/inventoryageinganalytics",
      "GET",
      params,
      config
    );
  }

  static async fetchStoreInventoryAnalytics(params, config) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/fetch/store/inventoryageinganalytics",
      "GET",
      params,
      config
    );
  }

  static prepareNavParamsForInventoryAgeing(filterData, selected = []) {
    let p = { ...filterData };

    delete p.inventoryStatus;

    if (filterData.store?.length) {
      p.fid = filterData.store.map((item) => item.value._id).join("|");
      p.fName = filterData.store.map((item) => item.value.name).join("|");
      delete p.store;
    }

    if (filterData.menu?.length) {
      p.menuId = filterData.menu.map((item) => item.value._id).join("|");
      p.menuName = filterData.menu.map((item) => item.label).join("|");
      delete p.menu;
    }

    if (filterData.brand?.length) {
      p.brandId = filterData.brand.map((item) => item.value._id).join("|");
      p.brandName = filterData.brand.map((item) => item.label).join("|");
      delete p.brand;
    }

    if (filterData.category?.length) {
      p.categoryId = filterData.category
        .map((item) => item.value._id)
        .join("|");
      p.categoryName = filterData.category.map((item) => item.label).join("|");
      delete p.category;
    }

    selected.forEach((x) => {
      p[x.key] = x.value;
    });

    return p;
  }

  static prepareParamsForOrderPlacedNotAllocated(filterData, formData) {
    const params = {
      filter: {
        source: filterData.warehouse,
      },
      matchFilter: {},
      isMatched: false,
    };

    const search = (formData.search || "").trim();
    const franchiseSearch = (formData.franchiseSearch || "").trim();
    if (search) {
      const orderId = search.split("_")?.[0];
      params.filter.$or = [
        { _id: orderId },
        { "subOrders.id": orderId },
        { "subOrders.name": { $regex: search, $options: "i" } },
      ];
      params.matchFilter = {
        $or: [
          { "subOrders.name": { $regex: search, $options: "i" } },
          { "subOrders.id": orderId },
          { "subOrders._id": { $regex: search, $options: "i" } },
        ],
      };
    }

    if (filterData.product?.length > 0) {
      if (!params.matchFilter?.$or) {
        params.matchFilter.$or = [];
      }
      params.matchFilter.$or.push({
        "subOrders.product.id": { $in: filterData.product?.[0]?.value?._id },
      });
    }

    if (franchiseSearch) {
      if (!params.filter.$or || !search) {
        params.filter.$or = [];
      }
      params.filter.$or.push({
        "franchise.id": franchiseSearch,
      });
      params.filter.$or.push({
        "franchise.name": { $regex: franchiseSearch, $options: "i" },
      });
    }

    if (Object.keys(params.matchFilter).length === 0) {
      delete params.matchFilter;
    }

    return params;
  }
}

export default Dashboard;
