import classNames from "classnames";
import FormInputLayout from "./FormInputLayout";

const TextInput = ({
  label,
  type,
  register,
  name,
  error,
  placeholder,
  layout,
  isMandatory,
  callback,
  note = "",
  info = {},
  groupContent = null,
  maxLength,
  disabled = false,
  gap,
  size,
  readOnly = false,
}) => {
  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      type={type}
      note={note}
      info={info}
      gap={gap}
    >
      <div
        className={classNames({
          "input-group": groupContent,
        })}
      >
        <input
          type={type}
          {...register(name, {
            onChange: callback,
            valueAsNumber: type == "number",
          })}
          placeholder={placeholder}
          className={classNames(
            "form-control",
            size == "lg" ? "form-control-lg" : "",
            size == "sm" ? "form-control-sm" : ""
          )}
          onWheelCapture={(e) => e.target.blur()}
          maxLength={maxLength}
          disabled={disabled}
          autoComplete="off"
          readOnly={readOnly}
        />
        {groupContent ? (
          <span className="input-group-text p-0">{groupContent}</span>
        ) : null}
      </div>
    </FormInputLayout>
  );
};

export default TextInput;
