import { CommonService } from "../../../services/src/sk-services";

const DisplayUnit = ({ value, isLoose, noConv }) => {
  return isLoose
    ? noConv
      ? value + " kg"
      : CommonService.roundedByDecimalPlace(value / 1000, 3) + " kg"
    : value + " units";
};

export default DisplayUnit;
