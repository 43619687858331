import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";
class Category {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static create(params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion,
      "POST",
      params
    );
  }

  static update(id, params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/" + id,
      "PUT",
      params
    );
  }

  static async getList(params) {
    const r = await Ajax.request(
      this.endpoint + "/category/" + this.apiVersion,
      "GET",
      params
    );
    if (Array.isArray(r?.resp)) {
      r.resp.forEach((x) => {
        x._categoryType =
          x.categoryType == "FMCG" ? "Operating on Discount" : x.categoryType;
      });
    }
    return r;
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static async getCategory(id, params = {}, config = {}) {
    const r = await Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/" + id,
      "GET",
      params,
      config
    );
    if (r.resp && r.resp.categoryType) {
      r.resp._categoryType =
        r.resp.categoryType == "FMCG"
          ? "Operating on Discount"
          : r.resp.categoryType;
    }
    return r;
  }

  static getCategoryGroup(params = {}) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/categorygroup",
      "GET",
      params
    );
  }

  // https://qa.storeking.in/api/config/v1/mastercategory
  static getMasterCategory(params = {}) {
    return Ajax.request(
      this.endpoint + "/config/" + this.apiVersion + "/mastercategory",
      "GET",
      params
    );
  }
  static getBulkCategory(params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/bulk",
      "GET",
      params
    );
  }

  static getChildCategoryById(id, params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/" + id + "/child",
      "GET",
      params
    );
  }

  static getProductNotLinkedCount(params) {
    return Ajax.request(
      this.endpoint +
        "/category/" +
        this.apiVersion +
        "/productnotlinked/count",
      "GET",
      params
    );
  }

  static getSimilarCategoryList(params) {
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/getSimilarCategory",
      "GET",
      params
    );
  }

  static getMenus(params) {
    const p = merge(
      {},
      {
        filter: {
          status: "Active",
          isMainMenu: true,
          hasParentCategory: false,
        },
      },
      params
    );
    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion,
      "GET",
      p
    );
  }

  static getMenusCount(params) {
    const p = merge(
      {},
      {
        filter: {
          status: "Active",
          isMainMenu: true,
          hasParentCategory: false,
        },
      },
      params
    );

    delete p.page;
    delete p.count;

    return Ajax.request(
      this.endpoint + "/category/" + this.apiVersion + "/count",
      "GET",
      p
    );
  }
}
export default Category;
