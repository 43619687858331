import React from "react";
import { Accordion } from "react-bootstrap";

const AppAccordion = ({ items }) => {
  return (
    <Accordion>
      {items.map((item, index) => (
        <Accordion.Item
          eventKey={index.toString()}
          key={index}
          className="mb-2"
        >
          <Accordion.Header as="div">{item.title}</Accordion.Header>
          <Accordion.Body>{item.content}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default AppAccordion;
