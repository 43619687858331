import Ajax from "./ajax";
import { appConfigs } from "./config";
class CronJobs {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getList(params) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zoho",
      "GET",
      params
    );
  }

  static getCronJobById(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zoho/" + id,
      "GET",
      params
    );
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zoho/count",
      "GET",
      params
    );
  }

  static getErrorLogs(params) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zohoLog",
      "GET",
      params
    );
  }
  static getErrorLogsCount(params) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zohoLog/count",
      "GET",
      params
    );
  }

  static createAccountsToZoho(params) {
    return Ajax.request(
      this.endpoint +
        "/analytics/" +
        this.apiVersion +
        "/zoho/syncAccountsToZoho",
      "POST",
      params
    );
  }

  static updateCronJob(id, params) {
    return Ajax.request(
      this.endpoint + "/analytics/" + this.apiVersion + "/zoho/job/" + id,
      "PUT",
      params
    );
  }
}
export default CronJobs;
