export const contentMenus = {
  groups: [],
  icon: "file-text",
  name: "Content",
  priority: 2,
  redirect: {
    path: "",
    type: "",
  },
  subMenus: [
    {
      groups: ["ListBrand", "ListCategory", "ListProduct"],
      name: "CMS",
      redirect: {
        path: "/cms/overview",
      },
    },
    {
      groups: ["ViewCategory"],
      name: "Category",
      redirect: {
        path: "/cms/category/list",
      },
    },
    {
      groups: ["ViewBrand", "ViewCategory"],
      name: "Brand",
      redirect: {
        path: "/cms/brand/list",
      },
    },
    {
      groups: ["ViewProduct"],
      name: "Product",
      redirect: {
        path: "/cms/product/list",
      },
    },
    {
      groups: ["ListDeal"],
      name: "Deal",
      redirect: {
        path: "/cms/deal/list",
      },
    },
    {
      groups: ["ViewOutOfStockRequests"],
      name: "Out of Stock Requests",
      redirect: {
        path: "/cms/out-of-stock-requests",
      },
    },
  ],
};
