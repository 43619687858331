import { RolesService } from "@sk/services";
import produce from "immer";
import { memo, Suspense, useEffect, useState } from "react";

// const  = lazy(() =>
//   import("").then((mod) => mod.AsyncTypeahead)
// );

import { AsyncTypeahead } from "react-bootstrap-typeahead";
const GroupsAutocomplete = ({ callback, selectedData }) => {
  const [rolesList, setRolesList] = useState({
    loading: false,
    data: [],
    selected: [],
  });

  useEffect(() => {
    if (selectedData && Array.isArray(selectedData)) {
      setRolesList(
        produce((draft) => {
          draft.selected = selectedData.map((x) => ({
            name: x,
          }));
        })
      );
    }
  }, [selectedData]);

  const onRolesSearch = async (q) => {
    let p = {
      filter: { $and: [{ name: { $regex: "^" + q, $options: "gi" } }] },
      select: "name",
    };
    const selected = rolesList.selected.map((e) => e.name);
    if (selected.length > 0) {
      p.filter.$and.push({ name: { $nin: selected } });
    }
    setRolesList(
      produce((draft) => {
        draft.loading = true;
        draft.data = [];
      })
    );
    const a = await RolesService.rbacGroup(p);
    const d = (Array.isArray(a.resp) ? a.resp : []).map((e) => ({
      name: e.name,
    }));
    setRolesList(
      produce((draft) => {
        draft.loading = false;
        draft.data = d;
      })
    );
  };

  const onRoleChange = (data) => {
    setRolesList(
      produce((draft) => {
        draft.selected = data;
      })
    );
    callback({ data: (data || []).map((x) => x.name) });
  };

  return (
    <>
      <Suspense fallback={<div> Loading </div>}>
        <AsyncTypeahead
          id="roles"
          isLoading={rolesList.loading}
          labelKey="name"
          onSearch={onRolesSearch}
          options={rolesList.data}
          filterBy={() => true}
          placeholder="Search for Groups"
          onChange={onRoleChange}
          multiple
          selected={rolesList.selected}
          useCache={false}
          renderMenuItemChildren={(e) => <span>{e.name}</span>}
          delay={800}
        />
      </Suspense>
    </>
  );
};

export default memo(GroupsAutocomplete);
