import { memo } from "react";
import AppHeader from "../components/AppHeader";
import SideMenu from "../components/SideMenu";

const SideMenuLayout = ({ children, router }) => {
  return (
    <>
      <SideMenu router={router} />
      <AppHeader router={router} />
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid h-100">{children}</div>
        </div>
      </div>
    </>
  );
};

export default memo(SideMenuLayout);
