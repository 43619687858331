import { AjaxService } from "@sk/services";
import { appConfigs } from "./config";

class BuilderWorkflow {
  static endpoint = appConfigs.BUILDER_API;

  static cacheConfig = {};

  static create(data) {
    return AjaxService.request(
      this.endpoint + "/builder/workflow",
      "POST",
      data
    );
  }

  static getList(params) {
    let c = { ...this.cacheConfig };
    return AjaxService.request(
      this.endpoint + "/builder/workflow",
      "GET",
      params,
      c
    );
  }

  static getDetail(id) {
    let c = { ...this.cacheConfig };
    return AjaxService.request(
      this.endpoint + "/builder/workflow/" + id,
      "GET",
      {},
      c
    );
  }

  static update(id, data) {
    return AjaxService.request(
      this.endpoint + "/builder/workflow/" + id,
      "PUT",
      data
    );
  }

  static checkBuilderAccess(data) {
    return AjaxService.request(
      this.endpoint + "/utility/check-builder-access",
      "POST",
      data
    );
  }
}
export default BuilderWorkflow;
