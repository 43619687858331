import { merge } from "lodash";
import { AjaxService, appConfigs } from "../sk-services";
import { formatDistanceToNow } from "date-fns";

class ProjectService {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static async getList(params) {
    const response = await AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/project/list",
      "GET",
      params
    );

    if (Array.isArray(response.resp)) {
      response.resp.forEach((project) => {
        if (project.endDate) {
          const endDate = new Date(project.endDate);
          const today = new Date();
          project.daysRemaining = Math.floor(
            (endDate - today) / (1000 * 60 * 60 * 24)
          );
        }

        project.statusType = this.getProjectStatuses().find(
          (status) =>
            status.value?.toLowerCase() === project.status?.toLowerCase()
        )?.badgeColor;
      });
    }
    return response;
  }

  static getCount(params) {
    const p = { ...params, outputType: "count" };
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/project/list",
      "GET",
      p
    );
  }

  static createProject(payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/project",
      "POST",
      payload
    );
  }

  static updateProject(projectId, payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/project/" + projectId,
      "PUT",
      payload
    );
  }

  static async getTasksByProjectId(projectId, params) {
    let p = {};
    if (projectId) {
      p.filter = { projectId };
    }
    const r = await AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/task/list",
      "GET",
      merge(p, params)
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((task) => {
        task.statusType = this.getStatuses().find(
          (status) => status.value?.toLowerCase() === task.status?.toLowerCase()
        )?.badgeColor;

        task.priorityBadge = this.getPriorities().find(
          (priority) =>
            priority.value?.toLowerCase() === task.priority?.toLowerCase()
        )?.badgeColor;
      });
    }
    return r;
  }

  static createSubTask(payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/subtask",
      "POST",
      payload
    );
  }

  static updateSubTask(subTaskId, payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/subtask/" + subTaskId,
      "PUT",
      payload
    );
  }

  static getSubTasksByTaskId(taskId, params) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/subtask/list",
      "GET",
      merge({ filter: { taskId } }, params)
    );
  }

  static createTask(payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/task",
      "POST",
      payload
    );
  }

  static getTaskCount(params) {
    const p = { ...params, outputType: "count" };
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/task/list",
      "GET",
      p
    );
  }

  static updateTask(taskId, payload) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/task/" + taskId,
      "PUT",
      payload
    );
  }

  static getProjectFor() {
    return [
      { value: "DEV", label: "DEV" },
      { value: "QA", label: "QA" },
      { value: "OTHER", label: "OTHER" },
    ];
  }

  static getProjectStatuses() {
    return [
      { value: "Todo", label: "Todo", badgeColor: "primary" },
      { value: "InProgress", label: "InProgress", badgeColor: "warning" },
      { value: "Completed", label: "Completed", badgeColor: "success" },
      { value: "OnHold", label: "On Hold", badgeColor: "light" },
      { value: "Backlog", label: "Backlog", badgeColor: "light" },
    ];
  }

  static getStatuses() {
    return [
      { value: "Todo", label: "Todo", badgeColor: "primary" },
      { value: "Assigned", label: "Assigned", badgeColor: "info" },
      { value: "InProgress", label: "InProgress", badgeColor: "warning" },
      { value: "Completed", label: "Completed", badgeColor: "success" },
      { value: "ReOpen", label: "ReOpen", badgeColor: "secondary" },
      { value: "Blocked", label: "Blocked", badgeColor: "danger" },
      { value: "Deferred", label: "Deferred", badgeColor: "danger" },
      { value: "NeedClarity", label: "Need Clarity", badgeColor: "info" },
      { value: "QA", label: "In QA", badgeColor: "info" },
      { value: "Review", label: "In Review", badgeColor: "secondary" },
    ];
  }

  static getTaskTypes() {
    return [
      { value: "Task", label: "Task", badgeColor: "info" },
      { value: "Bug", label: "Bug", badgeColor: "danger" },
      { value: "RCA", label: "RCA", badgeColor: "warning" },
      { value: "Other", label: "Other", badgeColor: "dark" },
      // { value: "Epic", label: "Epic", badgeColor: "primary" },
      // { value: "UserStory", label: "UserStory", badgeColor: "warning" },
      // { value: "Feature", label: "Feature", badgeColor: "info" },
      // { value: "Improvement", label: "Improvement", badgeColor: "light" },
    ];
  }

  static getPriorities() {
    return [
      { value: "Low", label: "Low", badgeColor: "success" },
      { value: "Medium", label: "Medium", badgeColor: "warning" },
      { value: "High", label: "High", badgeColor: "danger" },
    ];
  }

  static async getTask(taskId) {
    const response = await AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/task/" + taskId,
      "GET"
    );

    if (Array.isArray(response.resp)) {
      response.resp.forEach((task) => {
        const taskTypeBadge = this.getTaskTypes().find(
          (type) => type.value?.toLowerCase() === task.type?.toLowerCase()
        )?.badgeColor;

        const statusBadge = this.getStatuses().find(
          (status) => status.value?.toLowerCase() === task.status?.toLowerCase()
        )?.badgeColor;

        const priorityBadge = this.getPriorities().find(
          (priority) =>
            priority.value?.toLowerCase() === task.priority?.toLowerCase()
        )?.badgeColor;

        task.taskTypeBadge = taskTypeBadge;
        task.statusBadge = statusBadge;
        task.priorityBadge = priorityBadge;
        task.lastModifiedBy = task.lastModifiedBy?.name;
      });
    }

    return response;
  }

  static async postComment(taskId, projectId, commentData) {
    const response = await AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/comment",
      "POST",
      {
        taskId,
        projectId,
        ...commentData,
      }
    );
    return response;
  }

  static async getComments(taskId, params) {
    const response = await AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/comment/" + taskId,
      "GET",
      params
    );

    if (Array.isArray(response.resp) && response.resp.length > 0) {
      response.resp.forEach((comment) => {
        const timeAgo = formatDistanceToNow(new Date(comment.createdAt), {
          addSuffix: true,
        });
        comment.createdAgo = timeAgo.includes("less than a minute ago")
          ? "Just now"
          : timeAgo;
        comment.userFirstLetter = comment.createdBy?.charAt(0).toUpperCase();
      });
    }

    return response;
  }

  static getAllComments(params) {
    return AjaxService.request(
      this.endpoint + "/taskman/" + this.apiVersion + "/comment/list",
      "GET",
      params
    );
  }

  static getTaskStatusesGroupFilters(statuses) {
    if (statuses.includes("inProgress")) {
      return ["InProgress", "ReOpen", "Todo", "Assigned"];
    }
    if (statuses.includes("blocked")) {
      return ["Blocked", "Deferred", "NeedClarity"];
    }
    return [];
  }
}

export default ProjectService;
