import { memo } from "react";
import TextEditor from "../TextEditor";
import FormInputLayout from "./FormInputLayout";

const TextEditorInput = ({
  label,
  type,
  error,
  layout,
  isMandatory,
  value,
  onChangeCb,
  note = "",
  info = {},
}) => {
  return (
    <FormInputLayout
      label={label}
      type={type}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      note={note}
      info={info}
    >
      <TextEditor value={value} onChange={onChangeCb} />
    </FormInputLayout>
  );
};

export default memo(TextEditorInput);
