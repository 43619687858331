import { memo } from "react";
import { Modal } from "react-bootstrap";

const BusyLoader = ({ show, callback, message, isCentered = true }) => {
  const invokeCallback = (action, data) => {
    if (callback) {
      callback({
        action: action,
        data,
      });
    }
  };

  const onHideCb = () => {
    invokeCallback("close");
  };

  return (
    <Modal
      show={show}
      backdrop={"static"}
      keyboard={false}
      onHide={onHideCb}
      centered={isCentered}
      autoFocus={false}
    >
      <Modal.Body>
        <div className="p-2">
          <div className="row align-items-center">
            <div className="col-auto">
              <span
                className="spinner-border spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
            <div className="col">{message || "Loading, please wait..."}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(BusyLoader);
