import { memo } from "react";
import FormInputLayout from "./FormInputLayout";

const BooleanCheckboxInput = ({
  label,
  name,
  error,
  layout,
  isMandatory,
  callback,
  value,
  note = "",
  labelCol,
  info = {},
  disabled = false,
  gap,
}) => {
  const valParser = (val) => {
    if (val == "true") {
      return true;
    }
    if (val == "false") {
      return false;
    }
    return val;
  };

  return (
    <FormInputLayout
      type="checkbox"
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      childAlignMiddle={true}
      note={note}
      labelCol={labelCol}
      info={info}
      disabled={disabled}
      gap={gap}
    >
      <input
        type="checkbox"
        disabled={disabled}
        className="form-check-input"
        checked={value == true}
        onChange={(e) => {
          callback(valParser(e.target.checked));
        }}
      />
    </FormInputLayout>
  );
};

export default memo(BooleanCheckboxInput);
