import { memo, useRef } from "react";
import Chip from "./Chip";

const ChipsArray = ({ data = [], onAdd, onRemove }) => {
  const inpRef = useRef();

  const onkeydown = (e) => {
    const v = (e.target.value || "").trim();
    if (e.which == 13 && v) {
      onAdd({ label: v });
      inpRef.current.value = "";
    }
  };

  const onChange = (e) => {
    const v = e.target.value;
    if (/,/gi.test(v)) {
      let t = v
        .split(",")
        .filter((x) => x.trim().length > 0)
        .map((x) => ({ label: x.trim() }));
      onAdd(t);
      inpRef.current.value = "";
    }
  };

  return (
    <div>
      {data.length > 0 ? (
        <div className="mb-1">
          {data.map((x, index) => (
            <Chip
              key={index}
              label={x.label}
              callback={onRemove}
              uniqKey={index}
              showRemove={true}
            />
          ))}
        </div>
      ) : null}
      <input
        className="form-control"
        placeholder="Type and press enter to submit"
        onKeyDown={onkeydown}
        onChange={onChange}
        ref={inpRef}
      />
    </div>
  );
};

export default memo(ChipsArray);
