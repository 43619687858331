import classNames from "classnames";
import { memo } from "react";
import Amount from "../Amount";
import InfoPopover from "../InfoPopover";
import Spinner from "../Spinner";
import AppCard from "../AppCard";

const styles = {
  cardBody: {
    padding: ".2vw .65vw",
  },
};

const SummaryCardThree = ({
  title,
  titleColor = "",
  valueColor = "",
  isValueAmt,
  amtDecimalPlace,
  loading,
  onSummaryCardClick,
  isClickable,
  value,
  data,
  info,
  noValParse = false,
}) => {
  // className={classNames("border rounded p-3 mb-2 bg-white", {
  //  "cursor-pointer": isClickable,
  // })}
  return (
    <AppCard
      noPad={true}
      onClick={() => {
        if (onSummaryCardClick && isClickable) {
          onSummaryCardClick(data);
        }
      }}
    >
      <div className={classNames("row align-items-center px-3 py-2")}>
        <div
          className={classNames(
            "col-auto fs-val-md fw-semibold me-3",
            titleColor ? "text-" + titleColor : ""
          )}
        >
          {title}
          {info.header || info.content ? (
            <InfoPopover
              header={info.header}
              content={info.content}
              placement={info.placement}
            />
          ) : null}
        </div>
        <div
          className={classNames(
            "col text-end fs-val-normal fw-bold",
            valueColor ? "text-" + valueColor : "text-muted"
          )}
        >
          {loading ? (
            <Spinner isSmall={true} />
          ) : (
            <>
              {isValueAmt ? (
                <Amount value={value} decimalPlace={amtDecimalPlace} />
              ) : noValParse ? (
                value
              ) : (
                Number(value).toLocaleString("en-IN")
              )}
            </>
          )}
        </div>
      </div>
    </AppCard>
  );
};

export default memo(SummaryCardThree);
