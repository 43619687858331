import classNames from "classnames";
import { Spinner } from "../sk-uis";

const PaginationSummary = ({
  paginationConfig,
  loadingTotalRecords,
  fwSize = "md",
  className = "",
}) => {
  return (
    <>
      {loadingTotalRecords ? (
        <span className="me-2">
          <Spinner isSmall={true} title="Loading..." />
        </span>
      ) : null}

      {!loadingTotalRecords && paginationConfig.totalRecords ? (
        <div
          className={classNames(
            className,
            "text-black-50",
            fwSize == "md" ? "fs-val-md" : "",
            fwSize == "sm" ? "fs-val-sm" : ""
          )}
        >
          <span>Showing </span>
          <span className="fw-semibold text-dark">
            {paginationConfig.startSlNo}
          </span>
          <span> to </span>
          <span className="fw-semibold text-dark">
            {paginationConfig.endSlNo < paginationConfig?.totalRecords
              ? paginationConfig?.endSlNo
              : paginationConfig?.totalRecords}
          </span>
          <span> of </span>
          <span className="fw-semibold text-dark">
            {Number(paginationConfig?.totalRecords).toLocaleString("en-IN")}
          </span>
          <span> records </span>
        </div>
      ) : null}
    </>
  );
};

export default PaginationSummary;
