import classNames from "classnames";
import { Fragment, useId } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const InfoPopover = ({
  header = "",
  content = "",
  placement = "top",
  size = "sm",
  className = "",
  children,
}) => {
  const uid = useId();

  return (
    <div
      className={classNames("d-inline-block", className, {
        "mx-1": !children,
      })}
      role="button"
      tabIndex={0}
    >
      <OverlayTrigger
        trigger="hover"
        placement={placement}
        overlay={
          <Popover className="info-tooltip">
            {header ? <Popover.Header as="h3">{header}</Popover.Header> : null}
            <Popover.Body>{content} </Popover.Body>
          </Popover>
        }
      >
        {children ? (
          <div className="d-inline-block" key={uid}>
            {children}
          </div>
        ) : (
          <span
            key={uid}
            className={classNames(
              "bi bi-question-octagon cursor-pointer",
              size == "sm" ? "fs-val-sm" : ""
            )}
          ></span>
        )}
      </OverlayTrigger>
    </div>
  );
};

export default InfoPopover;
