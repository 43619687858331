import classNames from "classnames";

const NoDataFound = ({ children, noMargin = false }) => {
  return (
    <div
      className={classNames(
        "alert alert-primary fs-7 fw-semibold d-flex align-items-center text-center",
        noMargin ? "mb-0 p-1" : "my-3 "
      )}
      role="alert"
    >
      <span className="bi bi-exclamation-circle px-2"></span>
      <div className="text-center">{children}</div>
    </div>
  );
};

export default NoDataFound;
