import classNames from "classnames";
import { Alert } from "react-bootstrap";

function InfoBlk({ children, variant = "primary", size = "md", noMargin }) {
  return (
    <Alert
      variant={variant}
      className={classNames({
        "py-2 px-3": size === "sm",
        "mb-2": !noMargin,
        "mb-0": noMargin,
      })}
    >
      {children}
    </Alert>
  );
}

export default InfoBlk;
