import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

// Define the styles outside the component
const hiddenInputStyle = {
  visibility: "hidden",
  height: 0,
  position: "absolute",
};

const presetButtons = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 Days", value: "last7days" },
  { label: "Last 15 Days", value: "last15days" },
  { label: "Last 30 Days", value: "last30days" },
  { label: "Last 2 Months", value: "last2months" },
  { label: "Last 3 Months", value: "last3months" },
  { label: "This Month", value: "thisMonth" },
  { label: "Last Month", value: "lastMonth" },
];

function DateRangePickerModal({
  show,
  handleClose,
  dateRange,
  setDateRange,
  minDate,
  maxDate,
}) {
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const fromPickrRef = useRef(null);
  const toPickrRef = useRef(null);
  const [tempDateRange, setTempDateRange] = useState(dateRange);

  useEffect(() => {
    if (show) {
      setTempDateRange(dateRange);
    }
  }, [dateRange, show]);

  // Initialize Flatpickr instances
  useEffect(() => {
    if (fromRef.current && toRef.current) {
      // Prepare common config options
      const commonConfig = {
        minDate: minDate || undefined,
        maxDate: maxDate || "today",
      };

      fromPickrRef.current = flatpickr(fromRef.current, {
        inline: true,
        defaultDate: tempDateRange[0],
        ...commonConfig,
        onChange: (selectedDates) => {
          setTempDateRange((prev) => {
            if (toPickrRef.current) {
              toPickrRef.current.set("minDate", selectedDates[0]);
            }
            if (prev[1] < selectedDates[0]) {
              return [selectedDates[0], selectedDates[0]];
            }
            return [selectedDates[0], prev[1]];
          });
        },
      });

      toPickrRef.current = flatpickr(toRef.current, {
        inline: true,
        defaultDate: tempDateRange[1],
        ...commonConfig,
        minDate: tempDateRange[0], // Set initial minDate to start date
        onChange: (selectedDates) => {
          setTempDateRange((prev) => {
            if (selectedDates[0] < prev[0]) {
              return [prev[0], prev[0]];
            }
            return [prev[0], selectedDates[0]];
          });
        },
      });

      // Cleanup function
      return () => {
        fromPickrRef.current?.destroy();
        toPickrRef.current?.destroy();
      };
    }
  }, [show, minDate, maxDate]);

  // Update Flatpickr instances when tempDateRange changes
  useEffect(() => {
    if (fromPickrRef.current && toPickrRef.current) {
      fromPickrRef.current.setDate(tempDateRange[0], false);
      toPickrRef.current.setDate(tempDateRange[1], false);
    }
  }, [tempDateRange]);

  // Memoize handlePresetClick to prevent unnecessary re-renders
  const handlePresetClick = useCallback(
    (range) => {
      const today = new Date();
      let startDate, endDate;

      // Ensure endDate doesn't exceed maxDate if provided
      const getValidEndDate = (date) => {
        if (maxDate && new Date(date) > new Date(maxDate)) {
          return new Date(maxDate);
        }
        return date;
      };

      // Ensure startDate isn't before minDate if provided
      const getValidStartDate = (date) => {
        if (minDate && new Date(date) < new Date(minDate)) {
          return new Date(minDate);
        }
        return date;
      };

      switch (range) {
        case "today":
          startDate = endDate = today;
          break;
        case "yesterday":
          startDate = endDate = new Date(today.setDate(today.getDate() - 1));
          break;
        case "last7days":
          startDate = new Date(today.setDate(today.getDate() - 7));
          endDate = new Date();
          break;
        case "last15days":
          startDate = new Date(today.setDate(today.getDate() - 15));
          endDate = new Date();
          break;
        case "last30days":
          startDate = new Date(today.setDate(today.getDate() - 30));
          endDate = new Date();
          break;
        case "last2months":
          startDate = new Date(today.setDate(today.getDate() - 60));
          endDate = new Date();
          break;
        case "last3months":
          startDate = new Date(today.setDate(today.getDate() - 90));
          endDate = new Date();
          break;
        case "thisMonth":
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case "lastMonth":
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          endDate = new Date(today.getFullYear(), today.getMonth(), 0);
          break;
        default:
          return;
      }

      // Validate dates against min/max constraints
      startDate = getValidStartDate(startDate);
      endDate = getValidEndDate(endDate);

      setDateRange([startDate, endDate]);
      handleClose();
      setTempDateRange([startDate, endDate]);
    },
    [minDate, maxDate, setDateRange, handleClose]
  );

  const handleApply = useCallback(() => {
    setDateRange(tempDateRange);
    handleClose();
  }, [tempDateRange, setDateRange, handleClose]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="bg-white text-dark" closeButton>
        <Modal.Title className="mb-0 text-dark">Select Date Range</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <div className="d-flex position-relative">
          <div className="preset-buttons me-4">
            {presetButtons.map((button) => (
              <button
                key={button.value}
                onClick={() => handlePresetClick(button.value)}
                className="btn btn-light btn-sm fs-val-sm mb-2 w-100"
              >
                {button.label}
              </button>
            ))}
          </div>

          <div className="calendar-container d-flex gap-4">
            <div>
              <label className="mb-2 d-flex align-items-center gap-2">
                From:{" "}
                <span className="text-muted">
                  {tempDateRange[0]?.toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </label>
              <input ref={fromRef} type="text" style={hiddenInputStyle} />
            </div>
            <div>
              <label className="mb-2 d-flex align-items-center gap-2">
                To:{" "}
                <span className="text-muted">
                  {tempDateRange[1]?.toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </label>
              <input ref={toRef} type="text" style={hiddenInputStyle} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleApply}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DateRangePickerModal;
