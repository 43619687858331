import Ajax from "./ajax";
import { appConfigs } from "./config";

class Commission {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getPosRewardList(params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/posreward/rules",
      "GET",
      params
    );
  }

  static getPosRewardListCount(params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/posreward/rules/count",
      "GET",
      params
    );
  }

  static createPosReward(params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/posreward/rules",
      "POST",
      params
    );
  }

  static updatePosRewards(id, params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/posreward/rules/" +
        id,
      "PUT",
      params
    );
  }

  static getPosConfigById(id, params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/posreward/rules/" +
        id,
      "GET",
      params
    );
  }

  static getManualCredits(params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/manualcredit",
      "GET",
      params
    );
  }

  static getManualCreditCount(params) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltypoints/manualcredit/count",
      "GET",
      params
    );
  }
}

export default Commission;
