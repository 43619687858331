import classNames from "classnames";
import React, { memo } from "react";

const Spinner = ({ color, isSmall = false, title, type = "round" }) => {
  return (
    <>
      {/* ROUND SPINNER */}

      {type == "round" ? (
        <span
          className={classNames("align-middle", {
            "spinner-border": true,
            "text-primary": color == "primary",
            "spinner-border-sm": isSmall,
          })}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </span>
      ) : null}

      {/* DOTS */}
      {type == "dots" ? <span className="lds-ellipsis">&nbsp;</span> : null}
      {title ? <span className="align-middle">{title}</span> : null}
    </>
  );
};

export default memo(Spinner);
