/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { memo, useEffect, useRef, useState } from "react";

const Pagination = ({
  totalRecords,
  rowsPerPage,
  callback,
  activePage = 1,
  size = "md",
}) => {
  const triggerCb = useRef(false);
  const maxSteps = 5;
  const [data, setData] = useState({
    showNext: false,
    showPrev: false,
    showLast: false,
    showFirst: false,
    activePage,
    pages: 1,
  });
  const [displayNums, setDisplayNums] = useState([]);

  useEffect(() => {
    const pages = Math.ceil(totalRecords / rowsPerPage);
    const hasMore = pages > maxSteps ? true : false;
    triggerCb.current = false;
    setData({
      showFirst: hasMore,
      showLast: hasMore,
      showNext: hasMore,
      showPrev: hasMore,
      activePage,
      pages: pages,
    });
  }, [totalRecords, activePage]);

  useEffect(() => {
    const n = getDisplayNums();
    setDisplayNums([...n]);
  }, [data.activePage, data.pages]);

  useEffect(() => {
    if (triggerCb.current && callback) {
      callback({
        activePage: data.activePage,
        startSlNo: data.activePage * rowsPerPage - rowsPerPage + 1 || 1,
        endSlNo: data.activePage * rowsPerPage,
      });
      triggerCb.current = false;
    }
  }, [data.activePage]);

  const getDisplayNums = () => {
    let arr = [];
    let iterVal = Math.round(maxSteps / 2);

    for (let i = data.activePage - iterVal; i < data.activePage; i++) {
      if (i > 0) {
        arr.push(i);
      }
    }

    let start = arr.length != iterVal ? arr.length + 1 : data.activePage;
    let last = data.activePage + iterVal + (iterVal - arr.length);

    for (let i = start; i < last; i++) {
      if (i <= data.pages) {
        arr.push(i);
      }
    }

    return arr;
  };

  const onNextClick = () => {
    const nxt = data.activePage + 1;
    if (nxt <= data.pages) {
      setData((v) => ({ ...v, activePage: nxt }));
      triggerCb.current = true;
    }
  };

  const onPrevClick = () => {
    const prev = data.activePage - 1;
    if (prev >= 1) {
      setData((v) => ({ ...v, activePage: prev }));
      triggerCb.current = true;
    }
  };

  const onLastClick = () => {
    setData((v) => ({ ...v, activePage: data.pages }));
    triggerCb.current = true;
  };

  const onFirstClick = () => {
    setData((v) => ({ ...v, activePage: 1 }));
    triggerCb.current = true;
  };

  const onNumClick = (val) => {
    setData((v) => ({ ...v, activePage: val }));
    triggerCb.current = true;
  };

  return (
    <>
      {totalRecords && displayNums.length > 1 ? (
        <nav aria-label="navigation" className="d-inline-block">
          <ul className={`pagination pagination-${size} cursor-pointer`}>
            {data.showFirst ? (
              <li
                className={
                  "page-item " + (data.activePage === 1 ? "disabled" : "")
                }
              >
                <a
                  role="button"
                  tabIndex={0}
                  className="page-link"
                  onClick={onFirstClick}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">First</span>
                </a>
              </li>
            ) : null}

            {data.showPrev ? (
              <li
                className={
                  "page-item " + (data.activePage === 1 ? "disabled" : "")
                }
              >
                <a
                  role="button"
                  tabIndex={0}
                  className="page-link"
                  onClick={onPrevClick}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">Previous</span>
                </a>
              </li>
            ) : null}

            {displayNums.map((e) => (
              <li
                className={
                  "page-item " + (data.activePage === e ? "active" : "")
                }
                key={e}
              >
                <a
                  className="page-link"
                  role="button"
                  tabIndex={0}
                  onClick={() => onNumClick(e)}
                >
                  {e}
                </a>
              </li>
            ))}

            {data.showNext ? (
              <li
                className={
                  "page-item " +
                  (data.activePage >= data.pages ? "disabled" : "")
                }
              >
                <a
                  className="page-link"
                  tabIndex={0}
                  role="button"
                  onClick={onNextClick}
                  aria-label="Next"
                >
                  <span aria-hidden="true">Next</span>
                </a>
              </li>
            ) : null}

            {data.showLast ? (
              <li
                className={
                  "page-item " +
                  (data.activePage >= data.pages ? "disabled" : "")
                }
              >
                <a
                  role="button"
                  tabIndex={0}
                  className="page-link"
                  onClick={onLastClick}
                  aria-label="Next"
                >
                  <span aria-hidden="true">Last</span>
                </a>
              </li>
            ) : null}
          </ul>
        </nav>
      ) : null}
    </>
  );
};

Pagination.defaultProps = {
  totalRecords: 0,
  rowsPerPage: 10,
};

export default memo(Pagination);
