import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderPage {
  static endpoint = appConfigs.BUILDER_API;

  static create(data) {
    return Ajax.request(this.endpoint + "/builder/pages", "POST", data);
  }

  static update = (id, data) => {
    return Ajax.request(this.endpoint + "/builder/pages/" + id, "PUT", data);
  };

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/builder/pages", "GET", params);
  }

  static getDetail(id) {
    return Ajax.request(this.endpoint + "/builder/pages/" + id, "GET");
  }

  static getRenderPageDetail(id) {
    return Ajax.request(this.endpoint + "/builder/pages/render/" + id, "GET");
  }
}

export default BuilderPage;
