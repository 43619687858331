import { merge } from "lodash";
import Ajax from "./ajax";
import { CommonService } from "../sk-services";

const { appConfigs } = require("./config");

class CoinDash {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getCoinsRewardedCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/coins/rewarded",
      "GET",
      params
    );
  }

  static getCoinsRedeemedCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/coins/redeemed",
      "GET",
      params
    );
  }

  static getRegCustomersCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/registered/customer",
      "GET",
      params
    );
  }

  static getClubCreatedCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/created",
      "GET",
      params
    );
  }

  static getTotalOrdersCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/customers/total",
      "GET",
      params
    );
  }

  static getRewardList(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/customer/reward/list",
      "GET",
      params
    );
  }

  static getCustomerList(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/customer/club/reward/list",
      "GET",
      params
    );
  }

  static getRewardCategoryList(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/reward/category/list",
      "GET",
      params
    );
  }

  static getMonthlySummary(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/monthly/coins/summary",
      "GET",
      params
    );
  }

  static getMonthlyCustomerGrowth(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/monthly/customer/growth",
      "GET",
      params
    );
  }

  static getCustMonthlySales(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/fetch/POSSalesOrder/customerWise",
      "GET",
      params
    );
  }

  static getClubMembers(id, params = {}) {
    const p = merge(
      {
        queryType: "childSummary",
        filter: {
          senderRefId: id,
        },
      },
      params
    );

    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/referalCoinsData",
      "GET",
      p
    );
  }

  static getCoinsCategorySummary(params = {}) {
    const p = merge(
      {
        queryType: "overall",
        outputType: "list",
      },
      params
    );

    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/referalCoinsData",
      "GET",
      p
    );
  }

  static getOrderCategoryData(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/category/order/reward",
      "GET",
      params
    );
  }

  static getStoreWise(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/store/list",
      "GET",
      params
    );
  }

  static getClubWise(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/list",
      "GET",
      params
    );
  }

  static getClubMembersSummary(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/cards/data/count",
      "GET",
      params
    );
  }

  static getClubMembersList(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/member/list",
      "GET",
      params
    );
  }

  static async getClubOrders(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/club/order/list",
      "GET",
      params
    );

    if (Array.isArray(r.resp) && r.resp.length) {
      r.resp.forEach((e) => {
        const { displayStatus, statusType } =
          CommonService.getPosOrderDisplayStatus(e.Status, e.OrderType);

        const statusGroup = CommonService.getPosOrderStatusGroups().find((s) =>
          s.statusGroup.includes(displayStatus)
        );
        e.displayStatus = statusGroup?.displayStatus || displayStatus;
        e.statusType = statusGroup?.statusType || statusType;
      });
    }

    return r;
  }

  static getRegCustomerList(params = {}) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/order/list",
      "GET",
      params
    );
  }

  static getTopPurchaseCustomerList(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/top/purchase/customer/list",
      "GET",
      params
    );
  }
}

export default CoinDash;
