import Ajax from "./ajax";
import { appConfigs } from "./config";
import { default as dtSet } from "date-fns/set";
import { default as dtSub } from "date-fns/sub";
import Category from "./category";
import Oms from "./oms";
import Warehouse from "./warehouse";
import Deal from "./deal";
import get from "lodash/get";
import Common from "./common";
import Brand from "./brand";
import { CommonService, DealService } from "../sk-services";

class Po {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getPoStatuses() {
    return [
      { label: "All", value: "", color: "secondary" },
      { label: "Draft", value: "Draft", color: "warning" },
      { label: "Submitted", value: "Submitted", color: "info" },
      { label: "Open", value: "Open", color: "primary" },
      {
        label: "Partially Inwarded",
        value: "Partially Inwarded",
        color: "warning",
      },
      { label: "Closed", value: "Closed", color: "success" },
      { label: "Rejected", value: "Rejected", color: "danger" },
      { label: "Deleted", value: "Deleted", color: "dark" },
      { label: "Cancelled", value: "Cancelled", color: "danger" },
    ];
  }

  static formatPoResp(resp) {
    let x = { ...resp };
    x._statusColor = this.getPoStatuses().find(
      (y) => y.value === x.status
    )?.color;

    x.totalQty = (x.products || []).reduce(
      (acc, prd) => acc + prd.quantity?.requested,
      0
    );

    x.totalInvoicedQty = (x.products || []).reduce(
      (acc, prd) => acc + prd.quantity?.filled,
      0
    );

    x._isDelayed = x.expectedAt && new Date(x.expectedAt) < new Date();

    return x;
  }

  static async getList(params) {
    const r = await Ajax.request(
      this.endpoint + "/po/" + this.apiVersion,
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        return this.formatPoResp(x);
      });
    }

    return r;
  }

  static createPo(params) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion,
      "POST",
      params
    );
  }
  static updatePo(params) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/" + params._id,
      "PUT",
      params
    );
  }

  static async getPoById(id, params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/" + id,
      "GET",
      params
    );

    r.resp = this.formatPoResp(r.resp);

    return r;
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getSuggestedQty(pid, params, config = {}) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/suggestedQuantity/" + pid,
      "GET",
      params,
      config
    );
  }

  static getOpenPoQty(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/openPoQty",
      "GET",
      params,
      config
    );
  }

  static getPriceVal({ dealerPrice, dealerMarginPrice, mrp }) {
    let p = 0;
    if (dealerPrice && dealerMarginPrice) {
      p = dealerPrice;
    } else {
      p = mrp;
    }
    return p;
  }

  static calculateUnitPrice({
    dealerPrice,
    dealerMarginPrice,
    baseMargin,
    additionalMargin,
    mrp,
    marginType,
  }) {
    const p = this.getPriceVal({ dealerPrice, dealerMarginPrice, mrp });
    let purchasePrice = p;

    if (baseMargin > 0) {
      purchasePrice = p * (1 - baseMargin / 100);
    }

    if (additionalMargin) {
      if (marginType === "percentage") {
        purchasePrice = purchasePrice * (1 - additionalMargin / 100);
      } else {
        purchasePrice = purchasePrice - additionalMargin;
      }
    }
    return purchasePrice;
  }

  static calculateFinalMargin({
    dealerPrice,
    dealerMarginPrice,
    purchasePrice,
    mrp,
  }) {
    const price = this.getPriceVal({ dealerPrice, dealerMarginPrice, mrp });
    return Common.roundedByDecimalPlace(
      ((price - purchasePrice) / price) * 100,
      2
    );
  }

  static calculatePurchasePrice(mrp, discount) {
    return mrp - (mrp * discount) / 100;
  }

  static calculateCM1({ b2bPrice, purchasePrice }) {
    if (!b2bPrice) {
      return 0;
    }
    return Common.roundedByDecimalPlace(
      100 - (purchasePrice.toFixed(2) / b2bPrice) * 100,
      2
    );
  }

  static getQtyVal({ qtyType, qty, caseQty, innerPackQty }) {
    const t = qtyType;
    let q = qty;
    if (!q) {
      return 0;
    }
    if (t === "case") {
      q *= caseQty;
    }
    if (t === "innercase") {
      q *= innerPackQty;
    }
    return Number(q);
  }

  // static calculateTotal(prd) {
  //   return this.getQtyVal(prd) * unitPrice;
  // }

  static calculateTotal({
    qtyType,
    qty,
    caseQty,
    innerPackQty,
    purchasePrice,
  }) {
    const t = qtyType;
    let q = qty * 1;
    if (!q) {
      return 0;
    }
    if (t === "case") {
      q *= caseQty;
    }
    if (t === "innercase") {
      q *= innerPackQty;
    }
    return q * purchasePrice;
  }

  static async getPoPrdB2BPrice(prd, wh, config = {}) {
    let price = 0;
    let margin = 0;
    const f = {
      page: 1,
      count: 1,
      filter: {
        product: { $elemMatch: { id: prd._id, quantity: { $eq: 1 } } },
        is_active: true,
      },
      select: "b2bPrice,margin",
    };
    const r = await Deal.getDeals(f, config);
    const d = Array.isArray(r?.resp) && r?.resp.length > 0 ? r.resp[0] : {};
    if (d._id) {
      const f1 = {
        page: 1,
        count: 1,
        filter: {
          dealId: d._id,
          isActive: true,
          whId: wh,
        },
        sort: "-createdAt",
        select: "margin",
      };
      const dResp = await Deal.getWarehouseConfig(f1);
      if (Array.isArray(dResp?.resp) && dResp?.resp.length > 0) {
        price = prd.mrp - (prd.mrp / 100) * (dResp.resp[0].margin || 0);
        margin = dResp.resp[0].margin || 0;
      } else {
        price = d.b2bPrice;
        margin = d.discount;
      }
    }
    return { price, margin: Common.roundedByDecimalPlace(margin, 2) };
  }

  static getBrand(brandId, config = {}) {
    if (!brandId) {
      return { resp: {} };
    }
    return Brand.getDetailsById(brandId, { select: "name" }, config);
  }

  static async prepareProductData(
    prd,
    vendorMargins,
    wh,
    options = {
      apiConfig: {},
    }
  ) {
    const pBrand = prd.brand[0];
    const pCategory = prd.category[0];
    const pid = prd._id;
    let baseMargin =
      (
        vendorMargins.find((x) => x.product == pid && x.whId == wh) || {
          margin: 0,
        }
      ).margin || 0;

    if (!baseMargin) {
      baseMargin =
        (
          vendorMargins.find(
            (x) =>
              (pBrand == x.brand || pCategory == x.category) && x.whId == wh
          ) || { margin: 0 }
        ).margin || 0;
    }

    if (!baseMargin) {
      return new Promise((resolve) =>
        resolve({ _id: pid, status: "error", msg: "Margin is not defined" })
      );
    }

    const last30Days = dtSub(
      new Date(),
      {
        days: 30,
      },
      { hours: 0, minutes: 0 }
    );
    const last60Days = dtSet(
      dtSub(new Date(), {
        days: 60,
      }),
      { hours: 0, minutes: 0 }
    );
    const last90Days = dtSet(
      dtSub(new Date(), {
        days: 90,
      }),
      { hours: 0, minutes: 0 }
    );

    const curDt = dtSet(new Date(), { hours: 59, minutes: 59 });

    let promises = [
      Warehouse.getStockInfo(wh, prd._id, {}, options?.apiConfig),
      this.getSuggestedQty(prd._id, { whId: wh }, options?.apiConfig),
      this.getOpenPoQty({ id: prd._id, whId: wh }, options?.apiConfig),
      Oms.getOrderCount(
        {
          filter: {
            "subOrders.products.id": prd._id,
            source: wh,
            createdAt: {
              $gte: last30Days,
              $lte: curDt,
            },
          },
        },
        options?.apiConfig
      ),
      Oms.getOrderCount(
        {
          filter: {
            "subOrders.products.id": prd._id,
            source: wh,
            createdAt: {
              $gte: last60Days,
              $lte: curDt,
            },
          },
        },
        options?.apiConfig
      ),
      Oms.getOrderCount(
        {
          filter: {
            "subOrders.products.id": prd._id,
            source: wh,
            createdAt: {
              $gte: last90Days,
              $lte: curDt,
            },
          },
        },
        options?.apiConfig
      ),
      Category.getCategory(
        prd.category[0],
        {
          select: "marginDealerPrice,name",
        },
        options?.apiConfig
      ),
      this.getPoPrdB2BPrice(prd, wh, options?.apiConfig),
      Oms.getWhProductCount(prd._id, wh, {}, options?.apiConfig),
      this.getBrand(prd.brand[0], options?.apiConfig),
      this.getLatestPriceByVendor(prd._id, wh, {}, options?.apiConfig),
      DealService.getDealsCount(
        {
          filter: { product: { $elemMatch: { id: prd._id } } },
        },
        options?.apiConfig
      ),
    ];

    const r = await Promise.all(promises);

    let o = {
      _suggestedQuantity: get(r, "[0].resp.suggestedQuantity", 0),
      _stock: get(r, "[0].resp", {}),
      _baseMargin: baseMargin,
      _openPoQty: get(r, "[2].resp", {}),
      _sales: {
        last30: get(r, "[3].resp", 0),
        last60: get(r, "[4].resp", 0),
        last90: get(r, "[5].resp", 0),
      },
      _marginDealerPrice: get(r, "[6].resp.marginDealerPrice", false),
      _priceInfo: get(r, "[7]", {}),
      _pendingOrder: get(r, "[8].resp", 0),
      _brand: get(r, "[9].resp", {}),
      _category: get(r, "[6].resp", false),
      _latestVendorPrice: get(r, "[10].resp", []),
      _dealCount: get(r, "[11].resp", 0),
    };

    let t = { ...prd, ...o };
    t._form = {
      marginType: "percentage",
      schemeMargin: "",
      qty: "",
      qtyType: "units",
    };
    t.unitPrice = this.calculateUnitPrice({
      dealerPrice: t.dealer_price,
      dealerMarginPrice: t._marginDealerPrice,
      additionalMargin: t._form?.schemeMargin,
      baseMargin: t._baseMargin,
      purchasePrice: t.unitPrice,
      mrp: t.mrp,
      marginType: t?._form?.marginType,
    });
    t._finalMargin = this.calculateFinalMargin({
      dealerPrice: t.dealer_price,
      dealerMarginPrice: t._marginDealerPrice,
      purchasePrice: t.unitPrice,
      mrp: t.mrp,
    });
    t._cm1 = this.calculateCM1({
      b2bPrice: t?._priceInfo?.price,
      purchasePrice: t.unitPrice,
    });
    t._total = this.calculateTotal({
      caseQty: t.caseQty,
      innerPackQty: t.innerPackQty,
      purchasePrice: t.unitPrice,
      qty: t._form.qty,
      qtyType: t._form.qty,
    });
    return t;
  }

  static formatPoProductResp(resp) {
    return {
      _id: resp._id,
      name: resp.name,
      brand: resp?._brand || {},
      category: resp?._category || {},
      barcode: resp.barcode,
      hsnNumber: resp.HSNNumber,
      tax: resp.tax,
      dealerPrice: resp.dealer_price,
      dealerMarginPrice: resp._marginDealerPrice,
      mrp: resp.mrp,
      b2bPrice: resp?._priceInfo?.price,
      purchasePrice: resp.unitPrice,
      cm1: resp._cm1,
      totalValue: resp._total,
      baseMargin: resp._baseMargin,
      additionalMargin: resp?._form?.schemeMargin,
      marginType: resp?._form?.marginType,
      qty: resp?._form?.qty,
      qtyType: resp?._form?.qtyType,
      finalMargin: resp?._finalMargin,
      caseQty: resp?.caseQty,
      shelfLife: resp?.shelf_life,
      innerPackQty: resp?.innerPackQty,
      currentStock: resp?._stock?.avaiableQty,
      pendingStock: resp?._pendingOrder,
      openPo: resp?._openPoQty?.qty,
      vendorList: resp?._openPoQty?.vendorList,
      suggestedQuantity: resp?._suggestedQuantity,
      purchase: {
        lastThirtyDays: resp?.PoQtyDetail?.lastThirtyDaysQty,
        lastSixtyDays: resp?.PoQtyDetail?.lastSixtyDaysQty,
        lastNinetyDays: resp?.PoQtyDetail?.lastNinetyDaysQty,
      },
      sales: {
        lastThirtyDays: resp?._sales?.last30,
        lastSixtyDays: resp?._sales?.last60,
        lastNinetyDays: resp?._sales?.last90,
      },
      previousPurchasePrice:
        resp?._latestVendorPrice?.[0]?.priceDetails?.purchasePrice,
      vendorPriceList: resp?._latestVendorPrice,
      resp: resp,
      mrpList: [],
    };
  }

  static async getPosPo(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/getPosPo",
      "GET",
      params
    );

    (Array.isArray(r.resp) ? r.resp : []).forEach((x) => {
      if (Array.isArray(x?.productList) && x?.productList?.length > 0) {
        const log = x.statusAuditLog || [];

        // x._statusLbl = x.status == "Closed" ? "Received" : x.status;

        x._statusLbl =
          x.status == "Received - Payment Pending"
            ? "Payment Pending"
            : x.status;

        x._approvedBy = log.find((x) => x.to == "Approved") || {};
        x._rejectedBy = log.find((x) => x.to == "Rejected") || {};

        /* attaching total value  */
        x.productList.forEach((resp) => {
          let q = 0;
          if (
            resp.status == "Partially Received" ||
            resp.status == "Received"
          ) {
            q = resp.receivedQuantity || 0;
          } else {
            q = resp.requestedQuantity || 0;
          }

          if (resp.sellInLooseQty) {
            resp.totalValue = CommonService.calculatePriceOnUom(
              resp.price,
              q,
              {
                packsize: resp.packSize,
                uom: resp.UoM,
              },
              resp.unitType
            ).total;
            resp._displayUnitType = resp.unitType;
            console.log(resp.totalValue);
          } else {
            resp._displayUnitType = "unit";
            resp.totalValue = q * resp.price;
          }
        });

        x.requestProductList.forEach((resp) => {
          let q = 0;

          q = resp.requestedQuantity || 0;

          if (resp.sellInLooseQty) {
            resp.totalValue = CommonService.calculatePriceOnUom(
              resp.price,
              q,
              {
                packsize: resp.packSize,
                uom: resp.UoM,
              },
              resp.unitType
            ).total;
            resp._displayUnitType = resp.unitType;
            console.log(resp.totalValue);
          } else {
            resp._displayUnitType = "unit";
            resp.totalValue = q * resp.price;
          }
        });
      }
    });

    return r;
  }

  static getPosPoCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/getPosPo/count",
      "GET",
      params
    );
  }

  static getLatestPriceByVendor(pId, whId, params = {}, config = {}) {
    return Ajax.request(
      this.endpoint +
        "/po/" +
        this.apiVersion +
        "/getlatestpriceByvendor/" +
        pId +
        "/" +
        whId,
      "GET",
      params,
      config
    );
  }

  static formatPoViewProductResp(prd = {}) {
    return {
      _id: prd.productId,
      name: prd.name,
      brand: prd?.brand || {},
      category: prd?.category || {},
      barcode: prd.barcode,
      hsnNumber: prd.HSNNumber,
      tax: prd.tax,
      dealerPrice: prd.dealerPrice,
      dealerMarginPrice: prd.marginDealerPrice,
      mrp: prd.mrp,
      b2bPrice: prd.dealerPrice,
      purchasePrice: prd.unitPrice,
      cm1: this.calculateCM1({
        b2bPrice: prd.dealerPrice,
        purchasePrice: prd.unitPrice,
      }),
      totalValue: prd.total,
      baseMargin: prd.margins?.bMargin,
      additionalMargin: prd?.margins?.sMargin,
      marginType: prd.margin?.sMarginType,
      qty: prd.quantity.requested,
      finalMargin: this.calculateFinalMargin({
        dealerPrice: prd.dealerPrice,
        dealerMarginPrice: prd.marginDealerPrice,
        purchasePrice: prd.unitPrice,
        mrp: prd.mrp,
      }),
      caseQty: prd?.caseQty,
      innerPackQty: prd?.innerPackQty,
      previousPurchasePrice: prd?.previousPurchasePrice,
      status: prd.status,
      resp: prd,
      rejectedRemarks: prd.remarks || "",
    };
  }

  static updatePoState(id, params) {
    return Ajax.request(
      this.endpoint + "/po/" + this.apiVersion + "/" + id + "/state",
      "POST",
      params
    );
  }
  static updatePoStatus(id, params) {
    return Ajax.request(
      this.endpoint +
        "/po/" +
        this.apiVersion +
        "/lockunlock/updatestatus/" +
        id,
      "PUT",
      params
    );
  }

  static formatPosPoProductResp(resp) {
    const finalMargin = resp.baseMargin + resp.additionalDiscount;
    const purchasePrice = this.calculatePurchasePrice(resp.mrp, finalMargin);
    let totalValue = 0;

    if (resp.sellInLooseQty) {
      totalValue = CommonService.calculatePriceOnUom(
        purchasePrice,
        resp.requestedQuantity * 1,
        {
          packsize: resp.packSize,
          uom: resp.UoM,
        },
        resp.unitType
      ).total;
    } else {
      totalValue = resp.requestedQuantity * 1 * purchasePrice;
    }

    const temp = {
      _id: resp.dealId,
      name: resp.name,
      brand: resp?.brand || {},
      category: resp?.category || {},
      barcode: resp.barcode,
      hsnNumber: resp.hsn,
      tax: resp.taxInfo.gst,
      mrp: resp.mrp,
      purchasePrice: purchasePrice,
      baseMargin: resp.baseMargin,
      invoiceDetails: resp?.invoiceDetails || [],
      finalMargin: finalMargin,
      additionalMargin: resp.additionalDiscount || 0,
      qty: resp?.requestedQuantity,
      shelfLife: resp?.shelfLife ?? "",
      currentStock: resp.sellInLooseQty
        ? resp.currentStock / 1000
        : resp.currentStock,
      pendingStock: resp?.pendingOrder,
      sellingPrice: resp?.sellingPrice,
      openPo: resp?.openPosPoQty,
      suggestedQuantity: resp?.suggested,
      manufactureDate: resp.manufactureDate ?? "",
      status: resp.status,
      packsize: resp.packSize,
      uom: resp.UoM,
      unitType: resp.unitType,
      receivedQuantity: resp?.receivedQuantity || 0,
      invoiceQty: resp?.invoiceQty,
      isDamaged: resp.isDamaged || false,
      damagedQty: resp.damageQty || "",
      damagedReason: resp.damagedReason || "",
      sellInLoose: resp.sellInLooseQty,
      _displayUnitType: resp._displayUnitType,
      totalValue: totalValue,
      purchase: {
        lastFifteenDays: resp.sellInLooseQty
          ? resp?.purchasedIn?.fifteenDays / 1000
          : resp?.purchasedIn?.fifteenDays,
        lastThirtyDays: resp.sellInLooseQty
          ? resp?.purchasedIn?.thirtyDays / 1000
          : resp?.purchasedIn?.thirtyDays,
        lastSixtyDays: resp.sellInLooseQty
          ? resp?.purchasedIn?.sixtyDays / 1000
          : resp?.purchasedIn?.sixtyDays,
      },
      sales: {
        lastThirtyDays: resp.sellInLooseQty
          ? resp?.soldIn?.thirtyDays / 1000
          : resp?.soldIn?.thirtyDays,
        lastSixtyDays: resp.sellInLooseQty
          ? resp?.soldIn?.sixtyDays / 1000
          : resp?.soldIn?.sixtyDays,
        lastNinetyDays: resp.sellInLooseQty
          ? resp?.soldIn?.ninetyDays / 1000
          : resp?.soldIn?.ninetyDays,
      },
      resp: resp,
    };

    return temp;
  }

  static formatEditPoProducts(resp) {
    return {
      _id: resp.productId,
      name: resp.name,
      brand: resp?.brand || {},
      category: resp?.category || {},
      barcode: resp.barcode,
      hsnNumber: resp.HSNNumber,
      tax: resp.tax,
      dealerPrice: resp.dealerPrice,
      dealerMarginPrice: resp.marginDealerPrice,
      mrp: resp.mrp,
      b2bPrice: resp?.dealerPrice,
      purchasePrice: resp.unitPrice,
      cm1: this.calculateCM1({
        b2bPrice: resp.dealerPrice,
        purchasePrice: resp.unitPrice,
      }),
      totalValue: resp.total,
      baseMargin: resp.margins?.bMargin,
      additionalMargin: resp?.margins?.sMargin,
      marginType: resp?.margin?.sMarginType,
      qty: resp?.quantity.requested,
      finalMargin: this.calculateFinalMargin({
        dealerPrice: resp.dealerPrice,
        dealerMarginPrice: resp.marginDealerPrice,
        purchasePrice: resp.unitPrice,
        mrp: resp.mrp,
      }),
      caseQty: resp?.caseQty,
      innerPackQty: resp?.innerPackQty,
      currentStock: resp?.availableQty,
      suggestedQuantity: resp?.quantity?.suggestedQuantity,
      purchase: {
        lastThirtyDays: resp?.order30,
        lastSixtyDays: resp.order60,
        lastNinetyDays: resp?.order90,
      },
      sales: {
        lastThirtyDays: resp?.salesIn30,
        lastSixtyDays: resp?.salesIn60,
        lastNinetyDays: resp?.salesIn90,
      },
      previousPurchasePrice: resp?.previousPurchasePrice,
      vendorPriceList: [],
      resp: resp,
      mrpList: resp.mrpList,
    };
  }

  static async getLastPoDetailsByProductId(pId) {
    const params = {
      filter: {
        ["products.productId"]: pId,
        status: { $nin: ["Rejected", "Draft", "Cancelled", "Deleted"] },
      },
      sort: {
        createdAt: -1,
      },
    };

    const response = await this.getList(params);
    if (Array.isArray(response.resp) && response.resp.length > 0) {
      const firstRecord = response.resp[0];
      const productDetails = firstRecord?.products?.find(
        (e) => e.productId == pId
      );

      return {
        id: firstRecord._id,
        date: firstRecord.createdAt,
        qty: productDetails?.quantity?.filled,
        requestedQty: productDetails?.quantity?.requested,
        value: productDetails?.total,
      };
    }

    return { id: "", date: "", qty: 0, requestedQty: 0, value: 0 };
  }
}

export default Po;
