import React, { useEffect, useState } from "react";
import DateRangePickerModal from "../modals/DateRangePickerModal"; // Adjust the import path as necessary
import { format } from "date-fns";
import classNames from "classnames";

function DatePickerRangeInput({
  value,
  callback,
  hideClose,
  disabled,
  minDate,
  maxDate,
  isSmallInput,
}) {
  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (value?.length === 2) {
      setDateRange([new Date(value[0]), new Date(value[1])]);
    }
    if (value?.length === 1) {
      setDateRange([new Date(value[0]), new Date(value[0])]);
    }
    if (value?.length === 0) {
      setDateRange([null, null]);
    }
  }, [value]);

  const handleOpenModal = () => {
    if (!disabled) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    handleCloseModal();
    callback(range);
  };

  const handleClearDateRange = () => {
    setDateRange([null, null]);
    callback([]); // Clear the callback as well
  };

  return (
    <div className="input-group">
      <input
        type="text"
        readOnly
        value={
          dateRange[0] && dateRange[1]
            ? `${format(dateRange[0], "dd MMM yyyy")} - ${format(
                dateRange[1],
                "dd MMM yyyy"
              )}`
            : ""
        }
        onClick={handleOpenModal}
        placeholder="Select date range"
        className={classNames("form-control", {
          "form-control-sm": isSmallInput,
        })}
        disabled={disabled}
      />
      {!hideClose && (
        <span className="input-group-append">
          <button
            className={classNames("btn btn-light", {
              "btn-sm": isSmallInput,
            })}
            onClick={handleClearDateRange}
          >
            <i className="bi bi-x" />
          </button>
        </span>
      )}
      <DateRangePickerModal
        show={showModal}
        handleClose={handleCloseModal}
        dateRange={dateRange}
        setDateRange={handleDateRangeChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default DatePickerRangeInput;
