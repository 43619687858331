import React from "react";
import { AuthService } from "@sk/services";
import { SelectInput } from "../sk-uis";

const states = (AuthService.getLoggedInEmp()?.warehouses || []).map((item) => ({
  label: item.state,
  value: item.state,
}));

const WarehouseStateSelect = ({ register, error, callback }) => {
  return (
    <SelectInput
      label="State"
      register={register}
      name="state"
      error={error}
      isMandatory={true}
      options={states}
      callback={callback}
    />
  );
};

export default WarehouseStateSelect;
