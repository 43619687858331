import { AuthService, EmployeeService, LoaderService } from "@sk/services";
import { Toaster } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const AssignedWarehouseModal = ({ show, callback }) => {
  const [data, setData] = useState([]);

  const [defaultWh] = useState(() => {
    const a = AuthService.getLoggedInEmp();
    return a?.defaultWh || {};
  });

  const onHideCb = () => {
    callback({ action: "close" });
  };

  useEffect(() => {
    const a = AuthService.getLoggedInEmp();
    const whs = a?.warehouses || [];
    let states = [];
    whs.forEach((x) => {
      if (states.indexOf(x.state) === -1) {
        states.push(x.state);
      }
    });
    let t = [];
    states.sort().forEach((x) => {
      t.push({
        state: x,
        whs: whs.filter((e) => e.state === x),
      });
    });
    setData([...t]);
  }, []);

  const changeDefaultWh = async (d) => {
    LoaderService.showPrgsLoader();
    const r = await EmployeeService.changeDefaultWh(d._id);
    LoaderService.hidePrgsLoader();
    if (r?.statusCode === 200) {
      const user = AuthService.getLoggedInEmp();
      AuthService.setEmpInLocal({ ...user, defaultWh: d });
      Toaster.success("Default warehouse changed successfully");
      callback({ action: "changed" });
    } else {
      Toaster.error(r?.resp?.message || "Failed to change, please try again");
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHideCb}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Assigned Warehouses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <p className="text-dark mb-1 fw-semibold">Default Warehouse</p>
            <div className="text-primary fw-semibold">{defaultWh.name}</div>
          </div>

          <hr />

          <div className="mb-2 text-muted">Change Default Warehouse</div>
          {data.map((x, k) => (
            <div key={k} className="mb-3">
              <p className="text-dark mb-1 fw-semibold">{x.state}</p>
              <ul>
                {x.whs.map((e, k1) => (
                  <li key={k1}>
                    <div className="row mb-2">
                      <div className="col">
                        <div className="mb-1">{e.name}</div>
                        {/* <div className="font-11 text-muted">{e.district}</div> */}
                      </div>
                      <div className="col-auto">
                        {defaultWh._id != e._id ? (
                          <span
                            className="cursor-pointer text-primary"
                            onClick={() => changeDefaultWh(e)}
                          >
                            Select
                          </span>
                        ) : (
                          <span className="text-success">Selected</span>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(AssignedWarehouseModal);
