import Ajax from "./ajax";
import { appConfigs } from "./config";

class DigitalPartner {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getDigitalPartners(params) {
    return Ajax.request(this.endpoint + "/digitalPartner/v1/", "GET", params);
  }

  static kycDocumentValidation(params) {
    return Ajax.request(
      this.endpoint +
        "/digitalPartner/" +
        this.apiVersion +
        "/kyc_documentVerification/validation",
      "POST",
      params
    );
  }

  static getTierConfigCount(params) {
    return Ajax.request(
      this.endpoint +
        "/digitalPartner/" +
        this.apiVersion +
        "/tierConfig/count",
      "GET",
      params
    );
  }

  static getlist(params) {
    return Ajax.request(
      this.endpoint + "/digitalPartner/" + this.apiVersion + "/",
      "GET",
      params
    );
  }

  static getTierConfigList(params) {
    return Ajax.request(
      this.endpoint + "/digitalPartner/" + this.apiVersion + "/tierConfig",
      "GET",
      params
    );
  }

  static updateTierConfig(id, params) {
    return Ajax.request(
      this.endpoint +
        "/digitalPartner/" +
        this.apiVersion +
        "/tierConfig/" +
        id,
      "PUT",
      params
    );
  }

  static createTierConfig(params) {
    return Ajax.request(
      this.endpoint + "/digitalPartner/" + this.apiVersion + "/tierConfig",
      "POST",
      params
    );
  }
}

export default DigitalPartner;
