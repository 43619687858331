import Ajax from "./ajax";
import { appConfigs } from "./config";
class Utility {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getRbacTypes() {
    return [
      { label: "Management", value: "Management" },
      { label: "Dashboard", value: "Dashboard" },
      { label: "Other", value: "Other" },
    ];
  }

  static getConfigCsaList(params = {}) {
    return Ajax.request(this.endpoint + "/config/csa", "GET", params);
  }

  static getExclusiveType(params) {
    return Ajax.request(
      this.endpoint + "/config/exclusiveTypes",
      "GET",
      params
    );
  }

  static getClassGroupList(params = {}) {
    return Ajax.request(
      this.endpoint + "/utilities/" + this.apiVersion + "/classgroup",
      "GET",
      params
    );
  }

  static getMasterAuditLogs(params) {
    return Ajax.request(
      this.endpoint + "/utilities/v1/auditLog",
      "GET",
      params
    );
  }

  static getAuditLogsCount(params) {
    return Ajax.request(
      this.endpoint + "/utilities/v1/auditLog/count",
      "GET",
      params
    );
  }
  static getClassificationList(params = {}) {
    return Ajax.request(
      this.endpoint + "/utilities/" + this.apiVersion + "/classclassification",
      "GET",
      params
    );
  }

  static getStateList(params = {}) {
    return Ajax.request(this.endpoint + "/config/states", "GET", params);
  }

  static getDistrictList(id, params = {}) {
    return Ajax.request(
      this.endpoint + "/config/states/" + id + "/districts",
      "GET",
      params
    );
  }

  static getTownList(id, district, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/config/states/" +
        id +
        "/districts/" +
        district +
        "/subdistricts",
      "GET",
      params
    );
  }

  static getBankList(params) {
    return Ajax.request(this.endpoint + "/config/bank", "GET", params);
  }

  static getKingSaleList(params) {
    return Ajax.request(
      this.endpoint + "/kingsale/" + this.apiVersion + "/",
      "GET",
      params
    );
  }

  static getRmfSubTypes(params) {
    return Ajax.request(this.endpoint + "/config/rmfSubTypes", "GET", params);
  }

  static getRfSubTypes(params) {
    return Ajax.request(this.endpoint + "/config/rfSubTypes", "GET", params);
  }

  static getSfSubTypes(params) {
    return Ajax.request(this.endpoint + "/config/sfSubTypes", "GET", params);
  }

  static getOfficeDesignations(params) {
    return Ajax.request(
      this.endpoint + "/config/officedesignation",
      "GET",
      params
    );
  }
}
export default Utility;
