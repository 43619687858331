// import HowToUseService from "";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CommonService } from "@sk/services";
import { Toaster } from "../sk-uis";

const HowToUseModal = ({ callback, topic }) => {
  const [show, setShow] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    // setData(HowToUseService.getData(topic));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const invokeCallback = (action, data) => {
    setShow(false);
    if (callback) {
      callback({
        action: action,
        data: data || {},
      });
    }
  };

  const onHideCb = () => {
    invokeCallback("close");
  };

  const copyCode = (code) => {
    CommonService.copyToClipboard(code);
    Toaster.success("Copied successfully");
  };

  return (
    <>
      <Modal show={show} backdrop={"static"} onHide={onHideCb} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="fs-5">How to use</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="rounded-bottom">
          <div className="mb-3 fs-6 text-primary">{data.description}</div>

          {data && data.params && data.params.length > 0 ? (
            <div className="mb-3">
              <div className="text-muted fs-7">Available Parameters:</div>
              <table className="table table-bordered table-condensed fs-7">
                <thead>
                  <tr>
                    <th>Param</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {data.params.map((e, k) => (
                    <tr key={k}>
                      <td>{e.lbl}</td>
                      <td>{e.desc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}

          {data && data.examples && data.examples.length > 0 ? (
            <div className="text-danger mb-3 fs-6">Examples</div>
          ) : null}
          {(data && data.examples ? data && data.examples : []).map((e, k) => (
            <div key={k} className="mb-3">
              <div className="fs-7">
                <div className="row">
                  <div className="col">{e.title}</div>
                  <div className="col-auto">
                    <span
                      className="cursor-pointer"
                      onClick={() => copyCode(e.code)}
                    >
                      Copy code
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-light p-3">
                <pre>{e.code}</pre>
              </div>
            </div>
          ))}
          <div></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

HowToUseModal.propTypes = {
  callback: PropTypes.func,
  topic: PropTypes.string,
};

HowToUseModal.defaultProps = {
  config: {},
};

export default memo(HowToUseModal);
