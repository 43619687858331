import { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ url, onStateChange, isPlaying }) => {
  const audioRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isPlaying]);

  const handlePlay = () => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      setIsLoading(true);
      audioRef.current
        .play()
        .then(() => {
          onStateChange(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          setIsLoading(false);
          onStateChange(false);
        });
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      onStateChange(false);
    }
  };

  const handleEnded = () => {
    onStateChange(false);
  };

  return (
    <>
      <button
        className={`btn ${
          isPlaying ? "btn-primary" : "btn-outline-secondary"
        } rounded-circle btn-sm m-0`}
        onClick={handlePlay}
        disabled={isLoading}
      >
        {isLoading ? (
          <i className="bi bi-arrow-clockwise"></i>
        ) : (
          <i className={`bi bi-${isPlaying ? "pause-fill" : "play-fill"}`}></i>
        )}
      </button>

      <audio ref={audioRef} className="d-none" onEnded={handleEnded}>
        <source src={url} type="audio/mpeg" />
      </audio>
    </>
  );
};

export default AudioPlayer;
