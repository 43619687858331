import { flatten, orderBy, uniq } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Logistics {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static async getStoreReturns(params = {}) {
    const r = await Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/fetchStoreReturnProcessData",
      "GET",
      params
    );

    if (r.statusCode == 200) {
      if (Array.isArray(r.resp) && params?.outputType != "count") {
        const statusMap = new Map(
          this.getStoreReturnStatus().map((status) => [status.value, status])
        );

        r.resp.forEach((x) => {
          const statusInfo = statusMap.get(x.status) || {
            statusType: "secondary",
          };

          x.whName = x.whInfo?.name;

          x._canVerifyInward = x.status == "Inward Approval Pending";
          x._canVerify = x.status == "Approval Pending";
          x._canAssignDriver = x.status == "Approved";
          x._canInward = x.status == "Handover";

          x._totalDeals = x.deals.length;

          let totalUnits = 0;
          let totalLooseQty = 0;

          x.deals.forEach((deal) => {
            deal.whInfo = x.whInfo || {};

            if (deal.sellInLooseQty) {
              totalLooseQty += deal.requestedQuantity;
            } else {
              totalUnits += deal.requestedQuantity;
            }

            deal._finalQty =
              deal.approvedQuantity ||
              deal.receivedQuantity ||
              deal.requestedQuantity;

            deal._statusType =
              deal.status === "Pending"
                ? "warning"
                : deal.status === "Approved"
                ? "success"
                : deal.status === "Rejected"
                ? "danger"
                : "secondary";

            deal._canShowLocInView =
              ["Inward Approval Pending", "Inwarded"].indexOf(x.status) != -1;
          });

          let displayParts = [];
          if (totalUnits > 0) {
            displayParts.push(`${totalUnits} units`);
          }
          if (totalLooseQty > 0) {
            displayParts.push(`${totalLooseQty}kg`);
          }
          x.displayStockUnitStr = displayParts.join(" ");

          x.statusType = statusInfo.statusType;
          x.statusDescription = statusInfo.description;

          x.displayStatus = statusInfo ? statusInfo.label : x.status;

          // Add displayStatus to auditLog entries
          if (Array.isArray(x.auditLog)) {
            x.auditLog.forEach((log) => {
              const logStatusInfo = statusMap.get(log.status);
              log.displayStatus = logStatusInfo
                ? logStatusInfo.label
                : log.status;
            });
          }
        });
      }
    }

    return r;
  }

  static async getStoreReturnsCount(params = {}) {
    const r = await this.getStoreReturns({ ...params, outputType: "count" });
    let count = 0;
    if (r.statusCode == 200 && Array.isArray(r.resp) && r.resp.length > 0) {
      count = r.resp[0].total;
    }
    return { count };
  }

  static rejectStoreReturn(id, reason) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/return/rejected/" +
        id,
      "PUT",
      { reason }
    );
  }

  static approveStoreReturn(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/return/approved/" +
        id,
      "PUT",
      { status: "Approved", ...params }
    );
  }

  static getStoreReturnStatus() {
    return [
      {
        label: "Approval Pending",
        value: "Approval Pending",
        statusType: "info",
        description:
          "The store return request is waiting for approval from the management.",
      },
      {
        label: "Pending Pickup",
        value: "Approved",
        statusType: "primary",
        description:
          "The return request has been approved and is waiting for pickup arrangement.",
      },
      {
        label: "Pickup Ready",
        value: "Pickup Ready",
        statusType: "warning",
        description:
          "A driver has been assigned and the items are ready for pickup.",
      },
      {
        label: "Yet To Inward",
        value: "Handover",
        statusType: "secondary",
        description:
          "The items have been picked up but not yet received at the warehouse.",
      },
      // {
      //   label: "Closed",
      //   value: "Closed",
      //   statusType: "success",
      //   description:
      //     "The return process has been completed and the case is closed.",
      // },
      {
        label: "Inward Approval Pending",
        value: "Inward Approval Pending",
        statusType: "info",
        description:
          "The items have been received but are waiting for inward approval.",
      },
      {
        label: "Inwarded",
        value: "Inwarded",
        statusType: "success",
        description:
          "The returned items have been received and processed at the warehouse.",
      },
      {
        label: "Rejected",
        value: "Rejected",
        statusType: "danger",
        description:
          "The store return request has been rejected by the management.",
      },
    ];
  }

  static assignDriverToStoreReturn(id, vehicleInfo) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/return/pickup/" +
        id,
      "PUT",
      { status: "Pickup Ready", vehicleInfo }
    );
  }

  static inwardStoreReturn(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/return/inward/" +
        id,
      "PUT",
      params
    );
  }

  static inwardStoreReturnWithScan(id, params) {
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/return/received/" +
        id,
      "PUT",
      params
    );
  }

  static getLocations() {
    return [
      { name: "APOBLocation" },
      { name: "Boxing" },
      { name: "Cold Storage" },
      { name: "Contest" },
      { name: "Damage" },
      { name: "Dock" },
      { name: "Expiry" },
      { name: "Hazmat" },
      { name: "Internal Assets" },
      { name: "KPB-HOLDING" },
      { name: "MSF Reservation" },
      { name: "Near Expiry" },
      { name: "Normal" },
      { name: "Quarantine" },
      { name: "Return In transit" },
      { name: "Strong Room" },
      { name: "Suspense" },
    ];
  }

  static async getWhAreas(whId, params = {}) {
    const url = `${this.endpoint}/wh/${this.apiVersion}/${whId}/area`;
    return Ajax.request(url, "GET", params);
  }

  static async getWhRacks(whId, areaId, params = {}) {
    const url = `${this.endpoint}/wh/${this.apiVersion}/${whId}/${areaId}/rack`;
    return Ajax.request(url, "GET", params);
  }

  static async getWhBins(whId, areaId, rackId, params = {}) {
    const url = `${this.endpoint}/wh/${this.apiVersion}/${whId}/${areaId}/rack/${rackId}`;
    return Ajax.request(url, "GET", params);
  }

  static async getStoreReturnBarcode(barcode, pids = [], sellerId = "") {
    const url = `${this.endpoint}/deal/${this.apiVersion}/getProductsByBarcode`;
    const params = {
      productIds: pids,
      sellerId,
      barcode,
    };
    return Ajax.request(url, "GET", params);
  }

  static getReturnRemarks() {
    const d = [
      { name: "Quality issues" },
      { name: "StoreKing Buying department return request" },
      { name: "Rejected wrong material receipt" },
      { name: "Excess stock ordered - Return approved by StoreKing" },
      { name: "Damage stock receipt" },
      { name: "Shortage stock delivery and refund request" },
      { name: "Nonmoving & return / RTV eligible" },
      { name: "Expired stock return / RTV eligible" },
      { name: "Rejected due to Low shelf-life / near to expiry stock receipt" },
      { name: "Return approved by StoreKing/Buying department" },
      { name: "Incomplete / standard packing label information not printed" },
    ];
    return orderBy(d, ["name"], ["asc"]);
  }

  static generateReturnInvoice(id, params = {}) {
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/generatePurchaseReturnInvoice?returnId=${id}`,
      "POST",
      params
    );
  }

  static async getLogistics(params = {}, fid, isSfSeller) {
    const url = `${this.endpoint}/logistics/${this.apiVersion}`;
    const r = await Ajax.request(url, "GET", params);

    r.resp = (r.resp || []).map((e) => {
      if (e.isRFLinkedOrder) {
        e._orderType = "Request Order";
        e._orderTypeCss = "req-ord";
      } else if (e.destination === fid) {
        e._orderType = "My Order";
        e._orderTypeCss = "my-ord";
      } else if (e.destination !== fid) {
        e._orderType = "Network Order";
        e._orderTypeCss = "net-ord";
      } else {
        e._orderType = "";
        e._orderTypeCss = "";
      }

      e._fetchFran = e.destination != fid;

      e._canReceive = false;
      if (isSfSeller) {
        e._canReceive =
          e.status == "Shipped" && /^(WMF|SEL)/gi.test(e.currNode);
      } else {
        e._canReceive = e.status == "Shipped" && e.destination == fid;
      }

      e._canClaim =
        (e.currNode === fid || e._canReceive) && e.status === "Shipped";
      e._isReceived = e.currNode === fid;
      e._isDispatchPending =
        e.currNode === fid && e.destination != fid && !e.inTransitToRF;

      e._boxNo = e.invoiceNo;
      if (e.destination != fid && e._canReceive) {
        e._boxNo = e.motherBox;
      }

      e.arrivingFromSk = new Date(
        new Date(e.shippedOn).getTime() + 24 * 60 * 60 * 1000
      );

      return e;
    });

    return r;
  }

  static async getLogisticsCount(params = {}) {
    const url = `${this.endpoint}/logistics/${this.apiVersion}/count`;
    return Ajax.request(url, "GET", params);
  }

  static getLogisticsStatusFilter(status, fid, sellerId, isSfSeller) {
    let ids = [];
    if (sellerId) {
      ids = [fid, sellerId];
    } else {
      ids = [fid];
    }
    let p = {
      filter: {},
    };

    if (status === "shipmentArrival") {
      p.filter.status = {
        $in: ["Shipped", "Seller Shipped"],
      };
      if (isSfSeller) {
        p.filter.chosenRoute = { $in: ids };
        p.filter.currNode = {
          $regex: "^(WMF|SEL)",
          $options: "gi",
        };
      } else {
        p.filter.destination = fid;
      }
    }

    if (status === "dispatchPending") {
      p.filter.chosenRoute = { $in: ids };
      p.filter.status = "Shipped";
      p.filter.inTransitToRF = false;
      p.filter.currNode = { $in: ids };
    }

    if (status === "outForDelivery") {
      p.filter.chosenRoute = { $in: ids };
      p.filter.status = "Shipped";
      p.filter.inTransitToRF = true;
      p.filter.currNode = { $in: ids };
    }

    if (status === "delivered") {
      p.filter.status = "Delivered";
      if (isSfSeller) {
        p.filter.prevNode = { $in: ids };
      } else {
        p.filter.currNode = { $in: ids };
        p.filter.destination = { $in: ids };
      }
    }

    if (!Object.keys(p.filter).length) {
      delete p.filter;
    }

    return p;
  }

  static getInvoiceDetail(ids) {
    const params = {
      filter: {
        _id: {
          $in: ids,
        },
      },
    };
    return this.getInvoice(params);
  }

  static async getInvoice(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/invoice",
      "GET",
      params
    );

    r.resp = (r.resp || []).map((e) => {
      e._packages = [];
      e.deals.forEach((x) => {
        x.products.forEach((p) => {
          (p.packages || []).forEach((pk) => {
            if (
              e._packages.indexOf(pk.packageNo) === -1 &&
              pk.quantity === x.quantity
            ) {
              e._packages.push(pk.packageNo);
            }
          });
        });
      });

      // for checking the price difference
      (e.items || []).forEach((x) => {
        const d = (e.deals || []).find((v) => v._id === x.soId) || {};

        let barcodes = (d.invoicedsnapshotReference || []).map(
          (d) => d.barcode
        );
        const fb = flatten(barcodes);
        x._barcodes = uniq(fb);
        x._whId = e.whId;
        x._hasMrpDiff = {
          status: d.mrp && d.mrp !== x.mrp,
          prevMrp: d.mrp,
          curMrp: x.mrp,
        };
      });

      return e;
    });

    return r;
  }

  static async prepareProductsOnInvoiceToIntake(
    invoiceNo,
    packageNo,
    boxDetails = {}
  ) {
    const invResp = await this.getInvoiceDetail([invoiceNo]);
    const invData = Array.isArray(invResp) ? invResp[0] : {};

    let noPipePattern = false;
    const compareDate = new Date(2023, 2, 31);
    const createdAtDate = new Date(invData.createdAt);
    const timeDiff = compareDate.getTime() - createdAtDate.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    if (dayDiff < 0) {
      noPipePattern = true;
    }

    let packageData = {};

    if (boxDetails?.orderBelongsTo != "ThirdPartySeller") {
      (invData.deals || []).forEach((d) => {
        (d.products || []).forEach((p) => {
          (p.packages || []).forEach((pk) => {
            if (pk.packageNo === packageNo) {
              packageData[noPipePattern ? d._id : d._id + "|" + pk.mrp] = {
                ...pk,
                pid: p.id,
                dealId: d.id,
              };
            }
          });
        });
      });
    }

    let soIds = [];
    let pids = [];
    let dealIds = [];

    Object.entries(packageData).forEach(([k, e]) => {
      soIds.push(k);
      pids.push(e.pid);
      dealIds.push(e.dealId);
    });

    if (boxDetails?.orderBelongsTo == "ThirdPartySeller") {
      return (invData.items || []).map((e) => {
        e._invoiceGenDt = invData.createdAt;
        e._invoiceNo = invData._id;
        return e;
      });
    } else {
      return (invData.items || [])
        .filter((e) => {
          let soIdMatched =
            soIds.indexOf(noPipePattern ? e.soId : e.soId + "|" + e.mrp) != -1;
          if (soIdMatched) {
            if (/^PR/.test(e._id)) {
              return pids.indexOf(e._id) != -1;
            } else {
              return dealIds.indexOf(e._id) != -1;
            }
          } else {
            return false;
          }
        })
        .map((e) => {
          const key = noPipePattern ? e.soId : e.soId + "|" + e.mrp;
          e.quantity = packageData[key].quantity;
          if (!noPipePattern) {
            e.mrp = packageData[key].mrp;
          }
          e._invoiceGenDt = invData.createdAt;
          e._invoiceNo = invData._id;
          return e;
        });
    }
  }
}

export default Logistics;
