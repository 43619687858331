import classNames from "classnames";

const TabContent = ({ children, className = "" }) => {
  return (
    <div className={classNames("tab-pane mt-n1  bg-white", className)}>
      {children}
    </div>
  );
};

export default TabContent;
