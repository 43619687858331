const BtnLink = ({ children, className, onClick, color = "text-dark" }) => {
  return (
    <button
      className={`btn btn-link p-0 text-start ${className} ${color}`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default BtnLink;
