import { format as dtFormat } from "date-fns";
import { memo } from "react";

const DateFormatter = ({ date, format }) => {
  return (
    <>
      {date ? dtFormat(new Date(date), format || "dd MMM yyyy hh:mm a") : null}
    </>
  );
};

export default memo(DateFormatter);
