import { memo } from "react";

const lblStyle = {
  height: "20px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

const AppliedFilterLabel = ({ labels = [], callback, className = "" }) => {
  return (
    <>
      {labels.length > 0 ? (
        <div className={"d-inline-block " + className}>
          <span className="fs-val-md text-black-50">Applied Filter : </span>
          {labels.map((x) => (
            <span
              key={x.key}
              className="fs-val-sm badge rounded-pill badge-outline-primary me-2"
              style={lblStyle}
            >
              <span className="me-1 fw-normal">{x.label} : </span>
              <span className="">{x.value}</span>
              {x?.config?.canRemove ? (
                <button
                  className="btn btn-link btn-sm p-0 ms-1 my-0 text-dark"
                  onClick={() => callback({ action: "remove", data: x })}
                >
                  <span className="bi bi-x-circle text-muted"></span>
                </button>
              ) : null}
            </span>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default memo(AppliedFilterLabel);
