export const dashboardMenus = {
  groups: [],
  icon: "speedometer",
  name: "Dashboard",
  priority: 1,
  subMenus: [
    {
      groups: ["DarkStoreDashboard"],
      name: "Stores Summary",
      redirect: {
        path: "/dashboard/stores",
      },
    },
    {
      groups: ["DarkStoreSalesDashboard"],
      name: "Sales Analytics",
      redirect: {
        path: "/pos/sales-analytics",
      },
    },
    {
      groups: ["DarkStoreInventoryDashboard"],
      name: "Store Inventory",
      redirect: {
        path: "/pos/inventory",
      },
    },
    {
      groups: ["SCDashboard"],
      name: "Coins, Club, Customer",
      redirect: {
        path: "/dashboard/coins-v2",
      },
    },
    {
      groups: ["ListMarketinglog"],
      name: "Support Call Summary",
      redirect: {
        path: "/utility/marketing/logs",
      },
    },
    {
      groups: ["CustomerMissedCall"],
      name: "Customer Missed Call",
      redirect: {
        path: "/dashboard/customer-missed-call",
      },
    },
  ],
};
