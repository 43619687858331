import { useAuth } from "@sk/hooks";
import { AuthService } from "@sk/services";
const NoAccess = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "630px" }}
      >
        <div className="text-center">
          <div style={{ "font-size": "70px" }}>
            <i className="bi bi-file-earmark-lock text-muted"></i>
          </div>
          <h2>Access Denied</h2>
          <div className="fs-5 text-muted">
            You dont have permission to view this file
          </div>
          <div className="fs-5 text-muted">
            Request Access for your administrator
          </div>
        </div>
      </div>
    </>
  );
};

const ProtectedRoute = ({ children, roles = [] }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn && roles.length > 0) {
    return (location.hash = "#/auth/login");
  }

  if (roles.length > 0) {
    return AuthService.isValidUserRole(roles) ? children : <NoAccess />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
