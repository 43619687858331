import { useSetPageInfo } from "@sk/hooks";
import { NavService } from "@sk/services";
import classNames from "classnames";
import React, { memo } from "react";

const PageInfo = ({ title, breadcrumbs, navigate, noMargin = false }) => {
  useSetPageInfo({
    title,
  });
  const onTap = (data) => {
    if (data && data.link) {
      NavService.to(navigate, data.link, data?.params || {});
    }
  };

  return (
    <div className={classNames("row g-0", noMargin ? "" : "my-3")}>
      <div className="col-sm-12">
        <div
          className={classNames(
            "page-title-box pt-0",
            noMargin ? "pb-0" : "pb-2"
          )}
        >
          {breadcrumbs && breadcrumbs.length > 0 && (
            <div className="d-flex">
              {breadcrumbs.map((x, i) => (
                <React.Fragment key={i}>
                  <div
                    className={classNames(
                      "fs-val-md fw-semibold text-primary",
                      {
                        "text-muted": breadcrumbs.length - 1 == i,
                        "cursor-pointer": i < breadcrumbs.length - 1,
                      }
                    )}
                    onClick={() => onTap(x)}
                  >
                    {x.name}
                  </div>
                  {i < breadcrumbs.length - 1 && (
                    <div className="fs-val-md fw-bold text-primary">
                      <i className="bi bi-chevron-right px-1"></i>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        {/*end page-title-box*/}
      </div>
      {/*end col*/}
    </div>
  );
};

export default memo(PageInfo);
