import { memo, useEffect, useRef } from "react";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

const AppSwiper = ({ children, callback, config = {}, style = {} }) => {
  let swiperElRef = useRef(null);

  useEffect(() => {
    const swiperParams = Object.assign(
      {},
      {
        slidesPerView: 1,
      },
      config
    );

    Object.assign(swiperElRef.current, swiperParams);
    swiperElRef.current.initialize();

    if (callback) {
      callback({ swiperRef: swiperElRef.current });
    }
  }, [callback, config]);

  return (
    <>
      <swiper-container init="false" ref={swiperElRef} style={style}>
        {children}
      </swiper-container>
    </>
  );
};

export default memo(AppSwiper);
