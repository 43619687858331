import Ajax from "./ajax";
import { appConfigs } from "./config";

class seller {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getList(params) {
    return Ajax.request(
      this.endpoint + "/seller/" + this.apiVersion,
      "GET",
      params
    );
  }

  static getSeller(id) {
    return Ajax.request(
      this.endpoint + "/seller/" + this.apiVersion + "/" + id,
      "GET"
    );
  }
}

export default seller;
