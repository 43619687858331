import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderUser {
  static endpoint = appConfigs.BUILDER_API;

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/users", "GET", params);
  }

  static updateUser(id, params) {
    return Ajax.request(this.endpoint + "/users/" + id, "PUT", params);
  }
}

export default BuilderUser;
