import { merge } from "lodash";
import { CommonService } from "../sk-services";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Oms {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getOmsOrderStatus() {
    return [
      { label: "All", value: "", color: "primary" },
      { label: "Created", value: "Created", color: "info" },
      { label: "Confirmed", value: "Confirmed", color: "success" },
      { label: "Processing", value: "Processing", color: "warning" },
      { label: "Packed", value: "Packed", color: "warning" },
      { label: "Ready To Ship", value: "Ready To Ship", color: "warning" },
      { label: "Payment Initiated", value: "Payment Initiated", color: "info" },
      { label: "Seller Shipped", value: "Seller Shipped", color: "info" },
      { label: "Shipped", value: "Shipped", color: "info" },
      {
        label: "Partially Shipped",
        value: "Partially Shipped",
        color: "warning",
      },
      { label: "Delivered", value: "Delivered", color: "success" },
      { label: "Invoiced", value: "Invoiced", color: "info" },
      { label: "Closed", value: "Closed", color: "success" },
      { label: "Returned", value: "Returned", color: "danger" },
      {
        label: "Partially Returned",
        value: "Partially Returned",
        color: "warning",
      },
      { label: "Cancelled", value: "Cancelled", color: "danger" },
    ];
  }

  static getOrderGroupStatus() {
    return [
      { label: "All", value: "", color: "primary", statuses: [] },
      {
        label: "Allocated",
        value: "Allocated",
        color: "primary",
        statuses: [],
      },
      {
        label: "Not Allocated",
        value: "Not Allocated",
        color: "secondary",
        statuses: [],
      },
      { label: "Packed", value: "Packed", color: "warning", statuses: [] },
      { label: "Shipped", value: "Shipped", color: "info", statuses: [] },
      {
        label: "Delivered",
        value: "Delivered",
        color: "success",
        statuses: [],
      },
      {
        label: "Not Packed",
        value: "Not Packed",
        color: "warning",
        statuses: [],
      },
      { label: "Cancelled", value: "Cancelled", color: "danger", statuses: [] },
      {
        label: "Returned",
        value: "Returned",
        color: "danger",
        statuses: [],
      },
    ];
  }

  static getOrderCount(params = {}, config = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/order/count",
      "GET",
      params,
      config
    );
  }

  static formatOrderResponse(item) {
    let order = { ...item };

    const statusColors = {
      Created: "info",
      Received: "success",
      "Payment Initiated": "warning",
      Confirmed: "primary",
      Invoiced: "secondary",
      Cancelled: "danger",
      Processing: "warning",
      "Partially Shipped": "warning",
      Shipped: "success",
      Closed: "dark",
      "Partially Delivered": "warning",
      Delivered: "success",
      "Partner Shipped": "info",
      "Seller Shipped": "info",
      ManifestGenerated: "info",
      "Ready To Ship": "info",
      "Partially Returned": "warning",
      Returned: "danger",
      Packed: "info",
      Pending: "warning",
    };

    order.commission = {
      ...order.commission,
      logistics: order.commission?.logistics || 0,
      skCommission: order.commission?.skCommission || 0,
      orderPassThrough: order.commission?.orderPassThrough || 0,
      total:
        (order.commission?.logistics || 0) +
        (order.commission?.skCommission || 0) +
        (order.commission?.orderPassThrough || 0),
    };

    const mainStatus = this.getOmsOrderStatus().find(
      (status) => status.value === order.status
    );

    const allocated = order.subOrders?.filter(
      (subOrder) => subOrder.reservationStatus === "Closed"
    );

    const notAllocated = order.subOrders?.filter(
      (subOrder) => subOrder.reservationStatus !== "Closed"
    );

    const packed = order.subOrders?.filter(
      (subOrder) => subOrder.status === "Packed"
    );

    const shipped = order.subOrders?.filter(
      (subOrder) => subOrder.status === "Shipped"
    );

    const delivered = order.subOrders?.filter(
      (subOrder) => subOrder.status === "Delivered"
    );

    const notPacked = order.subOrders?.filter((subOrder) =>
      ["Confirmed", "Created", "Processing", "Invoiced"].includes(
        subOrder.status
      )
    );

    const cancelled = order.subOrders?.filter(
      (subOrder) => subOrder.status === "Cancelled"
    );

    const returned = order.subOrders?.filter(
      (subOrder) =>
        subOrder.status === "Returned" ||
        subOrder.status === "Partially Returned"
    );

    const formattedOrder = {
      ...order,
      statusSummary: {
        allocated: {
          items: allocated.length,
          qty: allocated.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: allocated.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        notAllocated: {
          items: notAllocated.length,
          qty: notAllocated.reduce(
            (acc, subOrder) => acc + subOrder.quantity,
            0
          ),
          value: notAllocated.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        packed: {
          items: packed.length,
          qty: packed.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: packed.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        shipped: {
          items: shipped.length,
          qty: shipped.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: shipped.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        delivered: {
          items: delivered.length,
          qty: delivered.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: delivered.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        notPacked: {
          items: notPacked.length,
          qty: notPacked.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: notPacked.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        cancelled: {
          items: cancelled.length,
          qty: cancelled.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: cancelled.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        returned: {
          items: returned.length,
          qty: returned.reduce((acc, subOrder) => acc + subOrder.quantity, 0),
          value: returned.reduce(
            (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
            0
          ),
        },
        total: {
          items: order.subOrders?.length || 0,
          qty:
            order.subOrders?.reduce(
              (acc, subOrder) => acc + subOrder.quantity,
              0
            ) || 0,
          value:
            order.subOrders?.reduce(
              (acc, subOrder) => acc + subOrder.price * subOrder.quantity,
              0
            ) || 0,
        },
      },
      statusType: mainStatus?.color || "secondary",
      subOrders: order.subOrders?.map((subOrder) => ({
        ...subOrder,
        statusType: statusColors[subOrder.status] || "secondary",
      })),
    };

    return formattedOrder;
  }

  static async getOrderList(params = {}) {
    const response = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/order",
      "GET",
      params
    );

    if (response.statusCode === 200 && response.resp) {
      response.resp = response.resp.map((order) =>
        this.formatOrderResponse(order)
      );
    }

    return response;
  }

  static async getOrderDetails(id) {
    const params = {
      filter: {
        _id: id,
      },
    };
    const r = await this.getOrderList(params);

    if (r.statusCode == 200 && r.resp?.length > 0) {
      r.resp = this.formatOrderResponse(r.resp[0]);
    }

    return r;
  }

  static getWhProductCount(pid, wh, params = {}, config = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/order/product/" +
        pid +
        "/" +
        wh +
        "/count",
      "GET",
      params,
      config
    );
  }

  // to get data here they are using PUT call
  static getReserveOrderQtyList(params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/order/gettotalordersbyreserveconfig",
      "PUT",
      params
    );
  }

  static getProductBatchedCount(pid, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/productBatchedCount/" + pid,
      "GET",
      params
    );
  }

  static updatePosPoStatus(pid, params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/pospo/updatePoApproval/" +
        pid,
      "PUT",
      params
    );
  }

  static closePosPo(pid, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/closePo/" + pid,
      "PUT",
      params
    );
  }

  static getInvoiceList(params = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/invoice",
      "GET",
      params
    );
  }

  static createScannedPackage(params = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/invoice/packscan",
      "POST",
      params
    );
  }

  static async getIntakeSummaryList(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/intakeSummary",
      "GET",
      params
    );

    // Adding totalValue and _displayUnitType
    (Array.isArray(r.resp) ? r.resp : []).forEach((x) => {
      if (Array.isArray(x?.productList) && x?.productList?.length > 0) {
        x.productList.forEach((resp) => {
          let q = 0;
          if (
            resp.status == "Partially Received" ||
            resp.status == "Received"
          ) {
            q = resp.receivedQuantity || 0;
          } else {
            q = resp.requestedQuantity || 0;
          }

          if (resp.sellInLooseQty) {
            resp.totalValue = CommonService.calculatePriceOnUom(
              resp.price,
              q,
              {
                packsize: resp.packSize,
                uom: resp.UoM,
              },
              resp.unitType
            ).total;
            resp._displayUnitType = resp.unitType;
          } else {
            resp._displayUnitType = "unit";
            resp.totalValue = q * resp.price;
          }
        });
      }
    });

    return r;
  }

  /******** Batch Processing ********/

  static createBatch(params, type) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/batch/?type=" + type,
      "POST",
      params
    );
  }

  static async getBatchProcessingList(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/batch",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((x) => {
        let totalBatchProcessing = 0;

        // Check if preforma exists and has length
        if (x?.preforma?.length) {
          // Use reduce to calculate totalBatchProcessing
          totalBatchProcessing = x.preforma.reduce(
            (acc, p) => (p.status == "Pending" ? acc + 1 : acc),
            0
          );
        }

        x._totalPendingBatching = totalBatchProcessing;

        x._invoicePending = x.status !== "Cancelled" ? totalBatchProcessing : 0;
      });
    }
    return r;
  }

  static getBatchProcessingCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/batch/count",
      "GET",
      params
    );
  }

  static getBatchById(id) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/viewbatch/" + id,
      "GET"
    );
  }

  static getOrderByCategory(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/order/getPendingOrdersByCategory/" +
        id,
      "GET",
      params
    );
  }

  static removeDummyBatch(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/update/dummybatch" + "PUT",
      params
    );
  }

  static cancelProforma(id, params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/cancelPerformaInvoice/" +
        id,
      "PUT",
      params
    );
  }

  static cancelBatch(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/batch/",
      "PUT",
      params
    );
  }

  static forceClosePosPo(id, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/forceClosePo/" + id,
      "PUT",
      params
    );
  }

  static createPackage(id, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/createPackage/" + id,
      "POST",
      params
    );
  }

  static createInvoice(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/invoice/createInvoice",
      "POST",
      params
    );
  }

  static invoiceValidationRequestStatus(params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/validator/showValidationRequestStatus",
      "GET",
      params
    );
  }

  static revertInvoice(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        `/revertInvoicePackedStatus/${id}`,
      "PUT",
      params
    );
  }

  static updatePosPo(id, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pospo/updatePo/" + id,
      "PUT",
      params
    );
  }

  static updateInvoice(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        `/updatePackedOrderStatus/${id}`,
      "PUT",
      params
    );
  }

  static async getCashDepositList(params) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/posCashDeposit/fetchData",
      "GET",
      params
    );

    if (r.statusCode == 200 && Array.isArray(r.resp)) {
      r.resp.forEach((x) => {
        x._paymentMode =
          x.paymentMode == "PURCHASE" ? "In Store Purchase" : x.paymentMode;

        x.paymentModeStatusType =
          x.paymentMode === "PURCHASE"
            ? "success"
            : x.paymentMode === "UPI"
            ? "info"
            : x.paymentMode === "CASH"
            ? "warning"
            : "";
      });
    }

    return r;
  }

  static approveCashDeposit(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/posCashDeposit/approveReceipt/" +
        id,
      "PUT",
      params
    );
  }

  static rejectCashDeposit(id, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/posCashDeposit/rejectReceipt/" +
        id,
      "PUT",
      params
    );
  }

  static createAppLayoutConfig(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/clubLayout/config",
      "POST",
      params
    );
  }
  static updateAppLayoutConfigById(id, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/clubLayout/config/" + id,
      "PUT",
      params
    );
  }

  static async getAppLayoutConfig(params) {
    const r = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/clubLayout/config",
      "GET",
      params
    );

    if (r.statusCode == 200) {
      r.resp.forEach((e) => {
        if (e.validityStatus == "Running") {
          e._validityStatus = "Live";
        } else {
          e._validityStatus = e.validityStatus;
        }
      });
    }

    return r;
  }

  static getAppLayoutConfigById(id, params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/clubLayout/config/" + id,
      "GET",
      params
    );
  }

  static getAppLayoutConfigCount(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/clubLayout/config/count",
      "GET",
      params
    );
  }

  static getStoreOfferAnalyticsDetails(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pos/offers/analytics",
      "POST",
      params
    );
  }

  static getFranchiseOfferAnalyticsDetails(params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/pos/offers/franchise/analytics",
      "GET",
      params
    );
  }
  static getBulkSummary(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/fetchBulkInvoiceTempData",
      "GET",
      params
    );
  }
  static updateBulkSummary(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/updateBulkInvoiceTempData",
      "PUT",
      params
    );
  }
  static getBulkOrders(params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/fetchBulkInvoiceTempDataList",
      "GET",
      params
    );
  }

  static allocateStockForOrder(orderId, params) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/reserveByOrderId/" +
        orderId,
      "POST",
      params
    );
  }

  static getOrderSummary(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/fetch/order/card/summary",
      "GET",
      params
    );
  }

  static getOpenOrders(params, isCount = false) {
    const p = merge(
      {},
      {
        filter: {
          status: {
            $in: [
              "Created",
              "Confirmed",
              "Processing",
              "Packed",
              "Ready To Ship",
              "Payment Initiated",
              "Seller Shipped",
              "Shipped",
              "Partially Shipped",
            ],
          },
        },
      },
      params
    );
    return isCount ? this.getOrderCount(p) : this.getOrderList(p);
  }

  static getOrderAnalyticsSummary(params, config = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/fetch/order/data",
      "GET",
      params,
      config
    );
  }

  static getProductCountById(_productId, whId, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/order/product/" +
        _productId +
        "/" +
        whId +
        "/count",
      "GET",
      params
    );
  }

  static async getLatestOrderDetailsByProductId(productId) {
    const params = {
      filter: {
        ["subOrders.products.id"]: productId,
        status: { $nin: ["Cancelled", "Returned"] },
      },
      sort: {
        createdAt: -1,
      },
    };
    const response = await this.getOrderList(params);

    // Extracting the required data points
    if (response.resp && response.resp.length > 0) {
      const firstRecord = response.resp[0];
      const productDetails = firstRecord?.subOrders?.find((e) =>
        e.products.find((p) => p.id == productId)
      );

      return {
        id: firstRecord._id,
        date: firstRecord.createdAt,
        qty: productDetails?.quantity || 0,
        value: productDetails?.price * (productDetails?.quantity || 0),
      };
    }

    return {};
  }
}

export default Oms;
