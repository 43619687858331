import classNames from "classnames";
import PropTypes from "prop-types";

const TableHeader = ({
  data = [],
  centered = false,
  sort = {},
  sortCb,
  fwSize = "",
  noBg = false,
  fw = "semi",
  callback,
  templateData,
  children,
  isSticky = false,
  noBorder = false,
}) => {
  const styles = {
    sortContainer: {
      display: "inline-flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    sortIconContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "4px",
      marginRight: "4px",
      lineHeight: 1,
    },
    stickyHeader: {
      position: "sticky",
      left: 0,
      zIndex: 99,
    },
  };

  const handleSort = (item) => {
    if (!item.enableSort) return;

    const newSortValue =
      sort.key === item.key && sort.value === "asc" ? "desc" : "asc";
    sortCb({ key: item.key, value: newSortValue });
  };

  const getColumnStyle = (column) => {
    if (!column.isSticky)
      return {
        ...(column.style || {}),
      };

    // Base sticky styles
    const stickyStyles = {
      position: "sticky",
      zIndex: 99,
      background: noBg ? "white" : "var(--bs-light)",
    };

    // Handle both left and right sticky positions
    if (column.stickyPosition === "right") {
      return {
        ...stickyStyles,
        right: 0,
        zIndex: 98, // Slightly lower z-index for right sticky
        ...column.style,
      };
    } else if (column.stickyPosition === "left") {
      return {
        ...stickyStyles,
        left: 0,
        zIndex: 99, // Higher z-index for left sticky
        ...column.style,
      };
    }

    return {
      ...stickyStyles,
      ...column.style,
    };
  };

  const renderSortIcons = (column) => {
    if (!column.enableSort) return null;

    return (
      <div style={styles.sortIconContainer}>
        <span
          className={classNames(
            "bi fs-val-sm",
            sort.value === "asc" && sort.key === column.key
              ? "bi-caret-up-fill"
              : "bi-caret-up"
          )}
        />
        <span
          className={classNames(
            "bi fs-val-sm",
            sort.value === "desc" && sort.key === column.key
              ? "bi-caret-down-fill"
              : "bi-caret-down"
          )}
        />
      </div>
    );
  };

  const renderColumnContent = (column) => {
    const content = column.hasCustomTemplate
      ? column.template(callback, templateData)
      : column.label;

    return (
      <div
        className={classNames("d-flex align-items-center", {
          "cursor-pointer": column.enableSort,
          "justify-content-center": column.isCentered,
          "justify-content-end": column.isRightAligned,
          "fw-bold": fw === "bold",
          "fw-medium": fw === "medium",
          "fw-semibold": fw === "semi",
        })}
        style={styles.sortContainer}
        onClick={() => handleSort(column)}
      >
        <span>{content}</span>
        {renderSortIcons(column)}
      </div>
    );
  };

  return (
    <thead
      className={classNames("fs-val-md m-b-0", {
        "text-center": centered,
        "fs-val-md": fwSize === "md",
        "fs-val-sm": fwSize === "sm",
        "position-sticky top-0 bg-white z-100": isSticky,
      })}
    >
      <tr className={classNames("position-relative", { "bg-light": !noBg })}>
        {data.map((column, index) => (
          <th
            key={index}
            width={column.width || "auto"}
            scope="col"
            colSpan={column.colSpan}
            style={getColumnStyle(column)}
            className={classNames("text-dark", {
              "sticky-column": column.isSticky,
              "border-bottom-column": noBg && !noBorder,
            })}
          >
            {renderColumnContent(column)}
          </th>
        ))}
      </tr>
      {children}
    </thead>
  );
};

TableHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.string,
      label: PropTypes.node,
      colSpan: PropTypes.number,
      isSticky: PropTypes.bool,
      stickyPosition: PropTypes.oneOf(["left", "right"]),
      stickyStyle: PropTypes.object,
      enableSort: PropTypes.bool,
      hasCustomTemplate: PropTypes.bool,
      template: PropTypes.func,
      isCentered: PropTypes.bool,
      isRightAligned: PropTypes.bool,
    })
  ),
  centered: PropTypes.bool,
  sort: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOf(["asc", "desc"]),
  }),
  sortCb: PropTypes.func,
  fwSize: PropTypes.string,
  noBg: PropTypes.bool,
  fw: PropTypes.oneOf(["bold", "medium", "semi"]),
  callback: PropTypes.func,
  templateData: PropTypes.any,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export default TableHeader;
