import Swal from "sweetalert2";

class Alert {
  static alert = async (
    options = { title: "", text: "", icon: "", okText: "Ok" }
  ) => {
    const a = await Swal.fire({
      title: options.title,
      html: options.text,
      icon: options.icon,
      confirmButtonText: options.okText,
    });
    return a;
  };

  static confirm = async (
    options = {
      title: "",
      text: "",
      icon: "",
      okText: "Ok",
      cancelText: "Cancel",
    }
  ) => {
    return Swal.fire({
      title: options.title,
      html: options.text,
      icon: options.icon || "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: options.cancelText || "Cancel",
      confirmButtonText: options.okText || "Ok",
      reverseButtons: true,
      didClose: () => {
        document.body.style.paddingRight = "0px"; // Restore scrolling
      },
    });
  };
}

export default Alert;
