import { memo } from "react";
import FormInputLayout from "./FormInputLayout";

const Radio = ({
  label,
  name,
  error,
  layout,
  isMandatory,
  options,
  onChange,
  value,
  cols = 6,
  note = "",
  gap,
  info = {},
}) => {
  const valParser = (val) => {
    if (val == "true") {
      return true;
    }
    if (val == "false") {
      return false;
    }
    return val;
  };

  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      childAlignMiddle={true}
      note={note}
      gap={gap}
      info={info}
    >
      <div className="row">
        {options.map((e) => (
          <span key={e.value} className={"col-" + cols}>
            <input
              type="radio"
              className="form-check-input"
              value={e.value}
              checked={e.value == value}
              onChange={(el) => onChange(valParser(el.target.value))}
            />
            <span className="fs-val-md"> &nbsp; {e.label} </span>
          </span>
        ))}
      </div>
    </FormInputLayout>
  );
};

export default memo(Radio);
