import React, { memo, useEffect, useMemo, useState } from "react";
import ImgPreviewModal from "../modals/ImgPreviewModal";
import { appConfigs } from "@sk/services";
import noImg from "../images/placeholder.svg";

const ImgRender = ({
  assetId,
  width,
  height,
  allowPreview,
  isAsset = true,
  className,
}) => {
  const [imgErrSrc, setImgErrSrc] = useState("");

  useEffect(() => {
    setImgErrSrc("");
  }, [assetId]);

  const srcVal = useMemo(() => {
    let url = appConfigs.ASSET + "/" + assetId;
    let dimen = "";

    // if there is width and height then pass size=widthxheight example size=100x400
    if (width) {
      dimen += width;
    }
    if (dimen) {
      dimen += "x";
    }
    if (height) {
      dimen += height;
    }
    if (dimen) {
      return (url += "?size=" + dimen);
    } else {
      return url;
    }
  }, [assetId, width, height]);

  const previewAssets = useMemo(() => {
    return [{ id: assetId }];
  }, [assetId]);

  const [imgPreview, setImgPreview] = useState(false);

  const handleClose = () => {
    setImgPreview(false);
  };

  const onError = (ev) => {
    setImgErrSrc(noImg);
  };

  return (
    <>
      <img
        className={`w-100 ${className}`}
        src={isAsset ? imgErrSrc || srcVal : assetId}
        width={width}
        height={height}
        onClick={() => setImgPreview(true)}
        onError={onError}
      />

      {allowPreview && srcVal ? (
        <ImgPreviewModal
          show={imgPreview}
          handleClose={handleClose}
          assets={previewAssets}
        />
      ) : null}
    </>
  );
};

export default memo(ImgRender);
