import each from "lodash/each";
import { CommonService, StorageService } from "../sk-services";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Auth {
  static endpoint = appConfigs.API;

  static setUserRolesInLocal(roles) {
    StorageService.set("roles", roles);
  }

  static setUserStoreIdsInLocal(storeIds) {
    StorageService.set("storeIds", storeIds);
  }

  static getUserStoreIdsFromLocal() {
    try {
      return (
        JSON.parse(StorageService.get("roles") || "{}")?.user?.stores || []
      );
    } catch (error) {
      return [];
    }
  }

  static setTokenInLocal(tkn) {
    StorageService.set("sktkn", tkn);
  }

  static getTokenFromLocal() {
    try {
      const e = JSON.parse(localStorage.getItem("sktkn") || "");
      return e;
    } catch (error) {
      return "";
    }
  }

  static setEmpInLocal(data) {
    StorageService.set("emp", data);
  }

  static doLogin(params) {
    return Ajax.request(this.endpoint + "/user/v1/login", "POST", params);
  }

  static clearUserDataFromLocal() {
    this.setEmpInLocal({});
    this.setTokenInLocal("");
    this.setUserRolesInLocal([]);
  }

  static doForgotPwd(params) {
    return Ajax.request(
      this.endpoint + "/user/v1/forgotPassword",
      "POST",
      params
    );
  }

  static getUserDetails(id) {
    return Ajax.request(this.endpoint + "/user/v1/" + id, "GET");
  }

  static getUserBulkDetails(params) {
    return Ajax.request(this.endpoint + "/user/v1", "GET", params);
  }

  static resetPwd(username, params) {
    return Ajax.request(
      this.endpoint + "/user/v1/changePassword/" + username,
      "POST",
      params
    );
  }

  static verifyLoginOtp(params) {
    return Ajax.request(
      this.endpoint + "/user/v1/verifyLoginOtp",
      "POST",
      params
    );
  }

  static async validateJwtToken(token) {
    await Ajax.request(
      this.endpoint + "/validateUser",
      "GET",
      {},
      {
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }

  static logout(id) {
    return Ajax.request(this.endpoint + "/user/v1/logout/" + id, "POST", {
      username: id,
    });
  }

  static getLoggedInEmp() {
    try {
      const e = JSON.parse(localStorage.getItem("emp") || "{}");
      return e;
    } catch (error) {
      return {};
    }
  }

  static getLoggedInEmpId() {
    return this.getLoggedInEmp()?.employee || "";
  }

  static getLoggedInEmpRole = () => {
    try {
      return this.getLoggedInEmp().roleId || "";
    } catch (error) {
      return "";
    }
  };

  static getDefaultWhState() {
    return this.getLoggedInEmp()?.defaultWh?.state || "";
  }

  static getDefaultWhId() {
    return this.getLoggedInEmp()?.defaultWh?._id || "";
  }

  static getLoggedInEmpWh() {
    return this.getLoggedInEmp()?.warehouses || [];
  }

  static getLoggedInEmpWhStates() {
    const wh = this.getLoggedInEmp()?.warehouses || [];
    let t = [];
    wh.forEach((x) => {
      if (t.indexOf(x.state) == -1) {
        t.push(x.state);
      }
    });
    return t;
  }

  static getWhOnState(state) {
    const wh = this.getLoggedInEmp()?.warehouses || [];
    return wh.filter((x) => x.state === state);
  }

  static getDefaultWhIdOnState(state) {
    const wh = this.getLoggedInEmp()?.warehouses || [];
    return (
      (wh.find((x) => x.state == state && x.isDefault) || { _id: "" })._id || ""
    );
  }

  static hasLoggedInToBuilder() {
    return localStorage.getItem("_btkn") ? true : false;
  }

  static isBuilderAdmin() {
    const tkn = localStorage.getItem("_btkn");
    if (!tkn) {
      return false;
    }
    const resp = CommonService.decodeJwt(tkn);
    // if role is there or not
    if (!resp.roles) {
      return false;
    }

    const res = resp.roles.filter(
      (item) => item == "ADMIN" || item == "SUPERADMIN"
    );
    if (!res.length) {
      return false;
    }
    return true;
  }

  static isUserLoggedIn() {
    const emp = this.getLoggedInEmp();
    return emp?.userId ? true : false;
  }

  static getLoggedUserGroups() {
    try {
      const r = JSON.parse(StorageService.get("roles") || "{}");
      let g = r?.user?.groups || [];
      const defaultWh = this.getDefaultWhId();
      const e = this.getLoggedInEmp();
      const a = e?.accessConfig || [];
      const d = a.find((x) => x.whId == defaultWh) || { otherGroups: [] };
      if (d.otherGroups?.length > 0) {
        g = g.concat(d?.otherGroups);
      }
      return g;
    } catch (error) {
      return [];
    }
  }

  static isValidUserRole(roles = []) {
    const groups = this.getLoggedUserGroups();
    let found = false;
    each(roles, (x) => {
      if (groups.indexOf(x) != -1) {
        found = true;
        return false;
      } else {
        return true;
      }
    });
    return found;
  }

  static isSuperAdmin() {
    return this.getLoggedInEmpRole() == "ROLE1";
  }
}
export default Auth;
