import Ajax from "./ajax";
import { appConfigs } from "./config";
class Account {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getFranBalance(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/",
      "GET",
      params
    );
  }

  static getChargeConfigList(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt/charge/list",
      "GET",
      params
    );
  }

  static createChargeConfig(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt/charge/create",
      "POST",
      params
    );
  }

  static updateChargeConfig(id, params) {
    return Ajax.request(
      this.endpoint +
        "/account/" +
        this.apiVersion +
        "/receipt/charge/update/" +
        id,
      "PUT",
      params
    );
  }

  static getHostToHostList(params) {
    return Ajax.request(
      this.endpoint + "/pg/" + this.apiVersion + "/hostToHostShow",
      "GET",
      params
    );
  }

  static getHostToHostListCount(params) {
    return Ajax.request(
      this.endpoint + "/pg/" + this.apiVersion + "/hostToHostShow/count",
      "GET",
      params
    );
  }

  static getUploadNotificationList(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/batch/incentives",
      "GET",
      params
    );
  }

  static updateUploadNotificationList(id, params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/batch/incentives/" + id,
      "PUT",
      params
    );
  }

  static getUploadNotificationCount(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/batch/incentives/count",
      "GET",
      params
    );
  }

  static sendUploadNotificationEmail(params) {
    return Ajax.request(
      this.endpoint +
        "/account/" +
        this.apiVersion +
        "/batch/incentives/emailfile",
      "POST",
      params
    );
  }

  static getCreditDebitList(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/creditDebit",
      "GET",
      params
    );
  }

  static createCreditDebit(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/creditDebit",
      "POST",
      params
    );
  }

  static bulkUpdateCreditDebit(ids, params) {
    return Ajax.request(
      this.endpoint +
        "/account/" +
        this.apiVersion +
        "/creditDebit/bulkUpdate?id=" +
        ids,
      "PUT",
      params
    );
  }

  static getCreditDebitCount(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/creditDebit/count",
      "GET",
      params
    );
  }

  static createReceipt(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt",
      "POST",
      params
    );
  }

  static getReceiptList(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt",
      "GET",
      params
    );
  }

  static getReceiptListCount(params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt/count",
      "GET",
      params
    );
  }

  static getReceiptById(id) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt/" + id,
      "GET"
    );
  }

  static rejectReceipt(id, params) {
    return Ajax.request(
      this.endpoint +
        "/account/" +
        this.apiVersion +
        "/receipt/bulkcancel?id=" +
        id,
      "PUT",
      params
    );
  }

  static sendToApproveReceipt(id, params) {
    return Ajax.request(
      this.endpoint + "/account/" + this.apiVersion + "/receipt/bulk?id=" + id,
      "PUT",
      params
    );
  }

  static approveReceipt(id, params) {
    return Ajax.request(
      this.endpoint +
        "/account/" +
        this.apiVersion +
        "/receipt/bulkReconcilaition?id=" +
        id,
      "PUT",
      params
    );
  }

  static updateHostToHostDetailsById(id, params) {
    return Ajax.request(
      this.endpoint + "/pg/" + this.apiVersion + "/hostToHost/franchise/" + id,
      "PUT",
      params
    );
  }
}
export default Account;
