import { memo } from "react";
import FormInputLayout from "./FormInputLayout";

const CheckboxInput = ({
  label,
  name,
  error,
  layout,
  isMandatory,
  options,
  onChange,
  value,
  note = "",
  cols = 3,
  info = {},
}) => {
  const valParser = (val) => {
    if (val == "true") {
      return true;
    }
    if (val == "false") {
      return false;
    }
    return val;
  };

  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      childAlignMiddle={true}
      note={note}
      info={info}
    >
      <div className="row">
        {options.map((e) => (
          <div key={e.value} className={"col-" + cols}>
            <input
              type="checkbox"
              className="form-check-input"
              value={e.value}
              onChange={(el) => onChange(valParser(el.target.value))}
            />
            <span> &nbsp; {e.label} </span>
          </div>
        ))}
      </div>
    </FormInputLayout>
  );
};

export default memo(CheckboxInput);
