const PageNotFound = () => {
  return (
    <div className="row align-items-center justify-content-center vh-100">
      <div className="col-12 text-center">
        <h1 className="display-1 fw-bold text-primary">404</h1>
        <div className="mt-4">
          <p className="lead">
            The page you are looking for could not be found.
          </p>
          <p>
            Please try searching for the page or returning to the home page.
          </p>
          <a href="#/auth/init" className="btn btn-primary">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
