import classNames from "classnames";
import flatpickr from "flatpickr";
import { useEffect, useRef } from "react";
import "../../node_modules/flatpickr/dist/flatpickr.min.css";

const DatePickerInput = ({
  value,
  inpChange,
  placeholder,
  config = {},
  isSmallInput,
  disabled = false,
  hideClose = false,
}) => {
  const inpRef = useRef(null);
  const pickerInsRef = useRef(null);

  useEffect(() => {
    const init = async () => {
      const o = {
        dateFormat: "d M Y",
        allowInvalidPreload: true,
        // appendTo: appendRef.current,
        // positionElement: appendRef.current,
        // static: true,
        ...(config || {}),
      };

      pickerInsRef.current = flatpickr(inpRef.current, o);
      pickerInsRef.current.config.onClose.push((dt) => {
        inpChange(dt);
      });
    };

    init();

    return () => {
      if (pickerInsRef.current) {
        pickerInsRef.current.destroy();
      }
    };
  }, [config, inpChange]);

  useEffect(() => {
    if (pickerInsRef.current && Array.isArray(value)) {
      if (config.enableTime) {
        pickerInsRef.current.dateFormat = "d M Y, h:i K";
      }

      pickerInsRef.current.setDate(
        value.filter(Boolean).map((x) => {
          return new Date(x);
          // if (config.enableTime) {
          //   return format(new Date(x), "dd MMM yyyy hh:mm a");
          // } else {
          //   return format(new Date(x), "dd MMM yyyy");
          // }
        })
      );
    }
  }, [config.enableTime, value]);

  useEffect(() => {
    if (Object.keys(config).length > 0 && pickerInsRef.current) {
      Object.keys(config).forEach((x) => {
        pickerInsRef.current.set(x, config[x]);
      });
      pickerInsRef.current.redraw();
    }
  }, [config]);

  const clearDate = () => {
    pickerInsRef.current.clear();
    inpChange([]);
  };

  return (
    <div
      className={classNames("input-group", { "input-group-sm": isSmallInput })}
    >
      <input
        type="text"
        ref={inpRef}
        className={classNames(
          "form-control",
          isSmallInput ? "form-control-sm" : ""
        )}
        placeholder={placeholder}
        disabled={disabled}
        tabIndex={0}
      />
      {!hideClose && value?.length ? (
        <button
          type="button"
          className="btn btn-sm btn-light"
          onClick={clearDate}
        >
          <i className="bi bi-x"></i>
        </button>
      ) : null}
    </div>
  );
};

DatePickerInput.defaultProps = {
  config: {},
};

export default DatePickerInput;
