export const sellingMarketingMenus = {
  groups: [],
  icon: "file-text",
  name: "Selling / Marketing",
  priority: 4,
  redirect: {
    path: "",
    type: "",
  },
  subMenus: [
    {
      name: "Promotion Config",
      priority: 0,
      groups: ["ListPromotionalconfig"],
      redirect: {
        path: "/configs/marketing/promotion",
        type: "sameWindow",
      },
    },
    {
      groups: ["ListWidget", "WidgetEdit", "WidgetCreate"],
      icon: "",
      name: "Club APP Widgets",
      priority: 8,
      redirect: {
        path: "/configs/app-config/widget/list",
      },
    },
    {
      name: "RSP Config",
      priority: 1,
      groups: ["ListRSPConfig", "ViewRSPConfig"],
      redirect: {
        path: "/cms/settings/rsp-config",
        type: "sameWindow",
      },
    },
    {
      groups: ["ListKingSlab", "POSViewKingSlab"],
      name: "King Slab Config",
      redirect: {
        path: "/cms/kingslab/pos",
      },
    },
    {
      name: "King Offers",
      groups: ["EditDeal"],
      redirect: {
        path: "/configs/store-discount",
      },
    },
    {
      name: "Purchase Limit",
      groups: ["EditDeal"],
      redirect: {
        path: "/configs/club-deal-purchase-limit",
      },
    },
    {
      groups: ["ListStoreCombo"],
      name: "Store Combo",
      priority: 0,
      redirect: {
        path: "/cms/store-combo-config",
      },
    },

    {
      groups: [],
      name: "Banners",
      priority: 5,
      subMenus: [
        {
          groups: ["ViewBannerLocationMaster"],
          name: "Location Master",
          redirect: {
            path: "/configs/market/dashboard/location-master",
          },
        },
        {
          groups: ["ViewBannerDesignMaster"],
          name: "Banner Master",
          redirect: {
            path: "/configs/market/dashboard/design-master",
          },
        },
        {
          groups: ["AddBanner"],
          name: "Banner Config",
          redirect: {
            path: "/configs/marketing/dashboard",
            params: {
              subType: "dashboard",
              tab: "banner",
            },
          },
        },
      ],
    },

    {
      groups: [],
      name: "Notifications",
      priority: 5,
      subMenus: [
        {
          groups: ["ViewNotificationTemplate"],
          name: "Templates",
          redirect: {
            path: "/utility/notifications/list",
          },
        },
        {
          groups: ["ListWhatsappTemplate"],
          name: "WhatsApp Templates",
          redirect: {
            path: "/utility/whatsapp-template",
          },
        },
        {
          groups: ["ViewBannerDesignMaster"],
          name: "Events",
          redirect: {
            path: "/utility/notifications/events/list",
          },
        },
        {
          groups: ["AddBanner"],
          name: "Logs",
          redirect: {
            path: "/utility/notifications/logs/list",
          },
        },
      ],
    },
  ],
};
