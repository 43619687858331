import { isAfter, isBefore, isEqual, set } from "date-fns";
import Ajax from "./ajax";
import { appConfigs } from "./config";
class Banner {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getBannerList(params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion + "/",
      "GET",
      params
    );
  }

  static getBannerCount(params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getBannerDetailsById(id, params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion + "/" + id,
      "GET",
      params
    );
  }

  static createBanner(params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion + "/",
      "POST",
      params
    );
  }

  static updateBanner(id, params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion + "/" + id,
      "PUT",
      params
    );
  }

  static getBannerLocationCode() {
    let list = [
      {
        label: "Home Page - Main",
        value: "HOME_PAGE_MAIN",
        page: "Home Page",
        location: "Main",
        key: "No Input",
      },
      {
        label: "Home Page - Secondary",
        value: "HOME_PAGE_SECONDARY",
        page: "Home Page",
        location: "Secondary",
        key: "No Input",
      },
      {
        label: "Brand Page - Main",
        value: "BRAND_PAGE_MAIN",
        page: "Brand Page",
        location: "Main",
        key: "Brand",
      },
      {
        label: "Brand Page - Secondary",
        value: "BRAND_PAGE_SECONDARY",
        page: "Brand Page",
        location: "Secondary",
        key: "Brand",
      },
      {
        label: "Category Page - Main",
        value: "CATEGORY_PAGE_MAIN",
        page: "Category Page",
        location: "Main",
        key: "Category",
      },
      {
        label: "Category Page - Secondary",
        value: "CATEGORY_PAGE_SECONDARY",
        page: "Category Page",
        location: "Secondary",
        key: "Category",
      },
      {
        label: "Product Search Page",
        value: "PRODUCT_LIST_PAGE_MAIN",
        page: "Product Search Page",
        location: "Main",
        key: "Product",
      },
      {
        label: "Product Page - Main",
        value: "PRODUCT_PAGE_MAIN",
        page: "Product Page",
        location: "Main",
        key: "Product",
      },
      {
        label: "Product Page - Secondary",
        value: "PRODUCT_PAGE_SECONDARY",
        page: "Product Page",
        location: "Secondary",
        key: "Product",
      },
      {
        label: "Menu Page - Main",
        value: "MENU_PAGE_MAIN",
        page: "Menu Page",
        location: "Main",
        key: "Menu",
      },
      {
        label: "Menu Page - Secondary",
        value: "MENU_PAGE_SECONDARY",
        page: "Menu Page",
        location: "Secondary",
        key: "Menu",
      },
      {
        label: "StoreKing Coin - Main",
        value: "STOREKING_COIN_PAGE_MAIN",
        page: "StoreKing Coin Page",
        location: "Main",
        key: "No Input",
      },
      {
        label: "StoreKing Coin - Secondary",
        value: "STOREKING_COIN_PAGE_SECONDARY",
        page: "StoreKing Coin Page",
        location: "Secondary",
        key: "No Input",
      },
      {
        label: "Cart Page - Min",
        value: "CART_PAGE_MAIN",
        page: "Cart Page",
        location: "Main",
        key: "No Input",
      },
      {
        label: "Cart Page - Secondary",
        value: "CART_PAGE_SECONDARY",
        page: "Cart Page",
        location: "Secondary",
        key: "No Input",
      },
    ];
    return list;
  }

  static getBannerStatusByDate(startDate, endDate) {
    const today = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    const stDate = new Date(startDate);

    startDate = set(new Date(startDate), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    endDate = new Date(endDate);

    if (isBefore(new Date(), stDate)) {
      return {
        label: "Upcoming",
        key: "upcoming",
        color: "text-warning",
        apiKey: "Future",
      };
    } else if (
      (isEqual(today, startDate) || isAfter(today, startDate)) &&
      (isEqual(today, endDate) || isBefore(today, endDate))
    ) {
      return {
        label: "Live",
        key: "active",
        color: "text-success",
        apiKey: "Present",
      };
    } else if (isAfter(today, endDate)) {
      return {
        label: "Expired",
        key: "expired",
        color: "text-danger",
        apiKey: "Past",
      };
    }
  }

  // Banner Design

  static getBannerDesignMasterList(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerdesignmaster/config",
      "GET",
      params
    );
  }

  static getBannerDesignMasterListCount(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerdesignmaster/config/count",
      "GET",
      params
    );
  }

  static createBannerDesignMaster(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerdesignmaster/config",
      "POST",
      params
    );
  }

  static UpdateBannerDesignMaster(id, params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerdesignmaster/config/" +
        id,
      "PUT",
      params
    );
  }

  static getBannerDesignMasterById(id, params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerdesignmaster/config/" +
        id,
      "GET",
      params
    );
  }

  // Banner Location

  static getBannerLocationMasterList(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/config",
      "GET",
      params
    );
  }

  static getBannerLocationMasterListCount(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/config/count",
      "GET",
      params
    );
  }

  static createBannerLocationMaster(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/config",
      "POST",
      params
    );
  }

  static updateBannerLocationMaster(id, params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/config/" +
        id,
      "PUT",
      params
    );
  }

  static getBannerLocationMasterDetailsById(id, params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/config/" +
        id,
      "GET",
      params
    );
  }

  static getBannerLocationMasterSizeList(params) {
    return Ajax.request(
      this.endpoint +
        "/banner/" +
        this.apiVersion +
        "/bannerlocationmaster/getbannersizes",
      "GET",
      params
    );
  }
}

export default Banner;
