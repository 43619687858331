import classNames from "classnames";
import { memo } from "react";
import { AppSlide, AppSwiper } from "../sk-uis";

const styles = {
  main: { backgroundColor: "#f5f5f9" },
  active: {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
};

const Tab = ({
  data,
  callback,
  activeTab,
  slidesPerView = "auto",
  template = 1,
  shadow = false,
  size = "md",
  noBg = false,
}) => {
  return (
    <div
      className={classNames(
        template == 10 || template == 2 ? "pt-2 px-2" : "",
        template == 4 && !noBg ? "bg-white" : "",
        shadow ? "shadow-sm" : ""
      )}
      style={template == 1 ? styles.main : {}}
    >
      <AppSwiper
        config={{
          slidesPerView: slidesPerView,
          spaceBetween: template == 3 ? 10 : 0,
          mousewheel: { forceToAxis: true },
        }}
      >
        {data.map((x) => (
          <AppSlide autoWidth={true} key={x.key}>
            {template == 1 && (
              <div
                onClick={() => {
                  if (activeTab.key != x.key) {
                    callback({ value: x });
                  }
                }}
                className={classNames(
                  `cursor-pointer fs-val-${size} px-4 py-2`,
                  activeTab.key === x.key
                    ? "bg-white text-primary fw-semibold"
                    : "text-black-50s"
                )}
                style={activeTab.key === x.key ? styles.active : null}
              >
                {x.tabName}
              </div>
            )}

            {template == 2 && (
              <div
                onClick={() => {
                  if (activeTab.key != x.key) {
                    callback({ value: x });
                  }
                }}
                className={classNames(
                  `px-3 py-1 mb-2 cursor-pointer fs-val-${size} rounded-pill border me-1`,
                  {
                    "border-blue text-primary": activeTab.key === x.key,
                    "text-muted": activeTab.key != x.key,
                  }
                )}
              >
                {x.tabName}
              </div>
            )}

            {template == 3 ? (
              <div
                onClick={() => {
                  if (activeTab.key != x.key) {
                    callback({ value: x });
                  }
                }}
                className={classNames(
                  `px-4 py-1 cursor-pointer fs-val-${size} rounded-pill`,
                  activeTab.key === x.key
                    ? "bg-white text-primary border-primary border"
                    : "bg-white text-black-50 border"
                )}
              >
                {x.tabName}
              </div>
            ) : null}

            {template == 4 ? (
              <div
                onClick={() => {
                  if (activeTab.key != x.key) {
                    callback({ value: x });
                  }
                }}
                className={classNames(
                  `px-3 py-2 fs-val-${size} cursor-pointer`,
                  activeTab.key === x.key
                    ? "border-bottom border-2 border-primary text-primary fw-semibold"
                    : "text-black-50"
                )}
              >
                {x.tabName}
              </div>
            ) : null}
          </AppSlide>
        ))}
      </AppSwiper>
    </div>
  );
};

export default memo(Tab);
