import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "swiper/swiper-bundle.min.css";
import AppSlide from "../components/slider/AppSlide";
import AppSwiper from "../components/slider/AppSwiper";
import { ImgRender } from "../sk-uis";

const swiperParams = {
  slidesPerView: 6, // Display three slides per view
  spaceBetween: 5, // Add spacing between slides
  // navigation
  navigation: true,
};

const ImgPreviewModal = ({
  show,
  handleClose,
  assets,
  imageIndex = -1,
  isAsset = true,
}) => {
  const [currentImage, setCurrentImage] = useState("");

  const swiperCb = (d) => {
    if (d.activeIndex) {
      setCurrentImage(assets[d.activeIndex]?.id);
    }
  };

  const onImgClick = (d) => {
    setCurrentImage(isAsset ? d.id : d);
  };

  useEffect(() => {
    let img = "";
    if (assets?.length) {
      if (isAsset) {
        img = assets[imageIndex == -1 ? 0 : imageIndex].id;
      } else {
        img = assets[0];
      }
    }
    setCurrentImage(img);
  }, [imageIndex, assets]);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <div className="text-light title fs-val-lg">Image Preview</div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "400px", overflow: "auto" }}>
          <ImgRender assetId={currentImage} isAsset={isAsset} />
        </div>
        <div className="mt-2 pt-2">
          <AppSwiper callback={swiperCb} config={swiperParams}>
            {assets?.map((item, index) => (
              <AppSlide className="p-2" key={index}>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    onImgClick(item);
                  }}
                >
                  <ImgRender
                    assetId={isAsset ? item?.id : item}
                    width="90"
                    isAsset={isAsset}
                  />
                </span>
              </AppSlide>
            ))}
          </AppSwiper>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="">
        
      </Modal.Footer> */}
    </Modal>
  );
};

export default memo(ImgPreviewModal);
