import Ajax from "./ajax";
import Storage from "./storage";
const { appConfigs } = require("./config");

class BuilderAuth {
  static endpoint = appConfigs.BUILDER_API;

  static doLogin(params = {}) {
    return Ajax.request(this.endpoint + "/auth/login", "POST", params);
  }

  static doSignup(params = {}) {
    return Ajax.request(this.endpoint + "/users/signup", "POST", params);
  }

  static isBuilderLoggedIn() {
    const isBuilderLogin = Storage.get("_btkn") ?? "";
    return isBuilderLogin ? true : false;
  }
}

export default BuilderAuth;
