export const accessMenus = {
  groups: [],
  icon: "person-badge",
  name: "Access",
  priority: 5,
  subMenus: [
    {
      editIndex: 3,
      groups: ["ViewRole"],
      name: "Manage Roles",
      priority: 0,
      redirect: {
        path: "/utility/roles",
        type: "sameWindow",
      },
    },
    {
      editIndex: 3,
      groups: ["ViewGroup"],
      name: "Manage Groups",
      priority: 0,
      redirect: {
        path: "/utility/groups",
        type: "sameWindow",
      },
    },
    {
      editIndex: 3,
      groups: ["ManageEmployeeAccess"],
      name: "Employee Access",
      priority: 0,
      redirect: {
        path: "/utility/roles/dashboard",
        type: "sameWindow",
      },
    },
  ],
};
