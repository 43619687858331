import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { memo, useState } from "react";

const TextEditor = ({ onChange, value, height }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <div className="fs-7">Please wait, we are loading editor....</div>
      )}
      <div className={classNames({ "d-none": loading })}>
        <Editor
          onInit={() => {
            setLoading(false);
          }}
          apiKey="c9cn9g8t4uea1w7m1506an2op88ifb4ribi0ynjy25scall6"
          value={value}
          onEditorChange={(v) => onChange({ cnt: v })}
          init={{
            height: height || 500,
            menubar: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "preview",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | image | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>
    </>
  );
};

export default memo(TextEditor);
