import Ajax from "./ajax";
import { appConfigs } from "./config";
class Brand {
  static endpoint = appConfigs.API;

  static create(params) {
    return Ajax.request(this.endpoint + "/brand/v1", "POST", params);
  }

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/brand/v1", "GET", params);
  }

  static getDetailsById(id, params = {}, config = {}) {
    return Ajax.request(
      this.endpoint + "/brand/v1/" + id,
      "GET",
      params,
      config
    );
  }

  static getListBulk(params) {
    return Ajax.request(this.endpoint + "/brand/v1/bulk", "GET", params);
  }

  static update(id, params) {
    return Ajax.request(this.endpoint + "/brand/v1/" + id, "PUT", params);
  }

  static getCount(params = {}) {
    return Ajax.request(this.endpoint + "/brand/v1/count", "GET", params);
  }

  static getProductNotLinkedCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/brand/v1/productnotlinked/count",
      "GET",
      params
    );
  }
}
export default Brand;
