import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Employee {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getEmployeeList(params) {
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion,
      "GET",
      params
    );
  }

  static getEmployeesCount(params) {
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getEmployee(id) {
    return Ajax.request(this.endpoint + "/employee/v1/" + id, "GET");
  }

  static updateEmployee(id, params) {
    return Ajax.request(this.endpoint + "/employee/v1/" + id, "PUT", params);
  }

  static getEmployees(params) {
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion,
      "GET",
      params
    );
  }

  static changeDefaultWh(id) {
    return Ajax.request(
      this.endpoint + "/employee/v1/defaultWareHouse/" + id,
      "PUT",
      {}
    );
  }

  static getRbacGroup(name) {
    return Ajax.request(
      this.endpoint +
        "/employee/" +
        this.apiVersion +
        "/employee/rbac/group/" +
        name,
      "GET"
    );
  }

  static createEmpRbac(params) {
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/rbac/employee/master",
      "POST",
      params
    );
  }

  static getEmpRbacGroup(id, params = {}) {
    let p = merge({}, params, {
      filter: {
        empId: id,
      },
    });
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/rbac/employee/master",
      "GET",
      p
    );
  }

  static getEmpRbacAuditLogs(id, params = {}) {
    const p = merge({}, params, {
      filter: {
        empId: id,
      },
    });
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/employee/audit/log",
      "GET",
      p
    );
  }

  static updateRbacGroup(employeeId, params) {
    return Ajax.request(
      this.endpoint +
        "/employee/" +
        this.apiVersion +
        "/rbac/group/" +
        employeeId,
      "PUT",
      params
    );
  }

  static getRbacFeatures(params) {
    const p = merge({}, params, {
      groupType: "feature",
    });
    return Ajax.request(
      this.endpoint + "/employee/" + this.apiVersion + "/rbac/group",
      "GET",
      p
    );
  }
}

export default Employee;
