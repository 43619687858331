import Ajax from "./ajax";
import { appConfigs } from "./config";

class Asset {
  static endpoint = appConfigs.API;

  static upload(params, method = "POST") {
    return Ajax.request(this.endpoint + "/asset/v1/upload", method, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default Asset;
