import classNames from "classnames";
import FormInputLayout from "./FormInputLayout";

const SelectInput = ({
  label,
  register,
  name,
  error,
  layout,
  isMandatory,
  options,
  defaultValue,
  callback,
  note = "",
  info = {},
  hasGrouping = false,
  disabled = false,
  gap,
  size,
}) => {
  const valParser = (val) => {
    if (val == "true") {
      return true;
    }
    if (val == "false") {
      return false;
    }
    return val;
  };

  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      note={note}
      info={info}
      gap={gap}
    >
      <select
        {...register(name, {
          onChange: (e) =>
            callback ? callback(valParser(e.target.value)) : null,
        })}
        className={classNames(
          "form-select",
          size == "sm" ? "form-select-sm" : ""
        )}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {hasGrouping ? (
          <>
            {(options || []).map((m, i) => (
              <optgroup label={m.label} key={i + ":grp"}>
                {(m.options || []).map((e, i) => (
                  <option key={e.value + ":" + i} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </optgroup>
            ))}
          </>
        ) : (
          <>
            {(options || []).map((e, i) => (
              <option key={e.value + ":" + i} value={e.value}>
                {e.label}
              </option>
            ))}
          </>
        )}
      </select>
    </FormInputLayout>
  );
};

export default SelectInput;
