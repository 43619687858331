import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ImagePreviewModal = ({ show, handleClose, images }) => {
  const [preview, setPreview] = useState({});

  useEffect(() => {
    if (Array.isArray(images) && images.length > 0) {
      setPreview({
        ...images[0],
        src: URL.createObjectURL(images[0].data),
      });
    }
  }, [images, show]);

  const onHideCb = useCallback(() => {
    URL.revokeObjectURL(preview.src);
    handleClose();
  }, [preview.src, handleClose]);

  return (
    <>
      <Modal show={show} onHide={onHideCb} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div
              style={{
                height: "600px",
                width: "100%",
                display: "inline-block",
                position: "relative",
              }}
            >
              {preview?.src ? (
                <img
                  src={preview?.src}
                  className="cursor-pointer"
                  alt="preview"
                  onLoad={() => {
                    URL.revokeObjectURL(preview.src);
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="imgs"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ImagePreviewModal);
