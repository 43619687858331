import classNames from "classnames";
import { memo } from "react";

const success = [
  "live",
  "open",
  "started",
  "active",
  "yes",
  "free",
  "publish",
  "paid",
  "delivered",
  "shipped",
  "created",
  "closed",
  "verified",
  "pending bill matching",
  "confirmed",
  "unlocked",
  "completed",
  "success",
  "approved",
  "allowed",
];

const danger = [
  "expired",
  "ended",
  "inactive",
  "no",
  "stopped",
  "seller",
  "locked",
  "unpaid",
  "error",
  "failed",
  "no answer",
  "rejected",
  "proofpending",
  "failed",
  "invalid",
  "restricted",
];

const warning = [
  "reserve got over",
  "pending",
  "draft",
  "in progress",
  "partially inwarded",
  "cancelled",
  "Pending Stock Inward",
  "reverted",
  "partially shipped",
  "partially completed",
  "unpublish",
  "pendingpproval",
  "partially processed",
  "processing",
  "in progress",
  "paymenet pending",
  "approval pending",
  "payment pending",
  "accounted",
  "suspicious",
  "suspense account",
  "upcoming",
];

const HighlightText = ({
  status,
  isBadge = false,
  size = "sm",
  isInline = false,
  template = 1,
  type,
  children,
  className,
  isSoft = false,
}) => {
  if (template === 2) {
    return (
      <div
        className={classNames(
          `badge`,
          `fs-val-${size}`,
          {
            ["bg-" + type]: !isSoft,
            "d-inline-block": isInline,
            ["not-hover bg-soft-" + type]: isSoft,
          },
          className || ""
        )}
      >
        {children}
      </div>
    );
  }

  let s = "";
  if (status) {
    s = status.toLowerCase();
  }
  let statusClass = "";
  if (success.indexOf(s) != -1) {
    statusClass = "success";
  } else if (danger.indexOf(s) != -1) {
    statusClass = "danger";
  } else if (warning.indexOf(s) != -1) {
    statusClass = "warning";
  } else {
    statusClass = "primary";
  }
  return (
    <div
      className={classNames(statusClass, `fs-val-${size}`, {
        ["badge" + " bg-" + (isSoft ? "soft-" : "") + statusClass]: isBadge,
        ["text-" + statusClass]: !isBadge,
        "d-inline-block": isInline,
      })}
    >
      {status}
    </div>
  );
};

export default memo(HighlightText);
