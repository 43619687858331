import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderMenu {
  static endpoint = appConfigs.BUILDER_API;

  static create(data) {
    return Ajax.request(this.endpoint + "/builder/menus", "POST", data);
  }

  static remove(id) {
    return Ajax.request(this.endpoint + "/builder/menus/" + id, "DELETE");
  }

  static update(id, data) {
    return Ajax.request(this.endpoint + "/builder/menus/" + id, "PUT", data);
  }

  static getList(params = {}) {
    return Ajax.request(this.endpoint + "/builder/menus", "GET", params);
  }

  static getDetail(id) {
    return Ajax.request(this.endpoint + "/builder/menus/" + id, "GET");
  }

  static getDefaultMenus() {
    return Ajax.request(this.endpoint + "/builder/menus/defaultMenus", "GET");
  }
}
export default BuilderMenu;
