import queryString from "query-string";

class Nav {
  static prepareUrl = (path, params) => {
    const parsed = queryString.parseUrl(path);
    let p = parsed.url;
    let q = parsed.query || {};

    if (params && Object.keys(params).length > 0) {
      p += "?" + queryString.stringify({ ...q, ...params });
    }

    return p;
  };

  static to(navigateObj, path, params) {
    navigateObj(this.prepareUrl(path, params));
  }

  static replace(navigateObj, path, params) {
    navigateObj(this.prepareUrl(path, params), { replace: true });
  }

  static getQueryParams(useSearchParamsObj) {
    return Object.fromEntries([...useSearchParamsObj]);
  }

  static reload() {
    location.reload();
  }

  static goBack() {
    history.back();
  }

  static openInNewTab = (path, params) => {
    let p = queryString.stringify(params);
    if (p) {
      p = "?" + p;
    }
    window.open("#" + path + p, "_blank");
  };

  static navToBrand(navigateObj, params) {
    this.to(navigateObj, "/cms/brand/view", params);
  }
  static navToCategory(navigateObj, params) {
    this.to(navigateObj, "/cms/category/view", params);
  }
  static navToDeal(navigateObj, params) {
    this.to(navigateObj, "/cms/deal/view", params);
  }

  static viewBrand(params) {
    this.openInNewTab("/cms/brand/view", params);
  }

  static viewCategory(params) {
    this.openInNewTab("/cms/category/view", params);
  }

  static viewDeal(params) {
    this.openInNewTab("/cms/deal/view", params);
  }

  static navToVendor(params) {
    this.openInNewTab("/vendor/view", params);
  }
}

export default Nav;
