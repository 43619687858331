import DatePickerInput from "../DatePickerInput";
import DatePickerRangeInput from "../DatePickerRangeInput";
import FormInputLayout from "./FormInputLayout";

const DateInput = ({
  config = {},
  label,
  error,
  placeholder,
  layout,
  isMandatory,
  value,
  callback,
  note = "",
  info = {},
  disabled = false,
  size = "",
  gap,
  hideClose = false,
  template = 1,
}) => {
  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      note={note}
      info={info}
      gap={gap}
    >
      {template == 2 ? (
        <DatePickerRangeInput
          value={value}
          callback={callback}
          isSmallInput={size == "sm" ? true : false}
          hideClose={!value?.length || hideClose}
          disabled={disabled}
          minDate={config?.minDate}
          maxDate={config?.maxDate}
        />
      ) : (
        <DatePickerInput
          value={value}
          inpChange={callback}
          placeholder={placeholder}
          config={config}
          isSmallInput={size == "sm" ? true : false}
          disabled={disabled}
          hideClose={hideClose}
        />
      )}
    </FormInputLayout>
  );
};

export default DateInput;
