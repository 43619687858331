import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { isBoolean, isNull } from "lodash";
import { memo } from "react";
import PaginationSummary from "../../PaginationSummary";
import TableSkeletonLoader from "../../TableSkeletonLoader";

const headers = [
  { label: "Sl No", width: "8%" },
  // { label: " Type" },
  { label: "Field" },
  { label: "Old Value" },
  { label: "New Value" },
  { label: "Message" },
  { label: "Created At", key: "createdAt", enableSort: true },
  { label: "Created By" },
];

const Table = ({
  data,
  sort = {},
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  return (
    <>
      {/* PAGINATION SUMMARY */}
      <div>
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} noBg={true} />

        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={paginationConfig.rowsPerPage}
              cols={headers.length}
            />
          )}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.length > 0 &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                {/* <td>{x.entityType}</td> */}
                <td>{x.field || "N/A"}</td>
                <td>
                  {isNull(x.oldValue) ? "N/A" : x.oldValue.toString() || "N/A"}
                </td>
                <td>
                  {isBoolean(x.newValue)
                    ? x.newValue.toString()
                    : x.newValue || "N/A"}
                </td>
                <td>{x.message || "N/A"}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <div>{x._createdBy?.name} </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
