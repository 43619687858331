import { AssetService } from "@sk/services";
import React, { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Loader from "../services/loader";
import { Toaster } from "../services/toaster";

// Styles For Upload Image
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function FileUpload({
  callback,
  maxFiles = 1,
  maxSize = 10,
  acceptedFormats = [".png", ".jpeg", ".jpg"],
  fileKey = "file",
  accept = {
    "image/*": acceptedFormats,
  },
  params = {},
  template = 1,
  allowApiUpload = true,
  title = "",
  allowMultiple = false,
}) {
  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0] || null;
      let allFiles = acceptedFiles;
      // check has file object
      if (!file) {
        Toaster.error("Failed to fetch the file");
        return;
      }

      if (!allowApiUpload) {
        callback({
          asset: file,
          resp: {},
        });
        return;
      }

      let result = [];

      const upload = async (index) => {
        let file = allFiles[index];
        // prepare file payload
        let p = {
          [fileKey]: file,
        };

        // upload file using api

        const r = await AssetService.upload({ ...p, ...params });

        // show error if status code not 200
        if (r.statusCode == 200) {
          // Toaster.success("File uploaded successfully");
          result.push({
            asset: r?.resp?._id || "",
            resp: r?.resp || {},
          });
          // trigger a callback
          // callback({
          //   asset: r?.resp?._id || "",
          //   resp: r?.resp || {},
          // });
        } else {
          // Toaster.error("Failed to upload the file, please try again");
          result.push({
            asset: "",
            resp: r?.resp || {},
          });
        }

        let next = index + 1;
        if (next < allFiles.length) {
          upload(next);
        } else {
          Loader.hideLoader();
          let succFiles = result.filter((e) => {
            return e.asset;
          });

          let errFiles = result.filter((e) => {
            return !e.asset;
          });

          if (succFiles.length != acceptedFiles.length) {
            Toaster.success(
              "Out Of " +
                acceptedFiles.length +
                "Files " +
                succFiles.length +
                "Files is Uploaded Succefully"
            );
          }

          if (errFiles.length == acceptedFiles.length) {
            Toaster.error("Failed to upload the file, please try again");
          }

          if (result)
            callback({
              asset: result[0]?.asset || "",
              resp: result[0]?.resp || {},
              result: result,
            });
        }
      };
      Loader.showLoader();
      upload(0);
    },
    [allowApiUpload, callback, fileKey, params]
  );

  const onDropRejected = useCallback(async (err) => {
    const file = err[0] || null;
    if (file?.errors.length && file.errors[0]?.code == "file-too-large") {
      Toaster.error("File is larger than " + maxSize + " MB");
    }
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDropAccepted,
      onDropRejected,
      maxSize: maxSize * 1000000, // file max size will be in bytes
      maxFiles: maxFiles,
      multiple: allowMultiple,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      {template == 1 ? (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className="my-3">
            Drag & drop some files here, or click to select files
          </div>
          <div className="fs-val-xs mt-1 text-muted">
            Supported format :
            {acceptedFormats.map((x, i) => (
              <React.Fragment key={x}>
                {x} {i < acceptedFormats.length - 1 ? "   , " : ""}
              </React.Fragment>
            ))}
            . Max file size is {maxSize} MB
          </div>
        </div>
      ) : null}

      {template == 2 ? (
        <div {...getRootProps()}>
          <button className="btn btn-primary" type="button">
            Upload
          </button>
          <div className="fs-val-xs mt-1 text-muted">
            Supported format :{" "}
            {acceptedFormats.map((x, i) => (
              <React.Fragment key={x}>
                {x} {i < acceptedFormats.length - 1 ? "   , " : ""}
              </React.Fragment>
            ))}
            . Max file size is {maxSize} MB
          </div>
          <input {...getInputProps()} />
        </div>
      ) : null}

      {template == 3 ? (
        <div {...getRootProps()}>
          <i className="bi bi-upload text-primary fs-val-lg cursor-pointer  fw-bolder"></i>
          <span className="text-primary fs-val-sm ms-2"> {title} </span>
          <input {...getInputProps()} />
        </div>
      ) : null}

      {template == 4 ? (
        <button
          className="btn btn-outline-primary cursor-pointer"
          {...getRootProps()}
        >
          <i className="bi bi-images text-primary fs-val-lg fw-bolder"></i>
          <input {...getInputProps()} />
        </button>
      ) : null}
    </>
  );
}

export default memo(FileUpload);
