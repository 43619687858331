import { memo, useState } from "react";
import HowToUseModal from "../modals/HowToUseModal";

const HowToUseHandler = ({ topic }) => {
  const [show, setShow] = useState(false);

  const cb = (e) => {
    if (e.action == "close") {
      setShow(false);
    }
  };

  return (
    <>
      <span
        onClick={() => setShow(true)}
        className="bi bi-question-circle fs-6 cursor-pointer"
      ></span>
      {show ? <HowToUseModal topic={topic} callback={cb} /> : null}
    </>
  );
};

export default memo(HowToUseHandler);
