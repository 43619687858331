import { accessMenus } from "./access";
import { accountMenus } from "./account";
import { contentMenus } from "./content";
import { dashboardMenus } from "./dashboard";
import { projectManagementMenus } from "./projectManagment";
import { sellingMarketingMenus } from "./sellingMarketing";
import { sourcingMenus } from "./sourcing";

export const menuList = [
  dashboardMenus,
  contentMenus,
  sourcingMenus,
  sellingMarketingMenus,
  accessMenus,
  accountMenus,
  projectManagementMenus,
];

export const appMenus = {
  menus: menuList,
};

export default menuList;
