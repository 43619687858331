import { memo } from "react";
import FormInputLayout from "./FormInputLayout";

const ColorInput = ({
  label,
  name,
  error,
  layout,
  isMandatory,
  register,
  callback,
  info = {},
}) => {
  return (
    <>
      <FormInputLayout
        label={label}
        error={error}
        isMandatory={isMandatory}
        layout={layout}
        name={name}
        info={info}
      >
        <input type="color" {...register(name, { onChange: callback })} />
      </FormInputLayout>
    </>
  );
};

export default memo(ColorInput);
