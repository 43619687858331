import Ajax from "./ajax";
import { appConfigs } from "./config";

class BuilderProject {
  static endpoint = appConfigs.BUILDER_API;

  static create(data) {
    return Ajax.request(this.endpoint + "/builder/projects", "POST", data);
  }

  static getList() {
    return Ajax.request(this.endpoint + "/builder/projects", "GET");
  }

  static getDetail(id) {
    return Ajax.request(this.endpoint + "/builder/projects/" + id, "GET");
  }

  static update(id, data) {
    return Ajax.request(this.endpoint + "/builder/projects/" + id, "PUT", data);
  }
}
export default BuilderProject;
