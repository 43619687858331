class Storage {
  static set(key, value) {
    let v;
    if (typeof value == "object") {
      v = JSON.stringify(value);
    } else {
      v = value;
    }
    window.localStorage.setItem(key, v);
  }

  static get(key) {
    if (window.localStorage[key]) {
      return window.localStorage.getItem(key);
    } else {
      return null;
    }
  }

  static clear() {
    window.localStorage.clear();
  }

  static remove(key) {
    if (window.localStorage[key]) {
      delete window.localStorage[key];
    }
  }
}

export default Storage;
