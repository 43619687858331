import { memo } from "react";
import SummaryCardOne from "./SummaryCardOne";
import SummaryCardTwo from "./SummaryCardTwo";
import SummaryCardThree from "./SummaryCardThree";

const SummaryCard = ({
  template = 1,
  title,
  value,
  isValueAmt,
  amtDecimalPlace = 2,
  loading = false,
  img = "",
  valueColor = "",
  titleColor = "",
  onSummaryCardClick,
  icon,
  isClickable = false,
  data = {},
  info = { header: "", content: "" },
  cardHeight,
  noValParse = false,
  noShadow = false,
}) => {
  return (
    <div>
      {/* Template 1 */}
      {template === 1 ? (
        <SummaryCardOne
          icon={icon}
          title={title}
          loading={loading}
          value={value}
          valueColor={valueColor}
          amtDecimalPlace={amtDecimalPlace}
          onSummaryCardClick={onSummaryCardClick}
          isClickable={isClickable}
          data={data}
          info={info}
          isValueAmt={isValueAmt}
          noValParse={noValParse}
          noShadow={noShadow}
        />
      ) : null}
      {/* Template 2 */}
      {template === 2 ? (
        <SummaryCardTwo
          img={img}
          title={title}
          valueColor={valueColor}
          titleColor={titleColor}
          isValueAmt={isValueAmt}
          amtDecimalPlace={amtDecimalPlace}
          loading={loading}
          value={value}
          isClickable={isClickable}
          onSummaryCardClick={onSummaryCardClick}
          data={data}
          info={info}
          cardHeight={cardHeight}
        />
      ) : null}

      {/* Template 3  */}

      {template == 3 && (
        <SummaryCardThree
          title={title}
          valueColor={valueColor}
          titleColor={titleColor}
          isValueAmt={isValueAmt}
          amtDecimalPlace={amtDecimalPlace}
          loading={loading}
          value={value}
          isClickable={isClickable}
          onSummaryCardClick={onSummaryCardClick}
          data={data}
          info={info}
          noValParse={noValParse}
        />
      )}
    </div>
  );
};

export default memo(SummaryCard);
