import React from "react";
import { AuthService } from "@sk/services";

const Rbac = ({ roles, children }) => {
  if (!AuthService.isValidUserRole(roles)) {
    return <></>;
  }

  return children;
};

export default Rbac;
