import classNames from "classnames";
import { memo } from "react";

const AppTitle = ({ title, type, className = "" }) => {
  return (
    <div
      className={classNames(className, {
        "header-title": type === "header",
        "app-sub-title": type === "sub-title",
        "card-title": type === "card-title", // card-title class is from theme
        h5: !type,
      })}
    >
      {title}
    </div>
  );
};

export default memo(AppTitle);
