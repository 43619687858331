import classNames from "classnames";
import Pagination from "./Pagination";
import PaginationSummary from "./PaginationSummary";

const PaginationBlock = ({
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  showSummary = false,
  className = "",
  size = "md",
}) => {
  if (
    paginationConfig.totalRecords > 0 &&
    paginationConfig.rowsPerPage > paginationConfig.totalRecords
  ) {
    return null;
  }

  return (
    <div className={classNames("row g-0 align-items-center", className)}>
      {showSummary ? (
        <div className="col">
          <PaginationSummary
            loadingTotalRecords={loadingTotalRecords}
            paginationConfig={paginationConfig}
          />
        </div>
      ) : null}
      <div className="col-auto ms-auto">
        <Pagination
          totalRecords={paginationConfig.totalRecords}
          rowsPerPage={paginationConfig.rowsPerPage}
          callback={paginationCb}
          activePage={paginationConfig.activePage}
          size={size}
        />
      </div>
    </div>
  );
};

export default PaginationBlock;
