export const accountMenus = {
  groups: [],
  icon: "cash",
  name: "Account",
  priority: 9,
  subMenus: [
    {
      groups: ["DepositReconcile"],
      name: "POS Cash Deposits",
      redirect: {
        path: "/account/cash-deposit-reconcile",
      },
    },
    {
      groups: ["ViewZohoDetails"],
      name: "Cron Jobs",
      priority: 0,
      redirect: {
        path: "/utility/cron-jobs",
      },
    },
  ],
};
