import { CommonService } from "@sk/services";
import { memo } from "react";

const Amount = ({ value, decimalPlace = 0, hideSymbol }) => {
  Intl.NumberFormat("en-IN", { type: "" });
  return (
    <>
      {!hideSymbol && <span style={{ fontFamily: "sans-serif" }}>&#8377;</span>}
      <span>
        {Number(
          CommonService.roundedByDecimalPlace(value, decimalPlace)
        ).toLocaleString("en-IN")}{" "}
      </span>
    </>
  );
};

export default memo(Amount);
