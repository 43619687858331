import React, { memo } from "react";
import ImgRender from "../ImgRender";
import AppSlide from "./AppSlide";
import AppSwiper from "./AppSwiper";

const ImgSlider = ({
  assets = [],
  onRemove,
  removeType = "text",
  swiperConfig = {
    slidesPerView: 5,
    spaceBetween: 20,
  },
  width,
  height,
}) => {
  return (
    <>
      {assets.length > 0 ? (
        <AppSwiper config={swiperConfig}>
          {assets.map((x, k) => (
            <AppSlide key={x.asset}>
              <div className="border rounded p-2">
                {removeType == "icon" ? (
                  <button
                    className="btn text-danger btn-link btn-lg position-absolute top-0 end-0 p-1"
                    onClick={() => onRemove(x, k)}
                  >
                    <span className="bi bi-x-circle"></span>
                  </button>
                ) : null}

                <ImgRender assetId={x.asset} width={width} height={height} />
                {removeType == "text" ? (
                  <div className="text-center text-danger fs-7">
                    <button
                      className="btn btn-sm btn-link text-danger"
                      onClick={() => onRemove(x, k)}
                    >
                      <span className="bi bi-trash"></span> Remove
                    </button>
                  </div>
                ) : null}
              </div>
            </AppSlide>
          ))}
        </AppSwiper>
      ) : null}
    </>
  );
};

export default memo(ImgSlider);
