import { AuthService, BuilderSidemenuService, NavService } from "@sk/services";
import { Alert, AssignedWarehouseModal, Loader, Toaster } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import SideMenuService from "../services/sidemenu";
import helpImage from "../images/help.jpg";

const AppHeader = ({ router }) => {
  const [user, setUser] = useState({});

  const [showSideMenu, setShowSideMenu] = useState(false);

  const [defaultMenus, setDefaultMenus] = useState([]);

  const [showWhModal, setShowWhModal] = useState(false);

  const [hasBuilderAccess, setHasBuilderAccess] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    setUser(AuthService.getLoggedInEmp());
    loadDefaultMenus();
    checkHasBuilderAccess();
  }, []);

  const checkHasBuilderAccess = async () => {
    const r = AuthService.getLoggedInEmp();

    if (!r.email) {
      return;
    }

    // // Check Access
    // const cr = await BuilderWorkflowService.checkBuilderAccess({
    //   email: r?.email,
    // });
    // if (cr.statusCode !== 200) {
    //   return;
    // }

    // if (cr.resp?.data?.status === "NO_ACCESS") {
    //   return;
    // }
    // setHasBuilderAccess(true);
  };

  const onHideCb = useCallback(() => {
    setShowSideMenu(false);
  }, []);

  const whModalCb = useCallback((e) => {
    setShowWhModal(false);
    if (e.action === "changed") {
      // router.reload();
      location.reload();
    }
  }, []);

  const openSettingMenu = () => {
    const hasAccess = AuthService.hasLoggedInToBuilder({});
    hasAccess
      ? setShowSideMenu((v) => !v)
      : NavService.to(router, "/builder/config/auth/login");
  };

  const closeSettingMenu = () => {
    setShowSideMenu((v) => false);
  };

  const loadDefaultMenus = async () => {
    const r = await BuilderSidemenuService.getDefaultMenus();
    const m = r?.resp?.data || [];
    if (Array.isArray(m)) {
      setDefaultMenus(m);
    } else {
      setDefaultMenus([]);
    }
  };

  const logout = async () => {
    const c = await Alert.confirm({
      title: "Please Confirm",
      text: "Do you want to logout?",
      okText: "Yes",
      cancelText: "No",
    });
    if (c.isConfirmed) {
      Loader.showPrgsLoader();
      const e = AuthService.getLoggedInEmp();
      const d = await AuthService.logout(e.username, {});
      const r = d.resp || {};

      Loader.hidePrgsLoader();

      if (d.statusCode == 200) {
        AuthService.clearUserDataFromLocal();
        NavService.replace(router, "/auth/login");
      } else {
        Toaster.error(r.message || "Failed to logout, please try again");
      }
    }
  };

  const gotoHome = () => {
    NavService.to(router, "/");
  };

  const onMenuClick = (index, subIndex, inNewTab) => {
    const rd = defaultMenus[index].subMenus[subIndex].redirect || {};
    const path = rd.path || "";
    SideMenuService.nav(path, inNewTab ? "newWindow" : "", router);
    closeSettingMenu();
  };

  // for toggling the sub-sidemenu
  const menuToggle = () => {
    SideMenuService.toggleMenu();
  };

  const goToProfile = () => {
    NavService.to(router, "/auth/user/profile");
  };

  const handleShowImageModal = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  return (
    <>
      <div className="topbar">
        <Navbar expand="lg" className="navbar-custom shadow-sm">
          <Container fluid>
            <Nav>
              <div
                className="ms-2 fs-val-normal fw-semibold"
                id="appHeader"
              ></div>
            </Nav>
            <Nav className="ms-auto my-2 my-lg-0 align-items-center">
              <Nav.Link onClick={() => setShowWhModal(true)}>
                <div className="d-flex align-items-center">
                  <span className="rounded-circle thumb-sm">
                    <i className="bi bi-house-door fs-3"></i>
                  </span>
                  <div>
                    <span className="d-none d-md-block fw-semibold font-12 text-dark">
                      {user?.defaultWh?.name}
                      <i className="bi bi-chevron-down ms-2" />
                    </span>
                  </div>
                </div>
              </Nav.Link>
              <NavDropdown
                title={
                  <div className="d-flex align-items-center">
                    <span className="rounded-circle thumb-sm">
                      <i className="bi bi-person fs-3"></i>
                    </span>
                    <div>
                      {/* <small className="d-none d-md-block font-11">Admin</small> */}
                      <span className="d-none d-md-block fw-semibold font-12 text-dark">
                        {user.employeeName}
                        <i className="bi bi-chevron-down ms-2" />
                      </span>
                    </div>
                  </div>
                }
              >
                <NavDropdown.Item onClick={goToProfile}>
                  My Profile
                </NavDropdown.Item>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item></NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link className="text-dark" onClick={handleShowImageModal}>
                <span className="bi bi-question-circle"></span> Help
              </Nav.Link>
              <Nav.Link className="text-dark" onClick={logout}>
                <span className="bi bi-power"></span> Logout
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>

      <AssignedWarehouseModal show={showWhModal} callback={whModalCb} />
      <Offcanvas show={showSideMenu} placement="end" onHide={onHideCb}>
        <Offcanvas.Header className="border-b">
          <h5 className="mb-0">Configuration</h5>
          <button
            className="btn-close align-self-center"
            onClick={closeSettingMenu}
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav">
            {defaultMenus.map((e, k) => (
              <li key={k} className="nav-item">
                <a className="nav-link">{e.name}</a>
                <ul className="nav flex-column">
                  {e.subMenus.map((e1, k1) => (
                    <li key={k1} className="nav-item">
                      <a className="nav-link cursor-pointer">
                        <span
                          onClick={() => onMenuClick(k, k1, false)}
                          className="me-2"
                        >
                          {e1.name}
                        </span>
                        <i
                          onClick={() => onMenuClick(k, k1, true)}
                          className="bi bi-arrow-up-right-square"
                        ></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={showImageModal}
        onHide={handleCloseImageModal}
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title className="mb-0 text-dark">Help / Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={helpImage} alt="Description" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(AppHeader);
