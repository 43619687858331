import { register } from "swiper/element/bundle";

register();

const AppSlide = ({ children, autoWidth = false, autoHeight = false }) => {
  const style = autoWidth
    ? { width: "auto" }
    : autoHeight
    ? { height: "auto" }
    : {};

  return <swiper-slide style={style}>{children}</swiper-slide>;
};

export default AppSlide;
