import Ajax from "./ajax";
import { appConfigs } from "./config";

class User {
  static endpoint = appConfigs.API;

  static getList(params) {
    return Ajax.request(this.endpoint + "/user/v1", "GET", params);
  }

  static getGroupsList(params) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/groups", "GET", params);
  }

  static getGroupsListCount(params) {
    return Ajax.request(
      this.endpoint + "/user/v1/rbac/groups/count",
      "GET",
      params
    );
  }

  static createAccessGroup(params) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/groups", "POST", params);
  }

  static updateAccessGroup(id, params) {
    return Ajax.request(
      this.endpoint + "/user/v1/rbac/groups/" + id,
      "PUT",
      params
    );
  }

  static getAccessGroupDetails(id) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/groups/" + id, "GET");
  }

  static deleteAccessGroup(id) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/groups/" + id, "DELETE");
  }

  static getRulesList(params) {
    return Ajax.request(this.endpoint + "/user/v1/rbac/rules/", "GET", params);
  }

  static updateRuleById(id, params) {
    return Ajax.request(
      this.endpoint + "/user/v1/rbac/rules/" + id,
      "PUT",
      params
    );
  }
}

export default User;
