import React, { memo } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ToasterContainer = () => {
  return <ToastContainer />;
};

export default memo(ToasterContainer);
