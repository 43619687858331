import { merge } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import { set } from "date-fns";
import { CommonService } from "../sk-services";

class Customer {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getList(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion,
      "GET",
      params
    );
  }

  static getDetail(id, params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/" + id,
      "GET",
      params
    );
  }

  static updateCustomer(id, params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/" + id,
      "PUT",
      params
    );
  }

  static getSmartCoinRewardList(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/config",
      "GET",
      params
    );
  }

  static getSmartCoinRewardById(id, params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/config/" +
        id,
      "GET",
      params
    );
  }

  static updateSmartCoinReward(id, params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/config/" +
        id,
      "PUT",
      params
    );
  }

  static getSmartCoinRewardCount(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/config/count",
      "GET",
      params
    );
  }

  static createSmartCoinReward(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/config",
      "POST",
      params
    );
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/count",
      "GET",
      params
    );
  }

  static getStoreOfferRequestedList(id, params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/storeoffers/requests/" +
        id,
      "GET",
      params
    );
  }

  static getCustomerCoinDashboardList(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/fetch/customerCoinDashBoard",
      "GET",
      params
    );
  }

  static getCustomerCoinReferralList(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/fetch/fetchReferalCouponDetails",
      "GET",
      params
    );
  }

  static getReferralCoinsDataList(params) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/referalCoinsData",
      "GET",
      params
    );
  }

  static getClubLinkedMembers(id, params) {
    const p = merge({}, params, {
      queryType: "ChildsList",
      filter: { customerId: id },
    });
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/analytics",
      "GET",
      p
    );
  }

  static async getClubLinkedMembersCount(id, params) {
    const p = merge({}, params, {
      outputType: "count",
      queryType: "ChildsList",
      filter: { customerId: id },
    });
    const r = await this.getClubLinkedMembers(id, p);
    return {
      count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].total : 0,
    };
  }

  static async getClubEarnedCoins(id, params) {
    const p = merge({}, params, {
      groupbycond: "status",
      filter: { customerId: id },
    });
    const r = await Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/shareReferalRewardRule/analytics",
      "GET",
      p
    );
    const d = Array.isArray(r.resp) ? r.resp : [];
    const c = d.find((e) => e._id == "Earned") || {};

    return {
      count: c?.parentCoins || 0,
    };
  }

  static getCustomerInsights(params, options) {
    return Ajax.request(
      this.endpoint + "/customer/" + this.apiVersion + "/customer/insights",
      "GET",
      params,
      options
    );
  }

  static getCustomerReqStatusLabel(status, tranStatus, type) {
    const statusObj = {
      Created: "Approval Pending",
      Approved: "Yet to Process",
      Rejected: "Rejected",
      Validate: "Payment Pending",
      Cancelled: "Cancelled",
      Delivered: "Delivered",
      Shipped: "Shipped",
      Closed: "Success",
      Invoiced: "Ready to Ship",
    };
    let s = statusObj[status] || status;

    if (status == "Closed" && tranStatus == "Failed") {
      s = "Failed";
    }

    if (status == "Closed" && tranStatus == "Cancelled") {
      s = "Cancelled";
    }

    if (
      (status == "Closed" && tranStatus == "Success") ||
      tranStatus == "Successful" ||
      tranStatus == "Successfull"
    ) {
      s = "Success";
    }

    if (type == "addBeneficiary" && status == "Approved") {
      s = "Beneficiary Create Pending";
    }

    return s;
  }

  static getCustomerReqStatusBg(status) {
    const s = {
      "Approval Pending": "warning",
      "Yet to Process": "secondary",
      "Yet to Ship": "info",
      "Ready to Ship": "info",
      Rejected: "danger",
      Success: "success",
      Failed: "danger",
      Cancelled: "danger",
      "Payment Pending": "warning",
      Delivered: "success",
      Shipped: "info",
      Expired: "danger",
      Returned: "dark",
    };
    return s[status] || status;
  }

  static formatCustomerServiceRequests(data) {
    return (data || []).map((e) => {
      e["_statusLbl"] = this.getCustomerReqStatusLabel(
        e["status"],
        e["transactionStatus"] || "",
        e["type"]
      );

      e["_bgCss"] = this.getCustomerReqStatusBg(e["_statusLbl"]);

      let totMrp = 0;
      let totPrice = 0;
      let totFreePrdPrice = 0;

      if (e.deliveryTimeSlot?.date) {
        const slot = e.deliveryTimeSlot || {};
        const dt = e.deliveryTimeSlot?.date;

        const { fromHour, fromMins, toHour, toMins } =
          CommonService.getDelvTimeHourMin(slot);

        e.deliveryTimeSlot._from = set(new Date(dt), {
          hours: fromHour,
          minutes: fromMins,
          seconds: 0,
          milliseconds: 0,
        }).toISOString();

        e.deliveryTimeSlot._to = set(new Date(dt), {
          hours: toHour,
          minutes: toMins,
          seconds: 0,
          milliseconds: 0,
        }).toISOString();
      }

      if (typeof e.collectAmount == "undefined") {
        e.collectAmount = e.orderAmount;
      }

      e._displayFinalAmt =
        e.paidAmount || e.payableAmount || e.collectAmount || e.orderAmount;

      (e.listingPayload || []).forEach((x) => {
        x._totalMrp = x.quantity * x.mrp;
        x._totalPrice = x.quantity * x.price;

        // if rejected mark all its offer as rejected
        if (x.status == "Rejected") {
          (x.offerDetails?.deals || []).forEach((e) => {
            e.status = "Rejected";
            e.remarks = x.remarks;
          });
        }

        const statusColorMap = {
          Created: "info",
          Pending: "warning",
          Approved: "success",
          Rejected: "danger",
          Completed: "primary",
          Default: "secondary",
        };

        x._statusType = x.status;
        x._displayStatus = x.status === "Created" ? "Pending" : x.status;
        x._statusType = statusColorMap[x.status] || statusColorMap.Default;

        if (x.status != "Rejected") {
          totMrp += x._totalMrp;

          (x.offerDetails?.deals || []).forEach((e) => {
            e.status = x.status;
            e._totalMrp = e.quantity * e.mrp;
            e._totalPrice = e.quantity * e.price;

            totMrp += e._totalMrp;

            if (e.isFree) {
              totFreePrdPrice += e._totalMrp;
            } else {
              totPrice += e._totalPrice;
            }
          });

          if (x.isFree) {
            totFreePrdPrice += x._totalMrp;
          } else {
            totPrice += x._totalPrice;
          }
        }
      });

      e._totalMrp = totMrp;
      e._totalPrice = totPrice;
      e._itemSavings = totMrp - totPrice;
      e._itemSavings += totFreePrdPrice;

      e._showInvoice =
        e.status === "Invoiced" ||
        e.status === "Shipped" ||
        e.status === "Delivered";

      const auditLog = e.pickerOrderData?.auditLog || [];
      const getAuditLogDetails = (action) => {
        const log = auditLog.find((log) => log.action === action);
        return log
          ? { time: log.actionAt, doneBy: log.actionBy }
          : { time: null, doneBy: null };
      };

      e["_timeline"] = [
        {
          label: "Order Created",
          key: "created",
          time: e.createdAt,
          doneBy: e.customerInfo?.id,
          icon: "bi bi-plus-circle",
          remarks: "Orderd by " + e.customerInfo?.name,
          partnerRemarks: "Order Created on [time]",
          partnerStatus: "Created",
        },
        {
          label: "Picker Alloted",
          key: "pickerAlloted",
          ...getAuditLogDetails("Alloted"),
          icon: "bi bi-person-check",
          remarks: "Picker alloted by [pickerName]",
          partnerRemarks: "Picker alloted on [time]",
          partnerStatus: "Accepted",
          showPickerEnabled: true,
        },
        {
          label: "Picker Started Picking",
          key: "pickerStartedPicking",
          ...getAuditLogDetails("Picking"),
          icon: "bi bi-box-seam",
          remarks: "Picking started by [pickerName]",
          showPickerEnabled: true,
        },
        {
          label: "Picker Completed Picking",
          key: "pickerCompletedPicking",
          ...getAuditLogDetails("Picked"),
          icon: "bi bi-check-circle",
          remarks: "Picking completed by [pickerName]",
          showPickerEnabled: true,
        },
        {
          label: "Picker Started Packing",
          key: "pickerStartedPacking",
          ...getAuditLogDetails("Packing"),
          icon: "bi bi-box",
          remarks: "Packing started by [pickerName]",
          showPickerEnabled: true,
        },
        {
          label: "Picker Completed Packing",
          key: "pickerCompletedPacking",
          ...getAuditLogDetails("Packed"),
          icon: "bi bi-check-circle",
          remarks: "Packing completed by [pickerName]",
          showPickerEnabled: true,
        },
        {
          label: "Ready for Ship",
          key: "readyForShip",
          ...getAuditLogDetails("ReadyToShip"),
          icon: "bi bi-truck",
          remarks: "Ready for ship by [pickerName]",
        },
        ...(e.status !== "Rejected" &&
        e.status !== "Cancelled" &&
        e.status !== "Expired" &&
        e.status !== "Returned"
          ? [
              {
                label: "Shipped",
                key: "shipped",
                ...getAuditLogDetails("Shipped"),
                icon: "bi bi-truck",
                remarks: "Shipped by [pickerName]",
              },
              {
                label: "Delivered",
                key: "delivered",
                ...getAuditLogDetails("Delivered"),
                icon: "bi bi-check-circle",
                remarks: "Delivered by [pickerName]",
                partnerRemarks: "Delivered on [time]",
                partnerStatus: "Completed",
              },
            ]
          : []),
        ...(e.status === "Returned"
          ? [
              {
                label: "Returned",
                key: "returned",
                ...getAuditLogDetails("Returned"),
                icon: "bi bi-arrow-return-left",
                remarks: "Returned by [pickerName]",
                partnerRemarks: "Returned on [time]",
                partnerStatus: "Cancelled",
              },
            ]
          : []),
      ];

      return e;
    });
  }

  static getClubRequests(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/customerServiceRequest",
      "GET",
      params
    );
  }

  static getClubRequestsCount(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/customerServiceRequest/count",
      "GET",
      params
    );
  }

  static formatCustomerOrderRequests(data) {
    return (data || []).map((e) => {
      e["_statusLbl"] = this.getCustomerReqStatusLabel(
        e["status"],
        e["transactionStatus"] || "",
        e["type"]
      );

      e["_bgCss"] = this.getCustomerReqStatusBg(e["_statusLbl"]);

      let totMrp = 0;
      let totPrice = 0;
      let totFreePrdPrice = 0;

      if (e.deliveryTimeSlot?.date) {
        const dt = e.deliveryTimeSlot?.date;

        e.deliveryTimeSlot._from = new Date(
          new Date(dt).setHours(e.deliveryTimeSlot.from, 0, 0, 0)
        ).toISOString();

        e.deliveryTimeSlot._to = new Date(
          new Date(dt).setHours(e.deliveryTimeSlot.to, 0, 0, 0)
        ).toISOString();
      }

      if (typeof e.collectAmount == "undefined") {
        e.collectAmount = e.orderAmount;
      }

      e._displayFinalAmt =
        e.paidAmount || e.payableAmount || e.collectAmount || e.orderAmount;

      (e.listingPayload || []).forEach((x) => {
        x._totalMrp = x.quantity * x.mrp;
        x._totalPrice = x.quantity * x.price;

        // if rejected mark all its offer as rejected
        if (x.status == "Rejected") {
          (x.offerDetails?.deals || []).forEach((e) => {
            e.status = "Rejected";
            e.remarks = x.remarks;
          });
        }

        if (x.status != "Rejected") {
          totMrp += x._totalMrp;

          (x.offerDetails?.deals || []).forEach((e) => {
            e.status = x.status;
            e._totalMrp = e.quantity * e.mrp;
            e._totalPrice = e.quantity * e.price;

            totMrp += e._totalMrp;

            if (e.isFree) {
              totFreePrdPrice += e._totalMrp;
            } else {
              totPrice += e._totalPrice;
            }
          });

          if (x.isFree) {
            totFreePrdPrice += x._totalMrp;
          } else {
            totPrice += x._totalPrice;
          }
        }
      });

      e._totalMrp = totMrp;
      e._totalPrice = totPrice;
      e._itemSavings = totMrp - totPrice;
      e._itemSavings += totFreePrdPrice;
      return e;
    });
  }

  static async getCustomerOrderRequests(params) {
    const r = await Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/customerServiceRequest",
      "GET",
      params
    );
    return r;
  }

  static async getCustomerRequestSummaryCount(params) {
    const p = { ...params, outputType: "count" };
    const r = await this.getCustomerRequestSummary(p);
    return { coun: r && r.length > 0 ? r[0].count : 0 };
  }

  static callToCustomer(params) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/callLog/manual/create",
      "POST",
      params
    );
  }

  static async getCallLogs(params) {
    const r = await Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/callLog/fetch",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((e) => {
        e._statusType = e.status == "Failed" ? "danger" : "success";
      });
    }
    return r;
  }

  static async getCallLogsCount(params) {
    const r = await Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/callLog/fetch/count",
      "GET",
      params
    );
    return { count: r.resp || 0 };
  }

  static sendMessageToCustomer(templateId, id) {
    let p = {
      templateId,
      customerId: id,
    };

    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/notificationtocustomer",
      "PUT",
      p
    );
  }

  static suspendCustomer(id, value, remarks) {
    const params = {
      remarks,
      status: value ? "Blocked" : "Unblocked",
    };

    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/suspend/customer/" +
        id,
      "PUT",
      params
    );
  }

  static async getSuspendedLogs(params) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/fetch/suspended/customer/log",
      "GET",
      params
    );
  }
}

export default Customer;
