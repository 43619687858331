const env = "live";

let api = "";
let asset = "";
let builderApi = "";

if (env === "live") {
  api = "https://newerp.storeking.in/api";
  asset = "https://newerp.storeking.in/api/asset/v1";
  builderApi = "https://cfgpanel.storeking.in/api";
}

if (env === "uat") {
  api = "https://newerp.storeking.in/api";
  asset = "https://newerp.storeking.in/api/asset/v1";
  builderApi = "https://cfgpanel.storeking.in/api";
}

const getMarketDashboardTabs = {
  tabs: [
    {
      tabName: "Messages",
      key: "marketing",
      navTo: "/configs/marketing/promotion",
      subType: "dashboard",
      rbac: [""],
    },
    {
      tabName: "Banners",
      key: "banner",
      navTo: "/configs/marketing/dashboard",
      subType: "dashboard",
      rbac: ["ViewBanner"],
    },
    {
      tabName: "StoreKing Coins",
      key: "smartcoin",
      navTo: "/dashboard/coins/dashboard",
      subType: "dashboard",
      rbac: ["EditDeal"],
    },
    {
      tabName: "Notifications",
      key: "notification",
      navTo: "/utility/notifications/list",
      subType: "dashboard",
      rbac: ["ViewNotificationTemplate"],
    },
    {
      tabName: "Storeking Offers",
      key: "bogo",
      navTo: "/configs/store-discount",
      subType: "dashboard",
      rbac: ["EditDeal"],
    },
    {
      tabName: "Widgets",
      key: "widget",
      navTo: "/configs/app-config/widget/list",
      subType: "config",
      rbac: ["AddRSPConfig", "EditRSPConfig", "ViewRSPConfig", "EditDeal"],
    },
    {
      tabName: "RSP",
      key: "rsp",
      navTo: "/cms/settings/rsp-config",
      subType: "rsp",
      rbac: ["EditDeal"],
    },
    {
      tabName: "Purchase Limit",
      key: "purchaseLimit",
      navTo: "/configs/club-deal-purchase-limit",
      subType: "dashboard",
      rbac: ["EditCategory", "AddCategory"],
    },
  ],

  rspSubTabs: [
    {
      tabName: "RSP Config",
      key: "config",
      navTo: "/cms/settings/rsp-config",
      tab: "rsp",
    },
  ],

  purchaseLimitSubTabs: [
    {
      tabName: "Purchase Limit Config",
      key: "config",
      navTo: "/configs/club-deal-purchase-limit",
      tab: "purchaseLimit",
    },
  ],

  marketingSubTabs: [
    // {
    //   tabName: "Dashboard",
    //   key: "dashboard",
    //   navTo: "/configs/marketing/dashboard",
    //   tab: "marketing",
    // },
    {
      tabName: "Message Config",
      key: "dashboard",
      navTo: "/configs/marketing/promotion",
      tab: "marketing",
    },
  ],

  bannerSubTabs: [
    {
      tabName: "ClubApp Banner Config",
      key: "dashboard",
      navTo: "/configs/marketing/dashboard",
      tab: "banner",
    },
  ],

  smartCoinSubTabs: [
    {
      tabName: "Referral Coins Dashboard",
      key: "dashboard",
      navTo: "/dashboard/coins/dashboard",
      tab: "smartcoin",
    },
    // {
    //   tabName: "Rewarded Coins",
    //   key: "rewarded",
    //   navTo: "/dashboard/coins/rewarded-coins",
    //   tab: "smartcoin",
    // },
    // {
    //   tabName: "Referral Coins ",
    //   key: "referral",
    //   navTo: "/dashboard/coins/referral-coins",
    //   tab: "smartcoin",
    // },
    {
      tabName: "Reward Config",
      key: "config",
      navTo: "/configs/smartcoin-reward",
      tab: "smartcoin",
    },
  ],

  bogoSubTabs: [
    {
      tabName: "Storeking Offers Config",
      key: "config",
      navTo: "/configs/store-discount",
      tab: "bogo",
    },
    {
      tabName: "King Slab",
      key: "kingSlab",
      navTo: "/cms/kingslab/pos",
      tab: "bogo",
    },
  ],

  widgetSubTabs: [
    {
      tabName: "Widget Config",
      key: "config",
      navTo: "/configs/app-config/widget/list",
      tab: "widget",
    },
  ],
};

export const appConfigs = {
  API: api,
  ASSET: asset,
  BUILDER_API: builderApi,
  API_VERSION: "v1",
  MARKETDASHABOARD: getMarketDashboardTabs,
  ASSET_UPLOAD: asset + "/upload",
  AJAX_REQ_CANCEL: "ERR_CANCELED",
  DARKSTORE_SUB_TYPES: ["SMARTSF", "SMARTSFCOCO", "OLASF", "PINCODESF"],
};
