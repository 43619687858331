import classNames from "classnames";
import React, { memo } from "react";
import Amount from "../Amount";
import AppCard from "../AppCard";
import Spinner from "../Spinner";
import InfoPopover from "../InfoPopover";

const SummaryCardOne = ({
  title,
  valueColor,
  value,
  loading,
  isValueAmt = false,
  description,
  amtDecimalPlace,
  icon,
  isClickable,
  data,
  onSummaryCardClick,
  info,
  noValParse = false,
  noShadow = false,
}) => {
  return (
    <AppCard noShadow={noShadow}>
      <div
        className={classNames(
          "row d-flex justify-content-center",
          isClickable ? "cursor-pointer" : ""
        )}
        onClick={() => {
          if (onSummaryCardClick && isClickable) {
            onSummaryCardClick(data);
          }
        }}
      >
        <div className="col">
          <div className="text-dark mb-0 fw-semibold">
            {title}

            {info.header || info.content ? (
              <InfoPopover
                header={info.header}
                content={info.content}
                placement={info.placement}
              />
            ) : null}
          </div>
          <h3
            className={classNames(
              "my-1 font-20 fw-bold",
              valueColor ? "text-" + valueColor : "text-muted"
            )}
          >
            {loading ? (
              <Spinner type="dots" />
            ) : (
              <>
                {isValueAmt ? (
                  <Amount value={value} decimalPlace={amtDecimalPlace} />
                ) : noValParse ? (
                  value
                ) : (
                  Number(value).toLocaleString("en-IN")
                )}
              </>
            )}
          </h3>
          {description ? (
            <p className="mb-0 text-truncate text-muted">{description}</p>
          ) : null}
        </div>
        {/*end col*/}
        <div className="col-auto align-self-center">
          {icon ? (
            <div className="d-flex justify-content-center align-items-center thumb-md bg-light-alt rounded-circle mx-auto">
              <i
                className={classNames(
                  "font-24 align-self-center bi text-muted",
                  icon
                )}
              />
            </div>
          ) : null}
        </div>
        {/*end col*/}
      </div>
      {/*end row*/}
    </AppCard>
  );
};

export default memo(SummaryCardOne);
