import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "@sk/uis";
import { FranchiseService } from "@sk/services";

const DisplayWarehouseName = ({ whId }) => {
  const [whName, setWhName] = useState({
    loading: true,
    name: "",
  });
  useEffect(() => {
    getWhName();
  }, [getWhName, whId]);

  const getWhName = useCallback(async () => {
    const r = await FranchiseService.getFranchise(whId, {
      select: "name",
    });
    if (r.statusCode !== 200) {
      setWhName({
        loading: false,
        name: "N/A",
      });
      return;
    }

    setWhName({ loading: false, name: r.resp.name });
  }, [whId]);

  if (!whId) return <div>N/A</div>;

  return (
    <>
      {whName.loading && <Spinner type="dots" isSmall={true} />}
      {!whName.loading && <div>{whName.name}</div>}
    </>
  );
};

export default DisplayWarehouseName;
