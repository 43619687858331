import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "@sk/uis";
import { FranchiseService, NavService } from "@sk/services";

const DisplayWarehouseName = ({ fId }) => {
  const [data, setData] = useState({
    loading: true,
    details: "",
  });
  useEffect(() => {
    loadFranDetails();
  }, [loadFranDetails, fId]);

  const loadFranDetails = useCallback(async () => {
    const r = await FranchiseService.getFranchise(fId, { select: "name" });
    if (r.statusCode !== 200) {
      setData({
        loading: false,
        details: "",
      });
      return;
    }

    setData({ loading: false, details: r.resp });
  }, [fId]);

  if (!fId) return <div>N/A</div>;

  const viewDetails = () => {
    if (data.details?._id) {
      //   NavService.openInNewTab("/configs/", p);
    }
  };

  return (
    <>
      {data.loading && <Spinner type="dots" isSmall={true} />}
      {!data.loading && (
        <div className="text-primary cursor-pointer" onClick={viewDetails}>
          {data.details?.name || "N/A"}{" "}
          {data.details?._id ? "-" + data.details?._id : null}
        </div>
      )}
    </>
  );
};

export default DisplayWarehouseName;
