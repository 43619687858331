import classNames from "classnames";
import React from "react";

const styles = {
  rowAutoLbl: {
    color: "#5c5c5c",
  },
  col: {
    lbl: {
      color: "#5c5c5c",
    },
  },
  rowLbl: {
    color: "#6e6e6e",
  },
};

function KeyVal({
  label = "",
  children,
  template = "row",
  noBottomSpace = false,
  className,
  labelStyle = {},
  labelBold = "medium",
  contentCss = "",
  labelCol = "col-sm-3",
  contentCol = "col-sm-9",
  fwSize = "md",
}) {
  return (
    <>
      {template == "row" ? (
        <div
          className={classNames("row", className ? className : "", {
            "mb-2": !noBottomSpace,
            "fs-val-md": fwSize == "md",
            "fs-val-sm": fwSize == "sm",
          })}
        >
          <div
            className={classNames(labelCol, {
              "fw-medium": labelBold == "medium",
              "fw-semibold": labelBold == "semibold",
              "fw-bold": labelBold == "bold",
            })}
            style={styles.rowLbl}
          >
            {label}
          </div>
          <div className={contentCol}>{children}</div>
        </div>
      ) : null}

      {template == "row-auto" ? (
        <div
          className={classNames(
            "row align-items-center",
            className ? className : "",
            {
              "mb-2": !noBottomSpace,
              "fs-val-md": fwSize == "md",
              "fs-val-sm": fwSize == "sm",
            }
          )}
        >
          <div className="col-auto" style={styles.rowAutoLbl}>
            {label}
          </div>
          <div className={classNames("col", contentCss ? contentCss : "")}>
            {children}
          </div>
        </div>
      ) : null}

      {template == "col" ? (
        <div
          className={classNames(className ? className : "", {
            "mb-2": !noBottomSpace,
            "fs-val-md": fwSize == "md",
            "fs-val-sm": fwSize == "sm",
          })}
        >
          <div
            className={classNames({
              "fw-medium": labelBold == "medium",
              "fw-semibold": labelBold == "semibold",
              "fw-bold": labelBold == "bold",
            })}
            style={{ ...styles.col.lbl, ...labelStyle }}
          >
            {label}
          </div>
          <div className="fw-medium">{children}</div>
        </div>
      ) : null}
    </>
  );
}

export default KeyVal;
