import classNames from "classnames";
import { memo } from "react";
import { AppSlide, AppSwiper } from "../sk-uis";

const tabConfig = {
  direction: "vertical",
  mousewheel: {
    forceToAxis: true,
  },
  slidesPerView: "auto",
  autoHeight: true,
};

const TabsVertical = ({ tabs = [], activeTab, clickCallback, children }) => {
  return (
    <>
      <div className="row">
        <div className="col-2">
          <AppSwiper
            config={tabConfig}
            style={{ height: "calc(100vh - 20vh)" }}
          >
            {tabs.map((x) => (
              <AppSlide key={x.key} autoHeight={true}>
                <div className="mb-3">
                  <button
                    onClick={() => clickCallback(x)}
                    className={classNames(
                      "btn w-100",
                      activeTab == x.key ? "btn-primary" : "btn-light"
                    )}
                  >
                    {x.name}
                  </button>
                </div>
              </AppSlide>
            ))}
          </AppSwiper>
        </div>
        <div className="col-10">{children}</div>
      </div>
    </>
  );
};

export default memo(TabsVertical);
