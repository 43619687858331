import { memo, useCallback, useEffect, useRef, useState } from "react";

import { CommonService } from "@sk/services";

const GoogleSignIn = ({ callback }) => {
  const [loader, setLoader] = useState(true);

  const loadedRef = useRef(false);

  const btnRef = useRef(null);

  const handleCredentialResponse = useCallback(
    (r) => {
      if (r && r.credential) {
        const t = CommonService.decodeJwt(r.credential);
        invokeCallback("authenticated", t);
      } else {
        invokeCallback("unauthenticated");
      }
    },
    [invokeCallback]
  );

  useEffect(() => {
    if (!loadedRef.current) {
      const t = setTimeout(() => {
        clearTimeout(t);
        window.google.accounts.id.initialize({
          client_id:
            "534074798626-0t4f7rot6prsjb7c5hjotmun4rfso0r1.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          auto_select: true,
        });
        window.google.accounts.id.renderButton(btnRef.current, {
          theme: "filled_blue",
        });
        window.google.accounts.id.prompt();
        setLoader(false);
      }, 1000);
    }
    return () => {
      loadedRef.current = true;
    };
  }, [handleCredentialResponse]);

  const invokeCallback = useCallback(
    (status, user) => {
      if (callback) {
        callback({ status, user });
      }
    },
    [callback]
  );

  return (
    <>
      {loader ? (
        <div className="text-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : null}
      <div ref={btnRef}></div>
    </>
  );
};

export default memo(GoogleSignIn);
