import React from "react";
import classNames from "classnames";

const AppCard = ({
  title,
  children,
  noShadow = false,
  noPad = false,
  className,
}) => {
  return (
    <div
      className={classNames("card rounded mx-0", className, {
        "shadow-sms": !noShadow,
        "shadow-none": noShadow,
      })}
    >
      {title ? (
        <div className="card-header">
          {typeof title === "string" ? (
            <h5 className="card-title fw-semibold">{title}</h5>
          ) : (
            title
          )}
        </div>
      ) : null}
      <div className={classNames("card-body", noPad ? "p-0" : "")}>
        {children}
      </div>
    </div>
  );
};

export default AppCard;
