const BtnLink = ({ children, className, onClick }) => {
  return (
    <button
      className={`btn btn-link p-0 text-dark text-start ${className}`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default BtnLink;
