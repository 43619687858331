import { merge, orderBy } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";

class Notifications {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static languageCodes = {
    en: "English",
    hi: "Hindi",
    mr: "Marathi",
    ta: "Tamil",
    te: "Telugu",
    kn: "Kannada",
    ml: "Malayalam",
  };

  static getLanguageNames(key) {
    return this.languageCodes[key] || key;
  }

  static async getList(params = {}) {
    const r = await Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/template",
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp.forEach((item) => {
        item._canCreateInPartner = item.partner == "interakt_whatsapp";
        item._createdInPartner = item.partnerConfig?.id ? true : false;
        item._languageName = this.getLanguageNames(item.languageCode);

        if (item.default?.headerType === "image") {
          const c = item.default?.header?.split("/");
          item._headerAsset = (c[c.length - 1] || "").replace(".jpg", "");
        }
      });
    }

    return r;
  }

  static getDetailsById(id = "", params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/template/" + id,
      "GET",
      params
    );
  }

  static getListCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/template/count",
      "GET",
      params
    );
  }

  static getConfig(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/config",
      "GET",
      params
    );
  }

  static createTemplate(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/template",
      "POST",
      params
    );
  }

  static updateTemplate(id = "", params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/template/" + id,
      "PUT",
      params
    );
  }

  static getLogsList(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/nslog",
      "GET",
      params
    );
  }

  static getLogsCountByStatus(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/nslog/getCountByStatus",
      "GET",
      params
    );
  }

  static getLogsCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/nslog/count",
      "GET",
      params
    );
  }

  static getEventsList(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/event",
      "GET",
      params
    );
  }

  static getEventsCount(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/event/count",
      "GET",
      params
    );
  }

  static updateEvent(id = "", params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/event/" + id,
      "PUT",
      params
    );
  }

  static createPromotionTemplate(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/config",
      "POST",
      params
    );
  }

  static async getPromotionTemplateList(params = {}) {
    const r = await Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/config",
      "GET",
      params
    );

    if (r.statusCode == 200 && Array.isArray(r.resp)) {
      r.resp.forEach((item) => {
        // Group notification types by unique type
        const uniqueTypes = [
          ...new Set(
            item.notifcationTypes.filter((n) => n.status).map((n) => n.type)
          ),
        ];
        item._notificationTypes = uniqueTypes;
      });
    }

    return r;
  }

  static getPromotionTemplateListCount(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/config/count",
      "GET",
      params
    );
  }

  static updatePromotionTemplate(id = "", params = {}) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/config/" +
        id,
      "PUT",
      params
    );
  }

  static getPromotionTemplateById(id = "", params = {}) {
    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/config/" +
        id,
      "GET",
      params
    );
  }

  static getNotificationDashboardDeliveryDetails(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/whatsapp/message/delivery/list",
      "GET",
      params
    );
  }

  static getCustomerPromotionalRuleIdsDetails(ids = [], params = {}) {
    let p = {
      filter: {
        _id: {
          $in: ids,
        },
      },
    };

    if (params) {
      p = merge(p, params);
    }

    return Ajax.request(
      this.endpoint +
        "/customer/" +
        this.apiVersion +
        "/promotionalNotification/fetchTemplate",
      "GET",
      p
    );
  }

  static getSegmentOptions() {
    return orderBy(
      [
        {
          label: "New Customer - No Orders Placed",
          value: "NEW_CUSTOMER_NO_ORDERS_PLACED",
        },
        {
          label: "One-Time Order Customer - Inactive Now",
          value: "ONE_TIME_ORDER_CUSTOMER_INACTIVE_NOW",
        },
        {
          label: "Recently Inactive - 15 Days",
          value: "RECENTLY_INACTIVE_15_DAYS",
        },
        {
          label: "Mid-Level Inactive - 30 Days",
          value: "MID_LEVEL_INACTIVE_30_DAYS",
        },
        {
          label: "Long-Term Inactive - 60 Days",
          value: "LONG_TERM_INACTIVE_60_DAYS",
        },
        {
          label: "Consistent Customer",
          value: "CONSISTENT_CUSTOMER",
        },
        {
          label: "Frequent Customer - Recently Inactive",
          value: "FREQUENT_CUSTOMER_RECENTLY_INACTIVE",
        },
        {
          label: "High-Value Customer - No Recent Orders",
          value: "HIGH_VALUE_CUSTOMER_NO_RECENT_ORDERS",
        },
        {
          label: "High-Value Customer",
          value: "HIGH_VALUE_CUSTOMER",
        },
        {
          label: "Coins Enthusiasts",
          value: "COINS_ENTHUSIASTS",
        },
        {
          label: "Gender-Based Campaigns",
          value: "GENDER_BASED_CAMPAIGNS",
        },
        {
          label: "High-Coin Balance Holders - Inactive",
          value: "HIGH_COIN_BALANCE_HOLDERS_INACTIVE",
        },
        {
          label: "Coins Expiry Warning",
          value: "COINS_EXPIRY_WARNING",
        },
        {
          label: "Referral Program Participants - Inactive",
          value: "REFERRAL_PROGRAM_PARTICIPANTS_INACTIVE",
        },
      ],
      "label"
    );
  }

  static assignAgent(id) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/callLog/update/agentAssignedAuditLog/" +
        id +
        "/Assigned",
      "PUT",
      {}
    );
  }

  static removeAgent(id) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/callLog/update/agentAssignedAuditLog/" +
        id +
        "/Notassigned",
      "PUT",
      {}
    );
  }

  static getAssignedAgentList(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/callassigend/fetchLog",
      "GET",
      params
    );
  }

  static getPartner(params = {}) {
    return Ajax.request(
      this.endpoint + "/notification/" + this.apiVersion + "/config",
      "GET",
      params
    );
  }

  static createPartnerTemplate(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/create/template/bypartner",
      "POST",
      params
    );
  }

  static fetchTemplateFromPartner(params = {}) {
    return Ajax.request(
      this.endpoint +
        "/notification/" +
        this.apiVersion +
        "/fetch/template/bypartner",
      "GET",
      params
    );
  }

  static syncPartnerTemplate(templateResponse = {}) {
    const lang = this.languageCodes;

    const payloadObj = {
      templateId: templateResponse?._id,
      templateName: templateResponse?.name
        ?.toLowerCase()
        ?.replace(/[^A-z0-9]/g, "")
        ?.replace(/\s/g, "_"),
      category: "Marketing",
      headerFormat: "",
      buttons: [],
      languageCode: lang[templateResponse?.languageCode],
    };

    if (templateResponse?.default?.headerType === "text") {
      payloadObj.headerFormat = "TEXT";
      payloadObj.header = templateResponse?.default?.header;
      payloadObj.headerText = templateResponse?.default?.headerText;
    } else if (templateResponse?.default?.headerType === "image") {
      payloadObj.headerFormat = "IMAGE";
      payloadObj.header = templateResponse?.default?.header;
    }

    payloadObj.body = templateResponse?.default?.body;
    payloadObj.bodyText = templateResponse?.default?.bodyText;
    payloadObj.footer = templateResponse?.default?.footer;

    // Extract button data from actions
    const actionsArray = templateResponse?.default?.buttons || [];

    if (actionsArray.length > 0) {
      payloadObj.buttonType = "Call To Action";
      payloadObj.buttons = actionsArray;
    }

    return this.createPartnerTemplate(payloadObj);
  }
}

export default Notifications;
