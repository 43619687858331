import { merge, orderBy } from "lodash";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import { AjaxService, CommonService } from "../sk-services";
import { format, subDays, set } from "date-fns";

class Pos {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getPosSettlementList(params = {}) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/posSettlement/fetchData",
      "GET",
      params
    );
  }

  static getCashDepositList(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/posCashDeposit/fetchData",
      "GET",
      params
    );
  }

  static posCashDepositDownload(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/posCashDeposit/fetchData",
      "GET",
      params
    );
  }

  static formatPosOrder(order) {
    let o = { ...order };

    if (o.deliveryTimeSlot?.date) {
      const slot = o.deliveryTimeSlot || {};
      const dt = o.deliveryTimeSlot?.date;

      const { fromHour, fromMins, toHour, toMins } =
        CommonService.getDelvTimeHourMin(slot);

      o.deliveryTimeSlot._from = set(new Date(dt), {
        hours: fromHour,
        minutes: fromMins,
        seconds: 0,
        milliseconds: 0,
      }).toISOString();

      o.deliveryTimeSlot._to = set(new Date(dt), {
        hours: toHour,
        minutes: toMins,
        seconds: 0,
        milliseconds: 0,
      }).toISOString();
    }

    const amt =
      (o.paymentModes || []).find((v) => v.modeType == "FranchiseeBalance") ||
      {};
    o._payableAmt = o.invoiceAmount || amt.collectAmount || amt.amount;
    o._payableAmtActual = amt.amount || 0;
    o._ordPlacedBy = o.manpowerFranchiseInfo
      ? o.manpowerFranchiseInfo
      : o.franchiseInfo;

    (o.deals || []).forEach((e) => {
      e._totalMrp = e.quantity * e.mrp;
      e._totalPrice = e.quantity * e.b2bPrice;
    });

    return o;
  }

  static async getOrders(params = {}) {
    const response = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pos/order",
      "GET",
      params
    );
    if (Array.isArray(response.resp)) {
      response.resp = response.resp.map((order) => {
        order = this.formatPosOrder(order);

        if (!order.orderType) {
          order.orderType = "POSORDER";
        }

        const { displayStatus, statusType } =
          CommonService.getPosOrderDisplayStatus(order.status, order.orderType);
        order.displayStatus = displayStatus;
        order.statusType = statusType;

        return order;
      });
    }
    return response;
  }

  static async getInvoices(params = {}) {
    const response = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/pos/invoice",
      "GET",
      params
    );
    if (Array.isArray(response.resp)) {
      response.resp = response.resp.map((invoice) => {
        invoice = this.formatPosOrder(invoice);

        return invoice;
      });
    }
    return response;
  }

  static getPosOrderStatusGroups() {
    return CommonService.getPosOrderStatusGroups();
  }

  static getCustMonthlySales(cid, params = {}) {
    const filter = {
      page: 1,
      count: 6,
      filter: {
        "customerInfo.id": cid,
      },
      groupBy: { yearMonth: "$yearMonth" },
    };

    const requestParams = merge({}, filter, params);

    return Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/fetch/POSSalesOrder/customerWise",
      "GET",
      requestParams
    );
  }

  static async getInventoryStock(params = {}, signal) {
    let p = { ...params };
    if (p.filter && p.filter._id) {
      p.franchiseId = p.filter._id;
    }
    const r = await Ajax.request(
      this.endpoint +
        "/franchise/" +
        this.apiVersion +
        "/fetch/stockInventorySummary",
      "GET",
      p,
      {
        signal,
      }
    );

    if (r.statusCode == 200 && Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        x.hasLastPurDt = false;
        x.hasSubscribeDt = false;

        if (!x.isOwnedByThirdparty) {
          if (x.purchaseData && x.purchaseData.on) {
            x.hasLastPurDt = true;
          }
          if (!x.hasLastPurDt && x.subscribedDate) {
            x.hasSubscribeDt = true;
          }
        }

        x._finalSellingPrice = CommonService.roundedByDecimalPlace(
          x.retailerSellingPrice || x.b2cPrice || x.mrp || 0,
          2
        );

        x._pnl = {
          status: "",
          color: "",
          value: x._finalSellingPrice - x.b2bprice,
        };

        if (x._pnl.value > 0) {
          x._pnl.status = "positive";
          x._pnl.color = "success";
        } else if (x._pnl.value < 0) {
          x._pnl.status = "negative";
          x._pnl.color = "danger";
        } else {
          x._pnl.status = "neutral";
          x._pnl.color = "secondary";
        }

        x._qty = x.sellInLooseQty
          ? CommonService.roundedByDecimalPlace(x.qty / 1000, 3)
          : x.qty;

        const salesDataKeys = [
          "ninetyday",
          "thirtyday",
          "fifteenday",
          "sevenday",
        ];

        salesDataKeys.forEach((key) => {
          const salesData = x.salesData?.[key];
          if (salesData) {
            salesData._qty = x.sellInLooseQty
              ? CommonService.roundedByDecimalPlace(salesData.qty / 1000, 3)
              : salesData.qty;
          }
        });

        x.hasPriceSlab = x.posPriceSlab?.length > 0;

        x.posPriceSlab = this.preparePosPriceSlabData(
          x.posPriceSlab,
          x.mrp,
          x.sellInLooseQty
        );

        x.lastPurchasedDate = x.hasLastPurDt ? x.purchaseData?.on : null;
        x.subscriptionDate = x.hasSubscribeDt ? x.subscribedDate : null;

        let s = x.salesData || {};
        let r = (s.ninetyday?._qty || 0) / 4;
        x.salesTrend = r > 0 && r > (x.qty || 0) ? "high" : "";

        if (x.sellInLooseQty) {
          x._lastCustomerOrderQty = CommonService.roundedByDecimalPlace(
            x.lastCustomerOrderQty / 1000,
            3
          );
        } else {
          x._lastCustomerOrderQty = x.lastCustomerOrderQty;
        }

        return x;
      });
    }

    return r;
  }

  static getDealsStock(params = {}, signal) {
    let p = {
      groupbycond: "deal",
      groupbycondName: "name",
    };
    return this.getInventoryStock(merge({}, p, params), signal); // Pass the signal
  }

  static getInventorySummary(params = {}) {
    return AjaxService.request(
      this.endpoint +
        "/franchise/" +
        this.apiVersion +
        "/inventory/cards/summary/list",
      "GET",
      params
    );
  }

  static getMenu(params = {}, signal) {
    let p = {
      page: 1,
      count: 100,
      groupbycond: "menu",
      groupbycondName: "name",
    };
    return this.getInventoryStock(merge({}, p, params), signal);
  }

  static getCategory(params = {}, signal) {
    let p = {
      groupbycond: "category",
      groupbycondName: "name",
    };
    return this.getInventoryStock(merge({}, p, params), signal);
  }

  static getBrand(params = {}, signal) {
    let p = {
      groupbycond: "brand",
      groupbycondName: "name",
    };
    return this.getInventoryStock(merge({}, p, params), signal);
  }

  static getDealLocation(ids = [], params = {}, signal) {
    let p = merge(
      {},
      { queryType: "fetchDataByDeal", filter: { dealId: { $in: ids } } },
      params
    );
    return Ajax.request(
      this.endpoint +
        "/deal/" +
        this.apiVersion +
        "/seller/stock/getStockMasterData",
      "GET",
      p,
      {
        signal,
      }
    );
  }

  static getPosStockAnalytics(params = {}, signal) {
    let p = {
      franchiseId: params.filter?._id,
    };
    return Ajax.request(
      this.endpoint + "/franchise/" + this.apiVersion + "/pos/analytics",
      "POST",
      merge({}, p, params),
      { signal }
    );
  }

  static getTopSellingPosStock(params = {}, signal) {
    let p = {
      endDate: format(new Date(), "yyyy-MM-dd"), // Today's date
      groupbycond: "deal",
      groupbycondName: "name",
      startDate: format(subDays(new Date(), 90), "yyyy-MM-dd"), // Date 90 days ago
      type: "TOPSELLING",
      outputType: "list",
    };
    return this.getPosStockAnalytics(merge({}, p, params), signal);
  }

  static getTopPurchasedPosStock(params = {}, signal) {
    let p = {
      endDate: format(new Date(), "yyyy-MM-dd"), // Today's date
      groupbycond: "deal",
      groupbycondName: "name",
      startDate: format(subDays(new Date(), 90), "yyyy-MM-dd"), // Date 90 days ago
      type: "TOPPURCHASED",
      outputType: "list",
    };
    return this.getPosStockAnalytics(merge({}, p, params), signal);
  }

  static async getPosTopProducts(params = {}, signal) {
    const response = await Ajax.request(
      this.endpoint +
        "/oms/" +
        this.apiVersion +
        "/fetch/TopSellingBrandCatDeal",
      "GET",
      params
    );

    // Format the response
    if (response && Array.isArray(response.resp)) {
      response.resp = response.resp.map((item) => {
        const formattedItem = { ...item, _qty: item.qty };
        if (formattedItem.sellInLooseQty) {
          formattedItem._qty = CommonService.roundedByDecimalPlace(
            item.qty / 1000,
            2
          );
        }
        return formattedItem;
      });
    }

    return response;
  }

  static async getPosSalesAnalyticsSummary(params = {}, signal) {
    const response = await Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/fetch/POSForGraph",
      "GET",
      params,
      { signal }
    );

    // Format the response
    if (response && Array.isArray(response.resp)) {
      response.resp = response.resp.map((item) => {
        const formattedItem = { ...item, _qty: item.qty };
        if (formattedItem.sellInLooseQty) {
          formattedItem._qty = CommonService.roundedByDecimalPlace(
            item.qty / 1000,
            2
          );
        }
        return formattedItem;
      });
    }

    return response;
  }

  static preparePosPriceSlabData(slabs, mrp, isLooseProduct = false) {
    const s = (slabs || []).map((e) => {
      e._mrp = mrp;

      e._offerPrice = mrp - (e.disc / 100) * mrp;

      if (e?.isFixedPrice) {
        e._offerPrice = e.price;
        e.disc = 100 - (e._offerPrice / mrp) * 100;
      }

      e._displayDisc = CommonService.roundedByDecimalPlace(e.disc, 0);
      e._normalDisc = CommonService.roundedByDecimalPlace(e._normalDisc, 0);

      if (isLooseProduct) {
        e._min = CommonService.roundedByDecimalPlace(e.min / 1000, 4) + " kg";
        e._max = CommonService.roundedByDecimalPlace(e.max / 1000, 4) + " kg";
      } else {
        e._min = e.min;
        e._max = e.max;
      }
      // e._discount = this.roundedBYDecimalPlace((e._offerPrice / mrp) * 100, 2);
      return e;
    });
    const sorted = orderBy(s, ["min"], ["asc"]);
    return sorted;
  }

  static async downloadOrdersReportData(params, callback) {
    try {
      const data = await Ajax.request(
        `${this.endpoint}/oms/${this.apiVersion}/seller/gensfreport`,
        "GET",
        params
      );

      const status = data.filePath ? "success" : "error";
      if (data.filePath) {
        const url = `${appConfigs.ASSET}/${data.filePath}`;
        window.open(url, window.cordova ? "_system" : undefined);
      }
      callback({ status });
    } catch (error) {
      callback({
        status: "error",
        err: error.error,
      });
    }
  }

  static async getPosShipments(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/posShipment/shipmentDetail`,
      "GET",
      params
    );

    if (r && Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        if (x.deliveryTimeSlot?.date) {
          const dt = x.deliveryTimeSlot?.date;

          x.deliveryTimeSlot._from = set(new Date(dt), {
            hours: x.deliveryTimeSlot.from,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }).toISOString();

          x.deliveryTimeSlot._to = set(new Date(dt), {
            hours: x.deliveryTimeSlot.to,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }).toISOString();
        }

        if (x.status == "Pending") {
          x._textColorCssClass = "color-primary";
          x._statusRemarks =
            'Code has been sent to Delivery Person "' +
            x.shipmentUserName +
            " (" +
            x.shipmentUserId +
            ')"';
        }

        if (x.status == "Accepted") {
          x._textColorCssClass = "color-green";
          x._statusRemarks =
            'The Delivery Person "' +
            x.shipmentUserName +
            " (" +
            x.shipmentUserId +
            ')" code verification sucess';
        }

        if (x.status == "Reached") {
          x._textColorCssClass = "color-green";
          x._statusRemarks =
            'The Delivery Person "' +
            x.shipmentUserName +
            " (" +
            x.shipmentUserId +
            ')" reached customer place';
        }

        if (x.status == "Shipped") {
          x._textColorCssClass = "color-green";
          x._statusRemarks =
            'The Delivery Person "' +
            x.shipmentUserName +
            " (" +
            x.shipmentUserId +
            ')" handed over the order to customer';
        }

        if (x.status == "Delivered") {
          x._textColorCssClass = "color-green";
          x._statusRemarks =
            'The Delivery Person "' +
            x.shipmentUserName +
            " (" +
            x.shipmentUserId +
            ')" delivered order succesfully';
        }

        if (x.status == "Cancelled") {
          x._textColorCssClass = "color-red";
          x._statusRemarks =
            (x.statusUpdateLog || []).find((e) => e.status == "Cancelled")
              ?.remark || "";
          if (!x.isCustomerAvailable) {
            x._statusRemarks += " . Customer was not there";
          }
        }

        x._statusLog = [
          {
            key: "ordered",
            name: "Order Generated",
          },
          {
            key: "accepted",
            name: "Delivery Person Accepted Shipment",
            log: (x.statusUpdateLog || []).find((v) => v.status == "Accepted"),
          },
          {
            key: "shipped",
            name: "Delivery Person Started Delivery",
            log: (x.statusUpdateLog || []).find((v) => v.status == "Shipped"),
          },
          {
            key: "reached",
            name: "Delivery Person Reached Customer Location",
            log: (x.statusUpdateLog || []).find((v) => v.status == "Reached"),
          },
        ];

        if (x.status == "Returned") {
          x._statusLog.push({
            key: "returned",
            name: "Order Returned",
            log: (x.statusUpdateLog || []).find((v) => v.status == "Returned"),
          });
        } else {
          x._statusLog.push({
            key: "delivered",
            name: "Delivery Person Delivered Order",
            log: (x.statusUpdateLog || []).find((v) => v.status == "Delivered"),
          });
        }
        return x;
      });
    }
    return r;
  }

  static async getCustomerWiseOrders(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/fetch/POSSalesOrder/customerWise`,
      "GET",
      params
    );
    return r;
  }

  static async getPosSettlement(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/posSettlement/fetchData`,
      "GET",
      params
    );
    return r;
  }

  static async getPosDeposits(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/posCashDeposit/fetchData`,
      "GET",
      params
    );

    if (r && Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        if (x.status == "Created") {
          x._statusLbl = "Deposit Pending";
          x._statusBadge = "warning";
        } else if (x.status == "PendingApproval") {
          x._statusLbl = "Approval Pending";
          x._statusBadge = "secondary";
        } else if (x.status == "ProofPending") {
          x._statusLbl = "Rejected";
          x._statusBadge = "danger";
        } else if (x.status == "Deposited" || x.status == "Approved") {
          x._statusLbl = "Approved";
          x._statusBadge = "success";
        } else {
          x._statusLbl = x.status;
          x._statusBadge = "secondary";
        }
        return x;
      });
    }

    return r;
  }

  static async getEmpList(params) {
    const r = await Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/manpower`,
      "GET",
      params
    );
    return r;
  }

  static async getEmployeeSettlement(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/posShipmentEarning/fetchData`,
      "GET",
      params
    );
    return r;
  }

  static async getPosStockLedger(params) {
    const r = await Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/seller/stock/ledger`,
      "GET",
      params
    );
    return r;
  }

  static async getRackBin(params) {
    const response = await Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/rackandbin/config`,
      "GET",
      params
    );

    // Format the response to include alias names for racks
    if (Array.isArray(response.resp)) {
      response.resp = response.resp.map((item) => {
        const rackAliasMap = {
          D: "Damage",
          E: "Expired",
          N: "Near Expiry",
        };

        // Update alias name only if LocationId is 'L2'
        if (item.code === "L2") {
          item.racks = item.racks.map((rack) => ({
            ...rack,
            aliasName: rackAliasMap[rack.name] || rack.name, // Use alias or original name
          }));
        }
        return item;
      });
    }
    return response;
  }

  static getRackBinAllotmentPercent(params) {
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/rackAndBin/fetchPercentageWise`,
      "GET",
      params
    );
  }

  static async searchDealInRackBin(sellerId, params) {
    let p = merge(
      {},
      {
        queryType: "fetchDataByProduct",
        page: 1,
        count: 100,
        filter: {
          "sellerInfo._id": sellerId,
        },
      },
      params
    );
    const response = await Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/seller/stock/getStockMasterData`,
      "GET",
      p
    );

    // Format the response to include alias names for racks
    if (Array.isArray(response.resp)) {
      const rackAliasMap = {
        D: "Damage",
        E: "Expired",
        N: "Near Expiry",
      };
      response.resp = response.resp.map((item) => {
        // Update alias name only if LocationId is 'L2'
        if (item.LocationId === "L2") {
          return {
            ...item,
            aliasName: rackAliasMap[item.RackName] || item.RackName, // Use alias or original name
          };
        }
        return item; // Return unchanged item if LocationId is not 'L2'
      });
    }
    return response;
  }

  static async getClubReqDeliverySummary(params) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/fetch/orderlistBySlot`,
      "GET",
      params
    );
    return r;
  }

  static async getDeliveryTimeSlot(params = {}) {
    const r = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/deliveryTimeSlot/config`,
      "GET",
      params
    );

    if (Array.isArray(r.resp) && r.resp.length > 0) {
      r.resp.forEach((e) => {
        if (Array.isArray(e.slab)) {
          orderBy(e.slab, ["from"], ["asc"]).forEach((v) => {
            const { fromHour, fromMins, toHour, toMins } =
              CommonService.getDelvTimeHourMin(v);
            v._fromHour = fromHour;
            v._fromMins = fromMins;
            v._toHour = toHour;
            v._toMins = toMins;
          });
        }
      });
    }

    return r;
  }

  static getBinItems(binId, params = {}) {
    let p = merge(
      {},
      {
        queryType: "fetchDataByLocation",
        filter: { "location.binId": binId },
      },
      params
    );
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/seller/stock/getStockMasterData`,
      "GET",
      p
    );
  }

  static downloadRackBin(sellerId, params = {}) {
    let p = merge({}, { filter: { "sellerInfo._id": sellerId } }, params);
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/rackandbin/report/download`,
      "GET",
      p
    );
  }

  static async getReturns(params) {
    const response = await Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/pos/return`,
      "GET",
      params
    );

    if (response && Array.isArray(response.resp)) {
      response.resp = response.resp.map((item) => {
        const statusType = (() => {
          switch (item.status?.toLowerCase()) {
            case "pending":
              return "warning";
            case "approved":
              return "success";
            case "rejected":
              return "danger";
            case "completed":
              return "info";
            default:
              return "secondary";
          }
        })();

        const displayStatus =
          item.status?.toLowerCase() === "closed" ? "Returned" : item.status;

        const type = item.shipmentUserId ? "CLUB" : "POS";

        const returnTypeBadge = type === "CLUB" ? "success" : "warning";

        return {
          ...item,
          statusType,
          displayStatus,
          type,
          returnTypeBadge,
        };
      });
    }

    return response;
  }

  static async createPurchaseBasket(params) {
    return Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/purchase/bucket/create`,
      "POST",
      params
    );
  }

  static async updatePurchaseBasket(id, params) {
    return Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/purchase/bucket/update/${id}`,
      "PUT",
      params
    );
  }

  static async getPurchaseBaskets(params) {
    return Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/purchase/bucket/list`,
      "GET",
      params
    );
  }

  static async updatePurchaseBasketStatus(id, status) {
    return Ajax.request(
      `${this.endpoint}/franchise/${this.apiVersion}/purchase/bucket/status/update/${id}`,
      "PUT",
      { status }
    );
  }

  static async getRealTimePickerOrders(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/order/created/list`,
      "GET",
      params
    );
  }

  static async getRealTimePickings(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/picking/status/order/list`,
      "GET",
      params
    );
  }

  static async getRealTimeStartPicking(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/alloted/status/order/list`,
      "GET",
      params
    );
  }

  static async getRealTimeStartPacking(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/picked/status/order/list`,
      "GET",
      params
    );
  }

  static async getRealTimePacking(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/packing/status/order/list`,
      "GET",
      params
    );
  }

  static async getRealTimeReadyToShip(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/readyToShip/status/order/list`,
      "GET",
      params
    );
  }

  static async getRealTimeIntransit(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/shipped/status/order/list`,
      "GET",
      params
    );
  }

  static async getNonDeliveredOrders(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/notDelivered/status/order/list`,
      "GET",
      params
    );
  }

  static async getReturnOrders(params) {
    return Ajax.request(
      `${this.endpoint}/oms/${this.apiVersion}/picker/returned/status/order/list`,
      "GET",
      params
    );
  }

  static async getPosPriceChangeLog(params) {
    const r = await Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/priceConfigLog`,
      "GET",
      params
    );

    if (Array.isArray(r.resp)) {
      r.resp = r.resp.map((x) => {
        x._reason = "";
        if (Object.keys(x.oldData).length && Object.keys(x.newData).length) {
          const o = x.oldData;
          const n = x.newData;
          if (o.mrp != n.mrp) {
            x._reason = "MRP Changed";
          } else if (o.price != n.price) {
            x._reason = "Selling Price Changed";
          } else if (o.discount != n.discount) {
            x._reason = "Discount Changed";
          } else {
            x._reason = "";
          }
        }
        return x;
      });
    }

    return r;
  }

  static async getPosPriceChangeLogCount(params) {
    return Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/priceConfigLog/count`,
      "GET",
      params
    );
  }

  static async getNewlyLaunchedProducts(params) {
    const response = await Ajax.request(
      `${this.endpoint}/deal/${this.apiVersion}/fetch/newly/launched/deals`,
      "GET",
      params
    );

    return response;
  }

  static async getPartnerOrderLog(params) {
    const response = await Ajax.request(
      `${this.endpoint}/commerce/${this.apiVersion}/partner/orderLog`,
      "GET",
      params
    );

    return response;
  }
}

export default Pos;
