import {
  BrandService,
  CategoryService,
  CommonService,
  CustomerService,
  DealService,
  EmployeeService,
  FranchiseService,
  OmsService,
  ProductService,
  RolesService,
  UserService,
  UtilityService,
  VendorService,
} from "@sk/services";
import merge from "lodash/merge";
import { memo, useCallback } from "react";
import AutoCompleteInput from "./AutoCompleteInput";

let abortCtrl;

const getSearchResult = async (type, search, filterParams) => {
  // if (abortCtrl) {
  //   abortCtrl.abort();
  // }

  // abortCtrl = new AbortController();

  const t = type.toLowerCase();
  const regexSearch = CommonService.sanitizeRegex(search);
  let params = {
    page: 1,
    count: 10,
    filter: {
      $or: [
        { name: { $regex: regexSearch, $options: "gi" } },
        { _id: { $regex: regexSearch, $options: "gi" } },
      ],
    },
  };

  if (t == "brand") {
    const r = await BrandService.getList(
      merge(
        {},
        { filter: { status: "Active" } },
        { ...params, sort: "name" },
        filterParams
      )
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (t == "category" || t == "menu") {
    let p = merge(
      {},
      { filter: { status: "Active" } },
      { ...params, sort: "name" },
      filterParams
    );
    if (t == "menu") {
      if (!p.filter) {
        p.filter = {};
      }
      // p.filter.parent = { $exists: false };
      p.filter.hasParentCategory = false;
    }

    if (t == "category") {
      if (!p.filter) {
        p.filter = {};
      }
      p.filter.hasParentCategory = true;
    }

    const r = await CategoryService.getList(p);
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (t == "product") {
    const r = await ProductService.getList(
      merge(
        {},
        { filter: { status: "Active" } },
        { ...params, sort: "name" },
        filterParams
      )
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (type == "franchise") {
    const r = await FranchiseService.getList(
      merge({}, params, filterParams, { sort: "name" })
    );

    console.log(r);

    return {
      data: (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (type == "user") {
    const r = await UserService.getList(
      merge(
        {},
        {
          filter: {
            $or: [
              { name: { $regex: search, $options: "gi" } },
              { lastName: { $regex: search, $options: "gi" } },
            ],
          },
        },
        filterParams,
        { sort: "name" }
      )
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name,
        value: x,
      })),
    };
  }

  if (type == "customer") {
    let matchCond = {
      fName: { $regex: search, $options: "gi" },
    };

    if (/^CR\d/.test(search)) {
      matchCond = {
        _id: search,
      };
    }

    if (/^\d/.test(search)) {
      matchCond = {
        mobile: search,
      };
    }

    const r = await CustomerService.getList(
      merge(
        {},
        {
          page: 1,
          count: 10,
          select: "fName",
          filter: matchCond,
          sortBy: {
            fName: 1,
          },
        },
        filterParams
      )
    );
    return {
      data: (Array.isArray(r.resp) ? r.resp : []).map((x) => ({
        label: x.fName + " - " + x._id,
        value: x,
      })),
    };
  }

  if (t == "deal") {
    const r = await DealService.getDeals(
      merge({}, params, { dealFor: "erp", sort: "name" }, filterParams)
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (t == "classification") {
    const r = await UtilityService.getClassificationList(
      merge({}, { ...params, sort: "name" }, filterParams)
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }
  if (t == "classgroup") {
    const r = await UtilityService.getClassGroupList(
      merge({}, { ...params, sort: "name" }, filterParams)
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (t == "order") {
    const r = await OmsService.getOrderList(
      merge({}, { ...params, sort: "name" }, filterParams)
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (type == "vendor") {
    const r = await VendorService.getVendors(
      merge({}, params, filterParams, { sort: "name" })
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }
  if (type == "groups") {
    // Pushing this Inside Feature
    params.filter.$or.push({
      feature: {
        $regex: search,
        $options: "gi",
      },
    });
    const r = await UserService.getGroupsList(
      merge({}, params, { sort: "name" })
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name,
        value: x,
      })),
    };
  }

  if (t == "roles") {
    const r = await RolesService.getList(
      merge({}, { ...params, sort: "name" }, filterParams)
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  if (t == "employee") {
    const r = await EmployeeService.getEmployees(
      merge(
        {},
        {
          filter: { firstName: { $regex: regexSearch, $options: "gi" } },
          sort: "firstName",
        },
        filterParams
      )
    );
    return {
      data: (r.resp || []).map((x) => ({
        label: x.firstName + " " + x?.lastName + " (" + x._id + ")",
        value: x,
      })),
    };
  }

  return new Promise((resolve) => resolve({ resp: [] }));
};

const getTemplate = (type, data) => {
  if (type === "franchise") {
    return (
      <div>
        <div>{data.label}</div>
        <div className="text-muted fs-val-sm">
          {data.value.district}, {data.value.state} - {data.value.pincode}
        </div>
      </div>
    );
  } else if (type === "groups") {
    return (
      <div>
        <div>{data.label}</div>
        <div className="text-muted fs-val-sm">
          Feature - {data.value.feature}
        </div>
      </div>
    );
  } else {
    return <div className="text-wrap fs-val-md">{data.label}</div>;
  }
};

const EntitySearchInput = ({
  type,
  label,
  error,
  placeholder,
  layout,
  isMandatory,
  value,
  callback,
  uid,
  isMultiple = false,
  note = "",
  info = {},
  disabled = false,
  template,
  filterParams = {},
  emptyLabel = "No data found",
  groupContent,
  size = "",
  gap,
}) => {
  const onSearch = useCallback(
    async (val, callback) => {
      const trimmedValue = val.trim();
      const r = await getSearchResult(type, trimmedValue, filterParams);
      callback(r.data);
    },
    [filterParams, type]
  );

  const fetchTemplate = useCallback(
    (e) => {
      if (template) {
        return template(e);
      }
      return getTemplate(type, e);
    },
    [type, template]
  );

  return (
    <AutoCompleteInput
      label={label}
      error={error}
      placeholder={placeholder}
      layout={layout}
      isMandatory={isMandatory}
      value={value}
      onSearch={onSearch}
      callback={callback}
      uid={uid}
      isMultiple={isMultiple}
      note={note}
      info={info}
      disabled={disabled}
      template={fetchTemplate}
      emptyLabel={emptyLabel}
      groupContent={groupContent}
      size={size}
      gap={gap}
    />
  );
};

export default memo(EntitySearchInput);
