import axios from "axios";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import values from "lodash/values";
import merge from "lodash/merge";
import { StorageService } from "../sk-services";
import Storage from "./storage";

const prepareParams = (params) => {
  const keys = Object.keys(params || {});
  let t = {};
  keys.forEach((x) => {
    const p = typeof params[x];
    t[x] = p == "object" && p != null ? JSON.stringify(params[x]) : params[x];
  });
  return t;
};

class Ajax {
  static abrtCtrl = typeof window != "undefined" ? new AbortController() : null;

  static handleUnAuthAbortCalls(res) {
    if (res && (res.status == 401 || res.response?.status == 401)) {
      if (this.abrtCtrl && this.abrtCtrl.abort) {
        this.abrtCtrl.abort();
      }

      if (typeof window != "undefined") {
        const l = location.href;
        if (!/auth\/login/.test(l)) {
          StorageService.remove("emp");
          StorageService.remove("_btkn");
          StorageService.remove("sktkn");
          location.href = location.origin + location.pathname + "#/auth/login";
        }
      }
    }
  }

  static parseError(err) {
    // ERROR PARSER
    let m = "";
    try {
      if (isString(err.message)) {
        m = err.message;
      }
      if (!m && get(err, "message.responseMessage")) {
        m = err.message.responseMessage;
      }
      if (!m && get(err, "Error.responseDetail.responseMessage")) {
        m = get(err, "Error.responseDetail.responseMessage");
      }
      if (!m && get(err, "Error.responseMessage")) {
        m = get(err, "Error.responseMessage");
      }
      if (!m) {
        const p = JSON.parse(err.message);
        if (isArray(p)) {
          m = p.map((e) => e.message || "").join(", ");
        } else if (isObject(p)) {
          m =
            p.message ||
            values(p)
              .map((e) => e.message)
              .join(", ");
        } else {
          m = err.message;
        }
      }
    } catch (e) {
      m = err.message;
    }
    return {
      msg: m,
      raw: err,
    };
  }

  static request(url, method, params, config) {
    const m = (method || "get").toLowerCase();

    let defaultConfig = {
      headers: { "Content-Type": "application/json" },
      cache: false,
    };

    if (["get"].indexOf(m) != -1) {
      defaultConfig.data = {};
    }

    // ATTACH SK JWT TOKEN
    const skApis = /newerp\.storeking\.in|uat\.storeking\.in\/api/gi;
    if (skApis.test(url)) {
      defaultConfig.headers["Authorization"] = "JWT " + Storage.get("sktkn");
    }

    // FOR BUILDER APIS
    if (/(api\/builder|cfgpanel.storeking.in\/api)/.test(url)) {
      defaultConfig.headers["Authorization"] = Storage.get("_btkn");
    }

    if (this.abrtCtrl && this.abrtCtrl.signal) {
      // defaultConfig.signal = this.abrtCtrl.signal;
    }

    const getCall = async (url, params, config) => {
      try {
        const r = await axios({
          method: "get",
          url,
          params: prepareParams(params),
          ...merge({}, defaultConfig, config || {}),
        });

        this.handleUnAuthAbortCalls(r);
        return {
          statusCode: r?.status || "",
          resp: r?.data || "",
          raw: r,
        };
      } catch (e) {
        const errorResponse = e;
        this.handleUnAuthAbortCalls(errorResponse);
        return {
          statusCode: errorResponse?.status || "",
          resp: errorResponse?.response?.data || "",
          raw: errorResponse,
        };
      }
    };

    const postCall = async (url, params, config) => {
      const r = await axios({
        url: url,
        method: "post",
        data: params,
        ...merge({}, defaultConfig, config || {}),
      }).catch((e) => e.response);
      this.handleUnAuthAbortCalls(r);
      return { statusCode: r?.status, resp: r?.data };
    };

    const putCall = async (url, params, config) => {
      const r = await axios({
        url: url,
        method: "put",
        data: params,
        ...defaultConfig,
        ...(config || {}),
      }).catch((e) => e.response);
      this.handleUnAuthAbortCalls(r);
      return { statusCode: r?.status, resp: r?.data };
    };

    const deleteCall = async (url, params, config) => {
      const r = await axios({
        url: url,
        method: "delete",
        data: params,
        ...defaultConfig,
        ...(config || {}),
      }).catch((e) => e.response);
      this.handleUnAuthAbortCalls(r);
      return { statusCode: r?.status, resp: r.data };
    };

    switch (m) {
      case "get":
        return getCall(url, params, config);
      case "post":
        return postCall(url, params, config);
      case "put":
        return putCall(url, params, config);
      case "delete":
        return deleteCall(url, params, config);
      default:
        return getCall(url, params, config);
    }
  }
}

export default Ajax;
