import classNames from "classnames";
import InfoPopover from "../InfoPopover";
import InputErrorMsg from "./InputErrorMsg";

const FormInputLayout = ({
  children,
  layout,
  label,
  error,
  isMandatory,
  type,
  note,
  gap = 3,
  info = { header: "", content: "" },
}) => {
  return (
    <div
      className={classNames("mb-" + gap, {
        "form-check": type == "checkbox" || type == "radio",
      })}
    >
      {label ? (
        <label
          className={classNames("mb-1 fs-val-sm", {
            "form-check-label": type == "checkbox" || type == "radio",
            // "form-label": type !== "checkbox" && type !== "radio",
          })}
        >
          {label} {isMandatory && <span className="text-danger"> * </span>}
          {info.header || info.content ? (
            <InfoPopover
              header={info.header}
              content={info.content}
              placement={info.placement}
            />
          ) : null}
        </label>
      ) : null}

      {children}

      {note ? <div className="form-text fs-val-sm">{note}</div> : null}

      {error && (
        <div
          className={classNames({
            "col-2": layout === "horizontala",
          })}
        >
          <InputErrorMsg msg={error} />
        </div>
      )}
    </div>
  );
};

export default FormInputLayout;
