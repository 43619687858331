import { toast } from "react-toastify";

export class Toaster {
  static error(msg) {
    toast.error(msg);
  }

  static success(msg) {
    toast.success(msg);
  }

  static warn(msg) {
    toast.warn(msg);
  }
}

// export default Toaster;
