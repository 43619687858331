import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import FormInputLayout from "./FormInputLayout";

const toolbarOptions = [
  [{ header: [1, 2, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
];

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "code-block",
  "link",
];

const style = {
  height: "200px",
};

const RichTextEditor = ({
  label,
  type,
  error,
  layout,
  isMandatory,
  value,
  onChangeCb,
  note = "",
}) => {
  const editorRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    if (editorRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: toolbarOptions,
        },
        formats: [...formats],
      });
    }
  }, []);

  useEffect(() => {
    if (quillInstance.current) {
      quillInstance.current.on("text-change", () => {
        const html = quillInstance.current.root.innerHTML;
        if (html === "<p><br></p>") {
          onChangeCb("");
        } else {
          onChangeCb(html);
        }
      });
    }
  }, [onChangeCb]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        quillInstance.current &&
        quillInstance.current.root.innerHTML === "<p><br></p>"
      ) {
        quillInstance.current.root.innerHTML = value || "";
      }
    }, 800); // Set timeout to allow for any pending updates

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [value]);

  return (
    <FormInputLayout
      label={label}
      type={type}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      note={note}
    >
      <div ref={editorRef} style={style} />
    </FormInputLayout>
  );
};

export default RichTextEditor;
