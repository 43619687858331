import { useState } from "react";
import Typeahead from "../Typeahead";
import FormInputLayout from "./FormInputLayout";

const AutoCompleteInput = ({
  label,
  error,
  placeholder,
  layout,
  isMandatory,
  value,
  onSearch,
  callback,
  uid,
  isMultiple = false,
  note = "",
  info = {},
  groupContent,
  disabled = false,
  template,
  emptyLabel = "",
  size = "",
  gap,
}) => {
  const [search, setSearch] = useState({
    loading: false,
    result: [],
  });

  const onSearchCallback = async (val) => {
    setSearch((v) => ({ ...v, loading: true, result: [] }));
    onSearch(val, (data) => {
      // search data structure = [{label: '', value: {}}];
      setSearch((v) => ({ ...v, loading: false, result: data }));
    });
  };

  const onSelect = (val) => {
    setSearch((v) => ({ ...v, value: val }));
    callback(val);
  };

  const renderInput = () => (
    <Typeahead
      val={value || []}
      searching={search.loading}
      onSearch={onSearchCallback}
      result={search.result}
      placeholder={placeholder}
      onItemChange={onSelect}
      uid={uid}
      isMultiple={isMultiple}
      disabled={disabled}
      template={template}
      emptyLabel={emptyLabel}
      size={size || null}
    />
  );

  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      note={note}
      info={info}
      gap={gap}
    >
      {groupContent ? (
        <div className="input-group">
          <span className="input-group-text p-0">{groupContent}</span>
          {renderInput()}
        </div>
      ) : (
        renderInput()
      )}
    </FormInputLayout>
  );
};

export default AutoCompleteInput;
