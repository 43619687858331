import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { cloneDeep } from "lodash";

const ChartRender = ({ type, data, options = {} }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      const isHorizontal = type === "horizontalBar";
      const chartType = isHorizontal ? "bar" : type;

      const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: isHorizontal ? "y" : "x",
        scales: {
          x: {
            beginAtZero: true,
          },
        },
      };

      const mergedOptions = { ...defaultOptions, ...options };

      chartInstance.current = new Chart(ctx, {
        type: chartType,
        data: cloneDeep(data),
        options: mergedOptions,
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [type, data, options]);

  return <canvas ref={chartRef} />;
};

export default ChartRender;
