export const sourcingMenus = {
  groups: [],
  icon: "truck",
  name: "Sourcing",
  priority: 3,
  redirect: {
    path: "",
    type: "",
  },
  subMenus: [
    {
      name: "Dashboard",
      priority: 0,
      groups: [],
      subMenus: [
        {
          name: "Reservation",
          priority: 0,
          groups: ["ListProductReservation"],
          redirect: {
            path: "/dashboard/reservation",
            type: "sameWindow",
          },
        },
        {
          name: "Order Placed No Stock",
          priority: 1,
          groups: ["StockNotAllocatedDashboard"],
          redirect: {
            path: "/dashboard/stock/not-allocated-orders",
            type: "sameWindow",
          },
        },
        {
          name: "Inventory Analysis",
          priority: 2,
          groups: ["InventoryAgeingDashboard"],
          redirect: {
            path: "/dashboard/inventory-ageing",
            type: "sameWindow",
          },
        },
      ],
    },
    {
      editIndex: 3,
      groups: ["AddPO", "ListThirdPartyPO", "ViewThirdpartyPO"],
      name: "POS Thirdparty PO",
      priority: 1,
      redirect: {
        path: "/purchase-order/pos/list",
        type: "sameWindow",
      },
    },
    {
      name: "Store Returns",
      groups: ["ListStoreReturns"],
      redirect: {
        path: "/logistics/store-returns",
      },
    },
    {
      name: "Orders",
      groups: ["ListOrder"],
      redirect: {
        path: "/oms/orders",
        type: "sameWindow",
      },
    },
    {
      name: "Vendors",
      groups: ["ListOrder"],
      redirect: {
        path: "/vendor/list",
        type: "sameWindow",
      },
    },
  ],
};
