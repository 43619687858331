import produce from "immer";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AuthService } from "../../../services/src/sk-services";
import { SideMenuService } from "../sk-uis";
import logo from "../images/logo.svg";

const SideMenu = ({ router }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({}); // Track open submenus by index
  const [menus, setMenus] = useState({ loading: true, data: [] });

  const assignedGroupsRef = useRef([]);

  const sideMenuStyle = useMemo(() => {
    return {
      width: isExpanded ? "250px" : "65px",
      height: "100vh",
      transition: "width 0.3s",
      overflowX: "hidden",
      overflowY: "auto",
      zIndex: 1000,
      backgroundColor: "#292e40",
    };
  }, [isExpanded]);

  useEffect(() => {
    let timeout = null;

    const fetchMenus = async () => {
      setMenus(
        produce((draft) => {
          draft.loading = true;
        })
      );

      timeout = setTimeout(() => {
        let groups = [];
        if (assignedGroupsRef.current.length > 0) {
          groups = assignedGroupsRef.current;
        } else {
          const groupsResp = AuthService.getLoggedUserGroups();
          groups = groupsResp;
          assignedGroupsRef.current = [...groups];
        }

        setMenus(
          produce((draft) => {
            draft.loading = false;
            draft.data = SideMenuService.getAllowedMenus(groups);
          })
        );
      }, 800);
    };

    fetchMenus();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle submenu based on index
  const toggleSubMenu = (index) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the state for the given index
    }));
  };

  // Handle redirection
  const handleRedirection = (path, type, params) => {
    SideMenuService.nav(path, type, router, params);
  };

  return (
    <div
      className={`sidebar position-fixed text-white custom-scrollbar`}
      style={sideMenuStyle}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="text-center py-2">
        <a
          className="d-flex align-items-center justify-content-center"
          style={{ height: "40px" }}
        >
          <div className="d-flex align-items-center">
            <img
              src={logo}
              width={35}
              height={35}
              alt="logo"
              className="ms-1"
            />
            <span
              className={`align-middle text-white fw-bold ms-2 ${
                isExpanded ? "d-inline-block" : "d-none"
              }`}
            >
              STOREKING
            </span>
          </div>
        </a>
      </div>
      <ul className="nav flex-column p-2">
        {menus.data.map((menu, index) => (
          <MenuItem
            key={index}
            menu={menu}
            index={index} // Pass the index to MenuItem
            isExpanded={isExpanded}
            openSubMenus={openSubMenus}
            toggleSubMenu={toggleSubMenu}
            handleRedirection={handleRedirection} // Pass the redirection handler
          />
        ))}
      </ul>
    </div>
  );
};

const MenuItem = ({
  menu,
  index,
  isExpanded,
  openSubMenus,
  toggleSubMenu,
  handleRedirection,
}) => {
  const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
  const isSubMenuOpen = openSubMenus[index]; // Use index to check if submenu is open

  return (
    <li className="nav-item">
      <a
        role="button"
        className="nav-link text-white d-flex align-items-center justify-content-between fs-val-md"
        onClick={(e) => {
          if (hasSubMenus) {
            e.preventDefault();
            toggleSubMenu(index); // Pass the index to toggleSubMenu
          } else {
            e.preventDefault();
            handleRedirection(
              menu.redirect?.path,
              menu.redirect?.type,
              menu.redirect?.params
            ); // Handle redirection
          }
        }}
      >
        <div className="d-flex align-items-center">
          <i className={`bi bi-${menu.icon} me-3 fs-val-xl`}></i>
          {isExpanded && <span className="fw-semibold">{menu.name}</span>}
        </div>
        {hasSubMenus && isExpanded && (
          <i
            className={`bi ${
              isSubMenuOpen ? "bi-chevron-up" : "bi-chevron-down"
            }`}
          ></i>
        )}
      </a>

      {hasSubMenus && isExpanded && (
        <div
          className={`sub-menu ps-3 ${isSubMenuOpen ? "d-block" : "d-none"}`}
        >
          <ul className="nav flex-column">
            {menu.subMenus.map((subMenu, subIndex) => (
              <MenuItem
                key={subIndex}
                menu={subMenu}
                index={`${index}-${subIndex}`} // Use a unique index for nested submenus
                isExpanded={isExpanded}
                openSubMenus={openSubMenus}
                toggleSubMenu={toggleSubMenu}
                handleRedirection={handleRedirection} // Pass the redirection handler
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default SideMenu;
