import { AuthService } from "@sk/services";
import FormInputLayout from "./FormInputLayout";

const states = AuthService.getLoggedInEmpWhStates().map((x) => ({
  label: x,
  value: x,
}));

const WarehouseStatesInput = ({
  label = "State",
  register,
  name,
  error,
  isMandatory,
  callback,
  note = "",
  info = {},
  disabled = false,
  gap,
}) => {
  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      name={name}
      note={note}
      info={info}
      gap={gap}
    >
      <select
        {...register(name, {
          onChange: (e) => (callback ? callback(e.target.value) : null),
        })}
        className="form-select"
        disabled={disabled}
      >
        {(states || []).map((e, i) => (
          <option key={e.value + "" + i} value={e.value}>
            {e.label}
          </option>
        ))}
      </select>
    </FormInputLayout>
  );
};

export default WarehouseStatesInput;
