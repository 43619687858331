import { memo, useState } from "react";
import { Tabs } from "../sk-uis";

const tabs = [
  { key: "brand", tabName: "Brand Master" },
  { key: "category", tabName: "Category Master" },
  { key: "categoryBrand", tabName: "Category-Brand Master" },
  { key: "vendor", tabName: "Vendor Master" },
];
const VendorFilterTab = ({ callback }) => {
  const [activeTab, setActiveTab] = useState({
    key: "brand",
    tabName: "Brand Master",
  });

  const tabCb = (data) => {
    const newActiveTab = tabs.find((tab) => tab.key === data.value);
    setActiveTab(newActiveTab);
    callback({ vendorType: data.value });
  };

  return (
    <>
      <Tabs data={tabs} activeTab={activeTab} callback={tabCb} />
      <div className="tab-pane py-3">
        {activeTab.key === "brand" && (
          <>
            <input className="form-control" placeholder="Search for brand" />
          </>
        )}
        {activeTab.key === "category" && <>Category Master</>}
        {activeTab.key === "categoryBrand" && <>Category-brand Master</>}
        {activeTab.key === "vendor" && <>Vendor</>}
      </div>
    </>
  );
};

export default memo(VendorFilterTab);
