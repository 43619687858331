import Ajax from "./ajax";
import { appConfigs } from "./config";
class batch {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static getList(params) {
    return Ajax.request(
      this.endpoint + "/template/" + this.apiVersion + "/batch",
      "GET",
      params
    );
  }

  static getCount(params) {
    return Ajax.request(
      this.endpoint + "/template/" + this.apiVersion + "/batch/count",
      "GET",
      params
    );
  }

  static getTemplateList(params) {
    return Ajax.request(
      this.endpoint + "/template/" + this.apiVersion,
      "GET",
      params
    );
  }

  static createBulkTemplate(params) {
    return Ajax.request(
      this.endpoint + "/template/" + this.apiVersion + "/parseBulkTemplate",
      "POST",
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
export default batch;
