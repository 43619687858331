import FormInputLayout from "./FormInputLayout";

const TextareaInput = ({
  label,
  register,
  name,
  error,
  placeholder,
  callback,
  layout,
  isMandatory,
  rows = 5,
  cols = 10,
  note = "",
  info = {},
  maxLength,
  disabled = false,
}) => {
  return (
    <FormInputLayout
      label={label}
      error={error}
      isMandatory={isMandatory}
      layout={layout}
      name={name}
      note={note}
      info={info}
    >
      <textarea
        {...register(name, { onChange: callback })}
        placeholder={placeholder}
        className="form-control"
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        disabled={disabled}
      ></textarea>
    </FormInputLayout>
  );
};

export default TextareaInput;
