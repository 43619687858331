import { format as dtFormat } from "date-fns";
import { escapeRegExp } from "lodash";
import get from "lodash/get";
import queryString from "query-string";
import Ajax from "./ajax";
import { appConfigs } from "./config";
import User from "./user";
import { md5 } from "js-md5";
import { AuthService } from "../sk-services";

class Common {
  static endpoint = appConfigs.API;
  static apiVersion = appConfigs.API_VERSION;

  static decodeJwt(token) {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      return {};
    }
  }

  static getLocationParams() {
    const i = location.hash.indexOf("?");
    if (i >= 0) {
      return queryString.parse(location.hash.substring(i + 1));
    }
    return {};
  }

  static hasUrlDynamicValue(url) {
    const v = (url.match(/\[.+?\]/g) || []).map((e) => e.replace(/\[\]/g, ""));
    return {
      status: v.length > 0 ? true : false,
      variables: v,
    };
  }

  static roundedByDecimalPlace(number, decimal_places) {
    const denominator = Math.pow(10, decimal_places);
    const rounded_number = Math.round(number * denominator) / denominator;
    return rounded_number;
  }

  static commaSeparate(number) {
    return number.toLocaleString("en-IN");
  }

  static rupeeFormat(val, decimal = 0, ignoreRupeeSym = false) {
    return (
      (!ignoreRupeeSym ? "" : "Rs.") +
      Number(this.roundedByDecimalPlace(val, decimal)).toLocaleString("en-IN")
    );
  }

  static copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  static replaceUrlDynamicValue = (url, variables, params) => {
    let u = url;
    (variables || []).forEach((e) => {
      u = u.replace("[" + e + "]", (params.path || {})[e]);
    });
    return u;
  };

  static scrollToView(elem, timer = 500) {
    window.scrollIntoView(elem),
      {
        time: timer,
        align: {
          top: 0.01,
        },
      };
  }

  static scrolltop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  static generateCsv(data, header, filename) {
    let finalVal = header.map((x) => x.name).join(",") + "\n";

    for (let i = 0; i < data.length; i++) {
      let value = data[i];
      for (let j = 0; j < header.length; j++) {
        let innerValue = "";
        if (header[j].isObject) {
          innerValue = get(value, header[j].value, "").toString();
        } else {
          innerValue = (value[header[j].value] || "").toString();
        }
        let result = innerValue && innerValue.replace(/"/g, '""');
        if (result && result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0) finalVal += ",";
        finalVal += result;
      }

      finalVal += "\n";
    }

    let pom = document.createElement("a");
    pom.setAttribute("id", "sk_csv_download");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(finalVal)
    );
    pom.setAttribute("download", filename + ".csv");
    pom.click();
    let timer = setTimeout(function () {
      clearTimeout(timer);
    }, 500);
  }

  static attachUserInfo = async (data, keys = []) => {
    if (!Array.isArray(keys) || !keys.length) {
      return data;
    }

    let formattedData = [...data];

    // get the values of keys which are inside the keys array
    let ids = [];
    data.forEach((x) => {
      // loop the keys to get the value from each data item
      keys.forEach((k) => {
        const t = x[k] || "";
        // got id push to ids array
        if (t && ids.indexOf(t) == -1) {
          ids.push(t);
        }
      });
    });

    // get user data based on the ids
    if (ids.length > 0) {
      const p = {
        filter: { _id: { $in: ids } },
        select: "name",
        page: 1,
        count: ids.length,
      };
      const u = await User.getList(p);
      const users = Array.isArray(u.resp) ? u.resp : [];
      if (users.length > 0) {
        // attach the user info to their relevant key buy having "_" as prefix
        formattedData.forEach((x) => {
          keys.forEach((k) => {
            const t = x[k] || "";
            // attach the user info
            if (t) {
              const u = users.find((e) => e._id == t);
              if (u && u._id) {
                x["_" + k] = u;
              }
            }
          });
        });
      }
    }

    return formattedData;
  };

  static updateAppTitle(title) {
    document.title = title || "StoreKing ERP";
  }

  static prepareAppliedFilterLabels(config, formData) {
    let t = [];

    Object.keys(formData).forEach((x) => {
      let c = config[x] || {};
      let v = null;

      if (c.isMultiple) {
        const co = formData[x];
        if (Array.isArray(co) && co.length > 0) {
          v = co.map((e) => get(e, c.valuePath)).join(", ");
        } else {
          v = "";
        }
      } else {
        v = c.valuePath ? get(formData[x], c.valuePath) : formData[x];
      }

      // for date range
      if (c.type == "dateRange") {
        if (Array.isArray(v) && v.length == 2) {
          v =
            dtFormat(v[0], c.dateFormat || "dd MMM yyyy") +
            " to " +
            dtFormat(v[1], c.dateFormat || "dd MMM yyyy");
        } else {
          v = "";
        }
      }

      // for time
      if (c.type == "time") {
        if (Array.isArray(v) && v.length == 1) {
          v = dtFormat(v[0], c.dateFormat || "hh:mm a");
        } else {
          v = null;
        }
      }

      // for date
      if (c.type == "date") {
        if (Array.isArray(v) && v.length == 1) {
          v = dtFormat(v[0], c.dateFormat || "dd MMM yyyy");
        } else {
          v = "";
        }
      }

      if (v === true || v === "true") {
        v = "Yes";
      }

      if (v === false || v === "false") {
        v = "No";
      }

      t.push({
        label: c.label || x,
        value: v,
        key: x,
        config: c,
      });
    });
    return t.filter((x) => x.value);
  }

  static updatePageTitle(title = "") {
    let header = document.getElementById("appHeader");
    header.innerHTML = title;
    header.style.textTransform = "uppercase";
  }

  static sanitizeRegex(string) {
    return escapeRegExp(string);
  }

  static getTierData() {
    return [
      "Tier 1",
      "Tier 2",
      "Tier 3",
      "Tier 4",
      "Tier 5",
      "Tier 6",
      "Tier 7",
      "Tier BRANDSFNEW",
      "Tier BPCL",
      "Tier BPCLNew",
      "Tier GPAY",
      "Metro",
    ];
  }

  static calculatePriceOnUom(price, qty, uomConfig, selected) {
    const configs = [
      {
        config: "gm",
        types: [
          {
            type: "gm",
            value: () => {
              const oneGm = price / (uomConfig?.packsize || 1);
              return qty * oneGm;
            },
          },
          {
            type: "kg",
            value: () => {
              const oneGm = price / (uomConfig?.packsize || 1);
              return qty * 1000 * oneGm;
            },
          },
        ],
      },
      {
        config: "kg",
        types: [
          {
            type: "gm",
            value: () => (qty / 1000) * (price / (uomConfig?.packsize || 1)),
          },
          {
            type: "kg",
            value: () => (price / (uomConfig?.packsize || 1)) * qty,
          },
        ],
      },
    ];

    const c = configs.find((x) => x.config == uomConfig?.uom) || {};

    if (c && c.types) {
      const t = c.types.find((x) => x.type == selected);
      if (t && t.value) {
        return { total: t.value() };
      } else {
        return { total: 0 };
      }
    } else {
      return { total: 0 };
    }
  }

  static getBanners(params) {
    return Ajax.request(
      this.endpoint + "/banner/" + this.apiVersion,
      "GET",
      params
    );
  }

  static createMD5Hash(data) {
    return md5.hex(data);
  }

  static downloadAsset(id = "", showAsPreview = false) {
    let u = appConfigs.ASSET + "/" + id;
    if (showAsPreview) {
      u += "?preview=1";
    }
    window.open(u, "_blank");
  }

  static getCoinsDashboardTypeLabel(type) {
    let label = type;
    if (type == "ParentCoinReward") {
      label = "All Time Reward";
    } else if (type == "DirectInviteFirstorder") {
      label = "Shared by WhatsApp";
    } else if (type == "IndirectInviteFirstorder") {
      label = "Referred by Club owner";
    } else if (type == "ReferralCoupon") {
      label = "Referral Coupon";
    } else if (type == "NoInviteFirstorder") {
      label = "Joining Bonus";
    }
    return label;
  }

  static getKcBalance(id, type, params = {}) {
    return Ajax.request(
      this.endpoint +
        "/commission/" +
        this.apiVersion +
        "/loyaltyPoints/account/" +
        id +
        "/" +
        type,
      "GET",
      params
    );
  }

  static getPosOrderDisplayStatus(status, orderType) {
    let displayStatus = status;
    let statusType = "warning";

    switch (status) {
      case "Delivered":
        displayStatus = "Delivered";
        statusType = "success";
        break;
      case "Closed":
        displayStatus =
          orderType === "POSORDER" ? "Delivered" : "Yet to deliver";
        statusType = orderType === "POSORDER" ? "success" : "warning";
        break;
      case "Partially Returned":
        displayStatus = "Delivered";
        statusType = "success";
        break;
      case "Returned":
        displayStatus = "Returned";
        statusType = "danger";
        break;
      case "Shipped":
        displayStatus = "Yet to deliver";
        statusType = "warning";
        break;
      default:
        displayStatus = status;
        statusType = "warning";
    }
    return { displayStatus, statusType };
  }

  static getPosOrderStatusGroups() {
    const statusGroups = [
      {
        key: "Delivered",
        name: "Delivered",
        statusGroup: ["Delivered", "Partially Returned"],
        statusType: "success",
        displayStatus: "Delivered",
      },
      {
        key: "Yet to Deliver",
        name: "Yet to Deliver",
        statusGroup: ["Closed", "Shipped"],
        statusType: "warning",
        displayStatus: "Yet to Deliver",
      },
      {
        key: "Returned",
        name: "Returned",
        statusGroup: ["Returned"],
        statusType: "danger",
        displayStatus: "Returned",
      },
    ];

    return statusGroups;
  }

  static convertQtyOnUom(qty, uom) {
    let q = qty;
    let u = uom;
    if (uom == "kg") {
      if (q < 1000) {
        u = "gm";
      } else {
        q = this.roundedByDecimalPlace(qty / 1000, 3);
        u = "kg";
      }
    }
    return { qty: q, uom: u };
  }

  static isValidMobileNumber(number) {
    const mobileNumberPattern = /^[6-9]\d{9}$/;
    return mobileNumberPattern.test(number);
  }

  static detectPlatform(userAgent) {
    let isMobile = /Mobile|Android|iPhone|iPad|iPod/i.test(userAgent);
    let isApp = /ReactNative|Flutter|Cordova|Electron|appname/i.test(userAgent); // Add your app-specific identifier

    let browser = "Unknown Browser";
    if (/Chrome/i.test(userAgent)) browser = "Chrome";
    else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent))
      browser = "Safari";
    else if (/Firefox/i.test(userAgent)) browser = "Firefox";
    else if (/Edge/i.test(userAgent)) browser = "Edge";
    else if (/Opera|OPR/i.test(userAgent)) browser = "Opera";

    if (isApp) return { platform: "App", browser: "N/A" };
    if (isMobile) return { platform: "Mobile Browser", browser: browser };
    return { platform: "Desktop Browser", browser: browser };
  }

  static storeReportDownload(params) {
    return Ajax.request(
      this.endpoint + "/oms/" + this.apiVersion + "/seller/gensfreport",
      "GET",
      params
    );
  }

  static isOzonetelLoggedIn() {
    const iframe = document.getElementById("ozonetelFrame");
    return iframe !== null;
  }

  static attachOzonetelIframe() {
    let iframe = document.getElementById("ozonetelFrame");
    if (!iframe) {
      const emp = AuthService.getLoggedInEmp();

      iframe = document.createElement("iframe");
      iframe.id = "ozonetelFrame";
      iframe.sandbox =
        "allow-scripts allow-forms allow-same-origin allow-popups allow-modals allow-downloads";
      iframe.allow = "geolocation; microphone; display-capture";
      // iframe.src = "https://agent.cloudagent.ozonetel.com/home";
      iframe.src = `https://agent.cloudagent.ozonetel.com/login?customer=localcube&agentid=${emp.agentId}&phoneNumber=${emp.sipId}&pin=100&action=formLogin`;
      iframe.classList.add("open");

      document.body.appendChild(iframe);

      const toggleButton = document.createElement("button");
      toggleButton.id = "ozonetelToggle";
      toggleButton.innerHTML = '<i class="bi bi-chevron-up"></i>';
      toggleButton.style.width = "40px";
      toggleButton.style.height = "40px";
      toggleButton.style.display = "flex";
      toggleButton.style.alignItems = "center";
      toggleButton.style.justifyContent = "center";
      toggleButton.onclick = () => {
        iframe.classList.toggle("open");
        toggleButton.innerHTML = iframe.classList.contains("open")
          ? '<i class="bi bi-chevron-up"></i>'
          : '<i class="bi bi-chevron-down"></i>';
      };
      document.body.appendChild(toggleButton);
    }
    return iframe;
  }

  static getDelvTimeHourMin(slot) {
    const fromHour = Math.floor(slot.from);
    const fromMins = slot.from % 1 == 0 ? 0 : Math.round((slot.from % 1) * 100);

    const toHour = Math.floor(slot.to);
    const toMins = slot.to % 1 == 0 ? 0 : Math.round((slot.to % 1) * 100);

    return { fromHour, fromMins, toHour, toMins };
  }

  static openGoogleMap(lat, lng) {
    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, "_blank");
  }

  static getSmartSfSubTypes() {
    return [
      { label: "Brand SF", value: "Brand SF" },
      ...appConfigs.DARKSTORE_SUB_TYPES.map((type) => ({
        label: type,
        value: type,
      })),
    ];
  }
}

export default Common;
